import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import { useAtom } from 'jotai'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import createUseStyles from '../lib/createUseStyles'
import { openAboutSections } from '../state/about'
import { theme } from '../styles/theme'
import { ArrowUp } from './icons/ArrowUp'
import gsap from 'gsap'

const HEIGHT = 24

export const AnchorPill = ({ title = 'Jump to section', anchors = [] }) => {
  const styles = useStyles()
  const [_, setOpenSectionKeys] = useAtom(openAboutSections)
  const handleClick = useCallback((e, anchor) => {
    const scroller = ScrollSmoother.get()
    if (scroller) {
      e.preventDefault()
      gsap.to(scroller, {
        scrollTop: scroller.offset(`#${anchor.href}`, 'top 32px'),
        duration: 0.6,
        ease: 'power2.inOut',
        onComplete: () => setOpenSectionKeys(keys => [...keys, anchor.id])
      })
    }
  }, [])

  return (
    <div
      className={styles.root}
      data-root
      style={{ '--item-height': `${HEIGHT}px`, '--item-count': anchors.length }}
    >
      <div className={styles.trigger}>
        <span className={styles.title}>{title}</span>
        <ArrowUp className={styles.arrow} />
      </div>
      <div className={styles.inner}>
        <div className={styles.items}>
          {anchors.map((anchor, index) => (
            <a
              className={styles.item}
              key={index}
              href={`#${anchor.href}`}
              onClick={e => handleClick(e, anchor)}
            >
              {anchor.title}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    borderRadius: '24px',
    width: '100%',
    position: 'relative',
    textTransform: 'uppercase',
    fontSize: theme.fonts.rem(12),
    color: '#fff',
    background: '#000',
    height: 48
  },
  trigger: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(4)}px`,
    zIndex: 250,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  title: {},
  arrow: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
    '[data-root="true"]:hover &': {
      transform: 'rotate(0deg)'
    }
  },
  inner: {
    position: 'absolute',
    zIndex: 200,
    top: 'calc(100% - 24px)',
    left: 0,
    right: 0,
    borderRadius: '0 0 24px 24px',
    height: 0,
    overflow: 'hidden',
    background: '#000',
    transition: 'height 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'height',
    '[data-root="true"]:hover &': {
      height: 'calc(var(--item-height) * var(--item-count) + 48px)'
    }
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
    lineHeight: '1em'
  },
  item: {
    height: 'var(--item-height)',
    padding: `0 ${theme.spacing(4)}px`,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    transition: 'transform 0.3s cubic-bezier(0.16, 1, 0.3, 1)',
    '&:hover': {
      transform: 'translate(5px, 0)'
    }
  }
})

AnchorPill.propTypes = {
  title: PropTypes.string,
  anchors: PropTypes.arrayOf(PropTypes.object).isRequired
}
