import { css } from 'goober'
import reduce from 'lodash/reduce'
import { useMemo } from 'react'

function createStyles (obj) {
  return reduce(obj, (result, value, key) => {
    result[key] = css(value)
    return result
  }, {})
}

export default (obj) => {
  return () => {
    return useMemo(() => {
      return createStyles(obj)
    }, [])
  }
}
