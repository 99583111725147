import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useLocalPagination } from '../../../hooks/useLocalPagination'
import createUseStyles from '../../../lib/createUseStyles'
import { roundedButton } from '../../../styles/buttons'
import RoundButton from '../../RoundButton'
import { theme } from '../../../styles/theme'
import Section from '../../Section'
import { BioDialog } from './BioDialog'
import { teamPropType } from './TeamListing.types'
import { TeamListingHighlight } from './TeamListingHighlight'
import { TeamListingItem } from './TeamListingItem'

export const TeamListing = ({
  page: { title: pageTitle, parent },
  data: { list = [], highlight },
  pageSize = 18
}) => {
  const styles = useStyles()

  const { visibleItems, handleClickMore, hasMore, currentPage } =
    useLocalPagination(list, pageSize)

  // Active bio
  const [activeTeamMember, setActiveTeamMember] = useState(null)

  useEffect(() => {
    // Ensure scroll area works for new height
    ScrollSmoother.refresh()
  }, [currentPage])

  return (
    <>
      <div className={styles.root}>
        <Section grid className={styles.header}>
          {parent && (
            <div className={styles.backBtn}>
              <RoundButton link={parent} dark bigPadding showBackArrow showArrow={false} showText={false}>
                Back to {parent.title}
              </RoundButton>
            </div>
          )}
          <h1 className={styles.title}>{pageTitle}</h1>
        </Section>
        {highlight && (
          <TeamListingHighlight
            className={styles.highlight}
            teamMember={highlight}
            onClick={setActiveTeamMember}
          />
        )}
        <Section grid className={styles.list}>
          {visibleItems.map(teamMember => (
            <TeamListingItem
              teamMember={teamMember}
              key={teamMember._id}
              className={styles.item}
              onClick={setActiveTeamMember}
            />
          ))}
        </Section>
        {hasMore && (
          <button className={styles.loadMore} onClick={handleClickMore}>
            Load more
          </button>
        )}
      </div>
      <BioDialog
        onClose={() => setActiveTeamMember(null)}
        pageTitle={pageTitle}
        teamMember={activeTeamMember}
      />
    </>
  )
}

const useStyles = createUseStyles({
  root: {},
  header: {
    paddingTop: 60
  },
  title: {
    gridColumn: '1 / -1',
    textTransform: 'uppercase',
    lineHeight: 0.9,
    [theme.breakpoints.up('md')]: {
      fontSize: '12vw'
    }
  },
  backBtn: {
    gridColumn: '1 / -1',
    marginBottom: 32
  },
  highlight: {},
  list: {
    [theme.breakpoints.up('md')]: {
      rowGap: `${theme.spacing(4)}px!important`
    }
  },
  item: {
    gridColumn: 'span 2',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 4'
    }
  },
  loadMore: {
    ...roundedButton,
    height: 44,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

TeamListing.propTypes = {
  page: PropTypes.shape({
    slices: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    highlight: teamPropType,
    list: PropTypes.arrayOf(teamPropType)
  }).isRequired,
  pageSize: PropTypes.number
}
