import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import { useAtom } from 'jotai'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import { headerFadeInAtom } from '../Header/headerState'
import RichContent from '../RichContent'

export default function PageHero ({ data, page }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()

  const { title, copy } = data

  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (!headerFadeIn) return

    const titleSplit = new SplitText(titleRef.current, { type: 'words, lines', linesClass: 'split-parent' })

    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.inOut',
        duration: 0.6
      }
    })

    tl.set(titleRef.current, {
      opacity: 1
    })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1
      // onComplete: () => titleSplit.revert()
    })

    tl.to(sectionRef.current.querySelectorAll(`.${classes.copy}`), {
      opacity: 1,
      y: 0
    }, '-=0.5')
  }, [headerFadeIn])

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <h1 className={classes.title} ref={titleRef}>{title}</h1>
      {copy && <RichContent className={classes.copy} content={copy} />}
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    marginTop: 90,
    marginBottom: 128,
    gridColumn: '1 / -1',
    lineHeight: '1em',
    textTransform: 'uppercase',
    fontSize: 88,
    opacity: 0,
    '& .split-parent': {
      overflowY: 'hidden',
      height: '1.1em',
      marginBottom: '-0.25em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 62,
      marginTop: 62 - 24,
      marginBottom: 72
    }
  },
  copy: {
    gridColumn: '1 / span 5',
    opacity: 0,
    transform: 'translateY(20px)'
  }
})
