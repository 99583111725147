import Link from './Link'
import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import cn from 'classnames'
import { keyframes } from 'goober'
import ResponsiveImage from './ResponsiveImage'
import { useEffect, useState, useRef, useCallback } from 'react'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import { useScrollReadyListener } from '../hooks/useScrollReady'

export const Marquee = ({ text }) => {
  const classes = useStyles()

  return (
    <div className={classes.marquee}>
      <div className={classes.marqueeInner}>
        <div className={cn(classes.dot, classes.dotSmall)} />
        <div>{text}</div>
        <div className={cn(classes.dot, classes.dotSmall)} />
        <div>{text}</div>
        <div className={cn(classes.dot, classes.dotSmall)} />
        <div>{text}</div>
      </div>
      <div className={classes.marqueeInner}>
        <div className={cn(classes.dot, classes.dotSmall)} />
        <div>{text}</div>
        <div className={cn(classes.dot, classes.dotSmall)} />
        <div>{text}</div>
        <div className={cn(classes.dot, classes.dotSmall)} />
        <div>{text}</div>
      </div>
    </div>
  )
}

export default function ShopPill({
  link,
  className,
  colour,
  hoverColour,
  actionTitle = 'shop',
  title,
  textColor,
  hoverStyle,
  products,
  pathway,
  index
}) {
  const [isHovering, setIsHovering] = useState(false)
  const scrollableDiv = useRef()
  const scrollRef = useRef()
  const ref = useRef()

  const classes = useStyles()

  const { pathwayRef } = pathway ?? {}

  const isMarquee = hoverStyle === 'marquee'
  const isCollection = hoverStyle === 'collection'
  const isPathways = hoverStyle === 'pathways'
  const isHover = isMarquee || isCollection || isPathways

  useEffect(() => {
    if (!isHovering) return
    gsap.fromTo(
      ref.current.querySelectorAll(
        `.${marquee}, .${classes.collection}, .${classes.pathway} a > div`
      ),
      {
        y: -10,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.6,
        ease: 'expo.out',
        overwrite: true,
        stagger: 0.02
      }
    )
  }, [isHovering])

  useScrollReadyListener(useCallback(() => {
    if (!isPathways) return
    const scroller = ScrollSmoother.get()
    if (scroller) scrollRef.current = scroller
  }, []))

  const handleWheel = useCallback((e) => {
    if (!isPathways) return

    if (isHovering && scrollableDiv.current) {
      e.preventDefault()
      const scrollAmount = e.deltaY
      const scrollTop = scrollableDiv.current.scrollTop
      const maxScrollTop = scrollableDiv.current.scrollHeight - scrollableDiv.current.clientHeight

      if ((scrollTop === 0 && scrollAmount < 0) || (scrollTop >= maxScrollTop && scrollAmount > 0)) {
        if (scrollRef.current) {
          scrollRef.current.paused(false)
        }
      } else {
        if (scrollRef.current) {
          scrollRef.current.paused(true)
        }
        scrollableDiv.current.scrollTop += scrollAmount
      }
    } else {
      if (scrollRef.current) {
        scrollRef.current.paused(false)
      }
    }
  }, [isHovering, isPathways])

  useEffect(() => {
    if (!isPathways) return
    window.addEventListener('wheel', handleWheel, { passive: false })
    return () => {
      window.removeEventListener('wheel', handleWheel)
    }
  }, [handleWheel])

  const eventListeners = {
    onMouseLeave: primaryInput === 'touch' ? null : () => setIsHovering(false),
    onMouseEnter: primaryInput === 'touch' ? null : () => setIsHovering(true)
  }

  return (
    <div className={className} ref={ref}>
      <div className={classes.wrap} data-speed="0.7" {...eventListeners}>
        <Link
          link={
            isPathways
              ? {
                  slug: `${pathwayRef?.slug}/${pathwayRef?.pathwayStories[0]?.slug}`
                }
              : link
          }
          showText={false}
          showExternalIcon={false}
        >
          <div
            className={cn(classes.pill, isPathways && classes.pathwayPill)}
            style={{ backgroundColor: colour, color: textColor }}
          >
            <div className={classes.dot} />
            <div>{isPathways && <span className={classes.prefix}>{index + 1}. </span>}{actionTitle}</div>
            {title && <div>{title}</div>}
            <svg
              width="5"
              height="7"
              viewBox="0 0 5 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.237375 0.784804H1.61738L4.52138 3.5328L1.61738 6.2928H0.237375L3.14138 3.5328L0.237375 0.784804Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </Link>
        {isHover && (
          <div
            className={cn(
              classes.hover,
              isPathways && classes.pathwayHover,
              'shop-pill-hover',
              (isCollection || isPathways) && 'shop-pill-collection-hover'
            )}
            style={{ backgroundColor: hoverColour, color: textColor }}
          >
            {isMarquee && (
              <Marquee text="5% of revenue goes to the Two Good Foundation" />
            )}
            {isCollection && (
              <div className={classes.collection}>
                {products &&
                  products.slice(0, 2).map((product, i) => (
                    <Link
                      link={product}
                      key={`${product._key}-${i}`}
                      showText={false}
                    >
                      <div>
                        {product.featuredImage &&
                          product.featuredImage !== null && (
                            <ResponsiveImage
                              className={classes.productImage}
                              image={product.featuredImage}
                              showPreview={false}
                              contain
                              containAspect={1}
                            />
                        )}
                        <div className={classes.productTitle}>
                          {product.title}
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
            {isPathways && (
              <div className={classes.pathway}>
                {pathwayRef && (
                  <div className={classes.storyList} ref={scrollableDiv}>
                    {pathwayRef.pathwayStories &&
                      pathwayRef.pathwayStories.map((story, k) => {
                        return (
                          <PathwayStory
                            key={k}
                            story={story}
                            pathwayRef={pathwayRef}
                          />
                        )
                      })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const PathwayStory = ({ story, pathwayRef }) => {
  const classes = useStyles()
  const { title, image, slug } = story ?? {}
  return (
    <div className={classes.storyItem}>
      {image && (
        <Link to={`${pathwayRef.slug}/${slug}`} showText={false}>
          <ResponsiveImage
            className={classes.storyImage}
            image={image}
            showPreview={false}
            containAspect={1}
          />
        </Link>
      )}
      <Link to={`${pathwayRef.slug}/${slug}`} showText={false} className={classes.storyLink}>
        <div>{title + "'s Story"}</div>
        <div className={classes.storyVideo}>
          <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 4.5L0.25 8.39711L0.25 0.602886L7 4.5Z" fill="black"/>
          </svg>
          <span>Watch video</span>
        </div>
      </Link>
    </div>
  )
}

const marquee = keyframes`
0% {
  transform: translateX(0);
}

100% {
  transform: translateX(-100%);
}
`

const useStyles = createUseStyles({
  marquee: {
    display: 'block',
    width: '100%',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    overflow: 'hidden',
    marginTop: 14,
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight
  },
  marqueeInner: {
    display: 'inline-block',
    animation: `${marquee} 40s infinite linear`,
    '& div': {
      display: 'inline-block',
      margin: '0 8px'
    }
  },
  storyList: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: 80,
    height: 250,
    overflow: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  storyItem: {
    paddingTop: 6,
    paddingBottom: 6,
    display: 'flex',
    width: '100%',
    height: 96,
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    fontSize: 12,
    transition: 'opacity 0.25s cubic-bezier(.22,.57,.12,.96)',
    '&:hover': {
      opacity: 0.6
    }
  },
  storyImage: {
    marginRight: 16,
    width: '80px !important',
    height: '80px !important'
  },
  storyVideo: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& svg': {
      position: 'relative',
      top: -2
    },
    '& span': {
      opacity: 0.5
    }
  },
  storyLink: {
    width: '100%'
  },
  prefix: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  wrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 200,
    [theme.breakpoints.up('sm')]: {
      '&:hover .shop-pill-hover': {
        height: 95
      },
      '&:hover .shop-pill-collection-hover': {
        height: 247
      }
    },
    '& a': {
      textDecoration: 'none'
    }
  },
  pill: {
    background: '#744F4D',
    padding: '0 32px',
    width: 303,
    height: 50,
    borderRadius: 25,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: '0.06em',
    lineHeight: '.9em',
    zIndex: 2,
    '& div:nth-child(2), & div:nth-child(3)': {
      marginTop: 4
    },
    '& div:nth-child(3)': {
      fontWeight: '700'
    }
  },
  pathwayPill: {
    [theme.breakpoints.up('lg')]: {
      width: '400px !important'
    }
  },
  dot: {
    width: 8,
    height: 8,
    background: 'currentColor',
    borderRadius: '50%'
  },
  dotSmall: {
    width: 4,
    height: 4
  },
  hover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 303,
    height: 50,
    borderRadius: 25,
    overflow: 'hidden',
    paddingTop: 50,
    zIndex: -1,
    color: 'currentColor',
    fontSize: 10,
    textTransform: 'uppercase',
    transition: 'height 0.25s cubic-bezier(.22,.57,.12,.96)',
    willChange: 'height'
  },
  pathwayHover: {
    [theme.breakpoints.up('lg')]: {
      width: '400px !important'
    }
  },
  collection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  productImage: {
    height: 118,
    marginTop: 20,
    marginBottom: 16
  },
  productTitle: {
    maxWidth: '12em',
    margin: 'auto',
    fontSize: 12,
    lineHeight: '1em'
  }
})
