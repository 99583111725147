import React, { forwardRef } from 'react'
import cn from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import ResponsiveImage from './ResponsiveImage'
import Link from './Link'
import CtaLink from './CtaLink'
import ViewMore from './ViewMore'
import Quote from './Quote'
import AccordionComponent from './AccordionComponent'
import IngredientsList from './IngredientsList'
import CtaButtonLink from './CtaButtonLink'
import CtaButtons from './CtaButtons'
import LogoImage from './LogoImage'

const BlockRenderer = props => {
  const styles = useStyles()

  const { style = 'normal' } = props.node

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    return React.createElement(
      style,
      { className: `heading-${level}` },
      props.children
    )
  }

  if (style === 'mono') {
    return <p className={cn(styles.monoText)}>{props.children}</p>
  }

  return BlockContent.defaultSerializers.types.block(props)
}

const SectionTitle = ({ title }) => {
  const styles = useStyles()

  return (
    <span className={cn(styles.sectionTitle, 'section-label')}>{title}</span>
  )
}

export const serializers = {
  types: {
    block: BlockRenderer,
    image: ({ node }) => {
      return <ResponsiveImage image={node} />
    },
    logoImage: ({ node }) => {
      return <LogoImage image={node.logoImage} />
    },
    ctaLink: ({ node }) => {
      return <CtaLink link={node.link} />
    },
    ctaButtonLink: ({ node }) => {
      return <CtaButtonLink link={node.link} />
    },
    ctaButtons: ({ node }) => {
      return <CtaButtons data={node} />
    },
    viewMore: ({ node }) => {
      return <ViewMore node={node} title={node.title} copy={node.copy} />
    },
    quote: ({ node }) => {
      return <Quote quote={node.quote} author={node.author} marginBottom={node.marginBottom} />
    },
    accordion: props => {
      return <AccordionComponent items={props.node.items} />
    },
    ingredientList: ({ node }) => {
      const { title, ingredients, numberOfItemsToShow } = node
      return (
        <IngredientsList
          title={title}
          ingredients={ingredients}
          numberOfItemsToShow={numberOfItemsToShow}
        />
      )
    }
  },
  marks: {
    link: ({ mark, children }) => {
      return (
        <Link link={{ ...mark }} showText={false}>
          {children}
        </Link>
      )
    },
    sectionLabel: ({ children }) => {
      return <SectionTitle title={children} />
    }
  }
}

const RichContent = forwardRef(({ className, content, children }, ref) => {
  const styles = useStyles()
  return (
    <div className={cn(styles.container, className)} ref={ref}>
      <BlockContent
        renderContainerOnSingleChild
        blocks={content}
        serializers={serializers}
      />
      {children}
    </div>
  )
})

RichContent.displayName = 'RichContent'

const useStyles = createUseStyles({
  container: {
    letterSpacing: '0.01em',
    fontSize: 16,
    '& > *:last-child': {
      marginBottom: 0
    },
    '& > div > *:last-child': {
      marginBottom: 0
    },
    '& strong': {
      fontFamily: theme.fonts.heading,
      fontWeight: theme.fonts.headingFontWeight
    }
  },
  largeText: {
    fontSize: '1.15vw',
    [theme.breakpoints.down('sm')]: {
      // fontSize: '1em'
      fontSize: 18
    }
  },
  sectionTitle: {
    position: 'absolute',
    left: theme.grid.md.margin,
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    fontSize: 10,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  monoText: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    fontSize: 12,
    margin: 0
  }
})

export default RichContent
