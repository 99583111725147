import { useState, useRef, useEffect, useCallback } from 'react'
import { useAtomValue } from 'jotai'
import cn from 'classnames'
import gsap from 'gsap'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import { settingsAtom } from '../../state/content'

const defaultState = {
  message: null,
  error: null
}

export default function NewsletterSignUp (props) {
  const classes = useStyles()
  const svgRef = useRef()
  const inputRef = useRef()

  const [focus, setFocus] = useState(false)
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState(defaultState)

  const { message, error } = status
  const { color } = props

  const settings = useAtomValue(settingsAtom)

  async function subscribeToNewsletter (data) {
    const response = await window.fetch('/api/mailchimp/newsletter', {
      method: 'POST',
      body: JSON.stringify(data)
    })
    if (response.ok) {
      setStatus({
        message: settings.newsletterSuccess || 'You’re signed up! Check your inbox for confirmation',
        error: false
      })
    } else {
      setStatus({
        message: settings.newsletterFail,
        error: true
      })
    }
    return response
  }

  const onSubscribe = useCallback((e) => {
    subscribeToNewsletter({ email })
    e.preventDefault()
  })

  const onEmailChange = useCallback((e) => {
    setEmail(e.target.value)
  }, [setEmail])

  const onFocus = (e) => {
    setFocus(true)
    e.target.placeholder = ''
  }

  const onBlur = (e) => {
    setFocus(false)
    if (!e.target.value) {
      e.target.placeholder = 'Enter your email address for Good'
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubscribe(e)
    }
  }

  useScrollReadyListener(useCallback(() => {
    setTimeout(() => {
      gsap.to(svgRef.current, {
        strokeDashoffset: '0px',
        duration: 0.6,
        ease: 'power3.inOut',
        delay: 0.5,
        scrollTrigger: {
          trigger: svgRef.current,
          start: '30% bottom'
        }
      })
    }, 200)
  }, []))

  useEffect(() => {
    inputRef.current.addEventListener('focus', () => {
      gsap.set(svgRef.current, {
        opacity: 0
      })
    })
  }, [])

  return (
    <div className={classes.newsletter} id="newsletter">
      <div className={classes.wrap}>
        <textarea
          className={classes.input}
          ref={inputRef}
          placeholder='Enter your email address for Good'
          value={email}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onEmailChange}
          onKeyDown={onKeyDown}
        />
        <div className={classes.svgContainer}>
          <svg xmlns='http://www.w3.org/2000/svg' width='100%' viewBox='0 0 218 115' fill='none'>
            <defs>
              <pattern id='wiggle' patternUnits='userSpaceOnUse' width='100%' height='100%'>
                <image className={classes.svgImage} href={(color && color.value === '#118739') ? '/newsletter-animation-white.png' : '/newsletter-animation-black.png'} transform='translate(-7, -7)' />
              </pattern>
            </defs>
            <path className={classes.svgPath} ref={svgRef} d='M183.031 40.8156C168.364 33.6489 133.531 20.8156 97.5308 21.8156C74.5308 21.8156 28.6308 28.7156 29.0308 52.3156C29.4308 75.9156 60.8642 87.4823 76.5308 90.3156C93.5308 94.4823 137.531 96.4156 177.531 70.8156C217.531 45.2156 183.531 23.1489 161.531 15.3156C142.697 8.48227 95.1755 -1.15535 54.0308 7.3156C20.0308 14.3156 4.95674 28.3156 4.03081 40.8156C3.69748 45.3156 5.93081 56.5156 17.5308 65.3156C32.0308 76.3156 66.5308 84.3156 99.5308 84.3156C132.531 84.3156 177.531 75.3156 202.031 77.8156' fill='none' stroke='url(#wiggle)' strokeWidth='4' />
          </svg>
        </div>
        <button className={cn(classes.sumbit, 'reset-button')} onClick={onSubscribe} title='Submit Email'>
          {focus
            ? (
              <svg width='26' height='30' viewBox='0 0 26 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M24.9852 0.81543V22.8353H1.34131' stroke='var(--foreground)' />
                <path d='M7.69068 16.4863L1.34131 22.8357L7.69068 29.1851' stroke='var(--foreground)' />
              </svg>
              )
            : (
              <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M14.1971 5.96177L13.3485 6.8103L18.69 12.1518L4.61293 12.1518L4.61293 13.3482L18.69 13.3482L13.3485 18.6897L14.1971 19.5382L20.9853 12.75L14.1971 5.96177Z' fill='var(--foreground)' />
              </svg>
              )}
        </button>
      </div>
      <div className={classes.line} />
      <div className={classes.statusWrapper}>
        <p className={cn(classes.status, error ? classes.error : classes.success)}>
          <span className={cn(color && color.value === '#118739' ? classes.white : null)}>
            {message}
          </span>
        </p>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  newsletter: {
    gridColumn: '1 / -1',
    marginBottom: 144,
    [theme.breakpoints.down('md')]: {
      marginBottom: 32
    }
  },
  wrap: {
    display: 'flex',
    alignItem: 'center'
  },
  input: {
    fontSize: 40,
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    borderRadius: 0,
    border: 'none',
    width: '100%',
    height: '46px',
    color: 'var(--foreground)',
    textTransform: 'uppercase',
    background: 'transparent',
    overflow: 'hidden',
    resize: 'none',
    outline: 'none',
    '&::placeholder': {
      opacity: 1,
      color: 'var(--foreground)'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      height: '54px'
    }
  },
  line: {
    width: '100%',
    height: '1px',
    background: 'var(--foreground)',
    opacity: 0.2,
    marginTop: 16,
    [theme.breakpoints.down('md')]: {
      opacity: 1
    }
  },
  svgPath: {
    strokeDasharray: '862px',
    strokeDashoffset: '862px',
    transitionDuration: '0s',
    transitionDelay: '0s'
  },
  svgContainer: {
    pointerEvents: 'none',
    position: 'absolute',
    top: '-32px',
    left: '686px',
    width: '210px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  svgImage: {
    width: '100%',
    height: '100%'
  },
  statusWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  status: {
    fontSize: 20,
    color: theme.colors.success
  },
  error: {
    color: theme.colors.error
  },
  white: {
    color: theme.colors.foreground
  }
})
