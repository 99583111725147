import { useCallback } from 'react'
import cn from 'classnames'
import createUseStyles from '../lib/createUseStyles'

const zeroPad = (num, places) => String(num).padStart(places, '0')

const QuantitySelector = ({ className, quantity, setQuantity, min = 1 }) => {
  const classes = useStyles()

  const incrementQuantity = useCallback(() => {
    setQuantity(quantity + 1)
  }, [quantity, setQuantity])

  const decrementQuantity = useCallback(() => {
    setQuantity(Math.max(min, quantity - 1))
  }, [quantity, setQuantity])

  return (
    <div className={cn(className || null, classes.quantitySelector)}>
      <button className={cn(classes.adjustButton, 'reset-button')} onClick={decrementQuantity} aria-label='Decrement Product'>
        -
      </button>
      <span className={classes.quantity}>{zeroPad(quantity, 1)}</span>
      <button className={cn(classes.adjustButton, 'reset-button')} onClick={incrementQuantity} aria-label='Increment Product'>
        +
      </button>
    </div>
  )
}

const useStyles = createUseStyles({
  quantitySelector: {
    display: 'flex',
    alignItems: 'center'
  },
  adjustButton: {
    minWidth: 42,
    minHeight: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default QuantitySelector
