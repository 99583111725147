import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import dayjs from 'dayjs'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'

export default function PageLink ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()

  const { page, image, subtitle } = data

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.image} > div`), {
      trigger: sectionRef.current,
      start: '30% bottom',
      onEnter: item => gsap.to(item, { 
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1, 
      })
    })
  }, []))
  
  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <Link link={page} showText={false} className={classes.link}>
        <div className={classes.image}>
          <ResponsiveImage image={image} aspect={685/529} speed="0.9" />
        </div>

        <div className={classes.aside}>
          <div className={classes.subtitle}>{subtitle}</div>
          <h3 className={classes.title}>{page.title}</h3>
          <div className={classes.meta}>
            <div>Written by {page.author}</div>
            <div>{dayjs(page.publishDate).format('DD MMMM, YYYY')}</div>
          </div>
        </div>
      </Link>
    </Section>
  )
}

const useStyles = createUseStyles({
  link: {
    gridColumn: '1 / -1',
    display: 'grid',
    gridTemplateColumns: `repeat(${theme.grid.sm.columns}, minmax(0px, 1fr))`,
    gap: `${theme.grid.sm.gutter}px`,
    textDecoration: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      gap: `${theme.grid.md.gutter}px`,
      gridTemplateColumns: `repeat(${theme.grid.md.columns}, minmax(0px, 1fr))`,
    }
  },
  aside: {
    gridColumn: '8 / span 4',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      marginBottom: 76
    }
  },
  image: {
    gridColumn: '1 / span 6',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
    '& > div': {
      opacity: 0,
      transform: 'scale(1.05)'
    }
  },
  subtitle: {
    textTransform: 'uppercase',
    marginBottom: 20,
    fontSize: 12
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 72,
    lineHeight: '1em',
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
      marginTop: -8,
    }
  },
  meta: {
    textTransform: 'uppercase',
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    fontSize: 10,
    display: 'grid',
    gridTemplateColumns: `repeat(2, minmax(0px, 1fr))`,
    gap: theme.grid.md.gutter,
    [theme.breakpoints.down('md')]: {
      gap: `${theme.grid.sm.gutter}px`,
      lineHeight: '1em',
      marginTop: -8,
      '& div:last-child': {
        textAlign: 'right'
      }
    }
  }
})
