import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import Link from './Link'
import cn from 'classnames'

export default function CtaButtonLink({ link }) {
  const styles = useStyles()

  return <Link className={cn(styles.ctaLink, 'button-link')} link={link} />
}

const useStyles = createUseStyles({
  ctaLink: {
    background: 'var(--foreground)',
    color: 'rgba(255,255,255,1)',
    padding: '21px 80px',
    borderRadius: '50px',
    fontSize: 12,
    border: 0,
    display: 'block',
    width: 'fit-content',
    fontFamily: theme.fonts.body,
    fontWeight: 700,
    letterSpacing: '0.07em',
    margin: '40px auto 16px',
    transition: 'color 0.2s ease-out',
    '&:hover': {
      color: 'rgba(255,255,255,0.7)'
    }
  }
})
