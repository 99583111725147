import cn from 'classnames'
import gsap from 'gsap'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import get from 'lodash/get'
import { useEffect, useRef, useState } from 'react'
import createUseStyles from '../lib/createUseStyles'
import Footer from './Footer'
import GlobalStyles from './GlobalStyles'
import Header from './Header'
import Meta from './Meta'
import Slices from './Slices'
import { theme } from '../styles/theme'
import { cartOpenAtom } from './Cart'
import first from 'lodash/first'
import { primaryInput } from 'detect-it'
import { donateOpenAtom, headerColoursAtom, menuOpenAtom } from './Header/headerState'
import { useLayoutState } from '../state/layout'
import { useHydrateAtoms } from 'jotai/utils'
import { customerGroupsAtom, settingsAtom } from '../state/content'
import { cursorSizeAtom, showCursorAtom, showVideoCursorAtom } from './Header/ProductCursor'
import Router from 'next/router'

export const scrollReadyAtom = atom(false)

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
gsap.config({ nullTargetWarn: process.env.NODE_ENV !== 'production' })

const ScrollContent = ({ page }) => {
  const settings = useAtomValue(settingsAtom)
  const slices = get(page, ['slices'])

  return (
    <div
      id='smooth-content'
      className='page-transition-wrapper'
      style={{ paddingTop: 131 }}
    >
      <Slices slices={slices} page={page} globalSettings={settings} />
      <Footer key={`footer_${page._id}`} pageColor={page.color} />
    </div>
  )
}

export default function PageComponent ({ data, preview }) {
  const classes = useStyles()
  const page = get(data, ['page'])
  const settings = get(data, ['global'])
  const customerGroups = get(data, ['customerGroups'])
  const seo = get(page, ['seo'])
  const container = useRef()
  const pageColor = page.color?.value ?? theme.colors.pageTheme.default.background
  const pageTextColor = (pageColor === '#118739') ? 'white' : theme.colors.pageTheme.default.foreground

  if (customerGroups) {
    useHydrateAtoms([
      [settingsAtom, settings],
      [customerGroupsAtom, customerGroups]
    ])
  } else {
    useHydrateAtoms([
      [settingsAtom, settings]
    ])
  }
  useLayoutState()

  const [menuIsOpen] = useAtom(menuOpenAtom)
  const [cartIsOpen] = useAtom(cartOpenAtom)
  const [donateIsOpen] = useAtom(donateOpenAtom)
  const setHeaderColours = useSetAtom(headerColoursAtom)
  const setScrollReady = useSetAtom(scrollReadyAtom)
  const setCursorVisible = useSetAtom(showCursorAtom)
  const setCursorSize = useSetAtom(cursorSizeAtom)
  const setVideoCursorVisible = useSetAtom(showVideoCursorAtom)

  const [pageData, setPageData] = useState(page)

  Router.events.on('routeChangeStart', () => document.documentElement.classList.add('cursor-wait'))
  Router.events.on('routeChangeComplete', () => {
    setTimeout(() => {
      document.documentElement.classList.remove('cursor-wait')
    }, 900)
  })
  Router.events.on('routeChangeError', () => document.documentElement.classList.remove('cursor-wait'))

  const scroll = useRef({})

  useEffect(() => {
    scroll.current = ScrollSmoother.create({
      smooth: 0.9,
      effects: true,
      normalizeScroll: (primaryInput !== 'touch'),
      ease: 'expo'
    })
    setScrollReady(true)
  }, [])

  useEffect(() => {
    if (primaryInput === 'touch') return
    const open = menuIsOpen || cartIsOpen || donateIsOpen

    scroll.current.paused(open)
  }, [menuIsOpen, cartIsOpen, donateIsOpen])

  function setPageColour () {
    gsap.to(document.documentElement, {
      '--background': pageColor,
      '--foreground': pageTextColor,
      duration: 0.8,
      ease: 'power3.inOut'
    })
  }

  useEffect(() => {
    setPageColour()
  }, [])

  useEffect(() => {
    const timeline = gsap.timeline({
      defaults: {
        duration: 0.8,
        ease: 'power3.inOut'
      }
    })

    timeline.add(() => {
      const firstSlice = first(page.slices)
      if (firstSlice?.bgColor) return
      if (firstSlice?._type === 'aboutHero') return
      if (firstSlice?._type === 'pathwaysHero') return
      if (firstSlice?._type === 'impactHero') return
      setHeaderColours({
        background: firstSlice?.bgColor || pageColor,
        foreground: firstSlice?.fgColor || pageTextColor,
        duration: 0.8,
        ease: 'power3.inOut'
      })
    })

    if (pageData === page) return
    if (pageData._id === page._id) return

    const pageTransitionWrapper = container.current.querySelector('.page-transition-wrapper')
    const navBackground = document.getElementById('nav-background')
    timeline.to([pageTransitionWrapper, navBackground], {
      opacity: 0,
      onComplete: () => {
        ScrollTrigger.getAll().forEach(t => t.kill())
        const firstSlice = first(page.slices)
        setPageColour()
        if (firstSlice?.bgColor) {
          setHeaderColours({
            background: firstSlice?.bgColor || theme.colors.pageTheme.default.background,
            foreground: firstSlice?.fgColor || theme.colors.pageTheme.default.foreground,
            duration: 0.3,
            ease: 'expo.out'
          })
        } else if (firstSlice?._type === 'aboutHero') {
          setHeaderColours({
            foreground: '#000000',
            background: 'rgba(252,191,156,1)',
            duration: 0.3,
            ease: 'power2.inOut'
          })
        } else if (firstSlice?._type === 'impactHero') {
          setHeaderColours({
            foreground: '#000000',
            background: 'rgba(233, 252, 156,1)',
            duration: 0.3,
            ease: 'power2.inOut'
          })
        }
        setCursorVisible(false)
        setVideoCursorVisible(false)
        setPageData(page)
        scroll.current.scrollTop(0)
        setTimeout(() => {
          setCursorSize('normal')
        }, 600)
      }
    })
    timeline.to([pageTransitionWrapper, navBackground], {
      opacity: 1,
      duration: 0.1
    })
    timeline.add(() => {
      scroll.current.effects(
        document.querySelectorAll('*[data-speed], *[data-lag]')
      )
      ScrollTrigger.refresh()
    })
  }, [page])

  return (
    <>
      <GlobalStyles />
      <Header />
      <Meta seo={seo} page={page} />
      <div
        id='smooth-wrapper'
        className={cn(classes.container)}
        ref={container}
      >
        <ScrollContent page={pageData} />
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }
})
