import * as Dialog from '@radix-ui/react-dialog'
import { keyframes } from 'goober'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import { CloseIcon } from './CloseIcon'
import { teamPropType } from './TeamListing.types'

export const BioDialog = ({ teamMember, pageTitle, onClose }) => {
  const styles = useStyles()

  const handleClickClose = useCallback(() => {
    onClose()
  }, [])

  useEffect(() => {
    const scroller = ScrollSmoother.get()
    if (scroller) {
      scroller.paused(!!teamMember)
    }
  }, [teamMember])

  return (
    <Dialog.Root modal open={!!teamMember}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={styles.content}
          onPointerDownOutside={handleClickClose}
        >
          <div className={styles.inner}>
            <div className={styles.header}>
              <span className={styles.pageTitle}>{pageTitle}</span>
              <Dialog.Close
                className={styles.closeButton}
                onClick={handleClickClose}
                title="Close bio"
              >
                <CloseIcon />
              </Dialog.Close>
            </div>
            {!!teamMember && (
              <>
                <div className={styles.details}>
                  <h1 className={styles.name}>{teamMember.name}</h1>
                  {!!teamMember.links?.length && (
                    <div className={styles.links}>
                      {teamMember.links.map((link, index) => (
                        <Link
                          className={styles.link}
                          showExternalIcon={false}
                          key={index}
                          to={link.url}
                        >
                          {link.text}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
                {teamMember.bio && (
                  <p className={styles.bio}>{teamMember.bio}</p>
                )}
                {teamMember.headshot && (
                  <div className={styles.image}>
                    <ResponsiveImage
                      className={styles.asset}
                      image={teamMember.headshot}
                    />
                    {teamMember.headshot.caption && (
                      <span className={styles.caption}>
                        {teamMember.headshot.caption}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const enter = keyframes`
  from {
    opacity: 0.5;
    transform: translate(-100%, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`

const useStyles = createUseStyles({
  root: {
    backgound: 'red'
  },
  overlay: {
    position: 'fixed',
    zIndex: 1001 + 50,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,0.2)'
  },
  content: {
    background: '#fff',
    position: 'fixed',
    zIndex: 1001 + 100,
    transform: 'translate(0, 0)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    animation: `${enter} 0.25s ease-out`,
    [theme.breakpoints.up('md')]: {
      right: 'auto',
      width: '75%'
    }
  },
  inner: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${theme.grid.sm.columns}, minmax(0, 1fr))`,
    columnGap: theme.grid.sm.gutter,
    padding: `0 ${theme.grid.sm.margin}px`,
    overflow: 'hidden auto',
    paddingBottom: theme.section.sm.marginBottom,
    [theme.breakpoints.up('md')]: {
      columnGap: theme.grid.md.gutter,
      padding: `0 ${theme.grid.md.margin}px`,
      paddingBottom: theme.section.md.marginBottom,
      gridTemplateColumns: 'repeat(9, minmax(0, 1fr))'
    }
  },
  header: {
    height: 100,
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pageTitle: {
    fontSize: theme.fonts.rem(12),
    color: 'rgba(0, 0, 0, 0.5)',
    textTransform: 'uppercase',
    ...theme.fonts.monoObj
  },
  closeButton: {
    background: 'transparent',
    border: '0 none',
    margin: 0,
    padding: theme.spacing(2),
    outline: '0 none'
  },
  details: {
    gridColumn: '1 / -1',
    marginBottom: theme.section.sm.marginBottom,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.section.md.marginBottom
    }
  },
  name: {
    ...theme.fonts.headingFontObj,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      fontSize: '11vw'
    }
  },
  links: {
    display: 'flex',
    gap: theme.spacing(3)
  },
  link: {
    fontSize: theme.fonts.rem(12),
    textTransform: 'uppercase',
    ...theme.fonts.monoObj,
    textDecoration: 'none'
  },
  bio: {
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 4'
    }
  },
  image: {
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 4'
    }
  },
  asset: {
    marginBottom: theme.spacing(1)
  },
  caption: {
    ...theme.fonts.monoObj,
    textTransform: 'uppercase',
    fontSize: theme.fonts.rem(12)
  }
})

BioDialog.propTypes = {
  teamMember: teamPropType,
  pageTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
