import { useEffect, useRef, useState } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import cn from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

export default function ProductMessages ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const quoteRef = useRef()

  const [refCallback, slider, sliderNode] = useKeenSlider({
    slides: {
      perView: 3,
    },
    loop: true,
    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          perView: 1.2,
        },
        loop: true
      }
    }
  })

  const { quotes } = data

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef} fullWidth >
      <h3 className={classes.title}>{data.title}</h3>
      <div ref={refCallback} className={cn('keen-slider', classes.slider)}>
        {quotes && quotes.map((quote, i) => (
          <div key={data._key + i + quote._ref} className='keen-slider__slide'>
            <div className={cn(classes.quoteButton)}>
              <div className={classes.dot} />
              m// 00{i + 1}<br />
              {quote.name}.
            </div>
            <blockquote className={classes.quote} ref={quoteRef}>
              {quote.quote}
            </blockquote>
          </div>
        ))}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    marginBottom: '128px !important',
    [theme.breakpoints.down('md')]: {
      marginBottom: '84px !important',
    }
  },
  title: {
    gridColumn: '1/-1',
    textTransform: 'uppercase',
    fontSize: 40,
    marginBottom: 32,
    paddingLeft: `${theme.grid.sm.margin}px`,
    paddingRight: `${theme.grid.sm.margin}px`,
    [theme.breakpoints.up('md')]: {
      paddingLeft: `${theme.grid.md.margin}px`,
      paddingRight: `${theme.grid.md.margin}px`
    }
  },
  quoteButton: {
    fontSize: 28,
    lineHeight: '1em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    }
  },
  slider: {
    gridColumn: '1 / -1',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing'
    },
    '& .keen-slider__slide': {
      paddingLeft: `${theme.grid.sm.margin}px`,
      [theme.breakpoints.up('md')]: {
        paddingLeft: `${theme.grid.md.margin}px`,
      }
    }
  },
  quote: {
    margin: 0,
    marginTop: 24,
    width: theme.span(3),
    [theme.breakpoints.down('md')]: {
      width: theme.span(3.5),
    }
  },
  dot: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: '1px solid black',
    marginBottom: 24,
    background: 'transparent',
    transition: 'background 0.3s ease-out'
  }
})
