import { useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import gsap from 'gsap'
import cn from 'classnames'

export function Spinner ({ className, spin = true, width = 16 }) {
  const classes = useStyles()
  const spinnerRef = useRef()
  useEffect(() => {
    if (spin) {
      const timeline = gsap.timeline({ repeat: -1 })
      timeline.to(spinnerRef.current, { y: '-50%', duration: 0.15, ease: 'sine.out' }, '+=0.5')
      timeline.to(spinnerRef.current, { y: '0%', duration: 0.15, ease: 'sine.out' }, '+=0.5')
      return () => {
        timeline.kill()
      }
    } else {
      gsap.set(spinnerRef.current, { clearProps: 'y' })
    }
  }, [spin])

  return (
    <div className={cn(classes.container, className)} style={{ fontSize: width }}>
      <svg viewBox='0 0 31 62' fill='none' xmlns='http://www.w3.org/2000/svg' className={classes.spinner} ref={spinnerRef}>
        <path d='M14.793 0C10.872 0 7.11154 1.59236 4.33897 4.42677C1.56639 7.26119 0.00878906 11.1055 0.00878906 15.114C0.00878906 19.1224 1.56639 22.9667 4.33897 25.8011C7.11154 28.6355 10.872 30.2279 14.793 30.2279C18.714 30.2279 22.4744 28.6355 25.247 25.8011C28.0196 22.9667 29.5772 19.1224 29.5772 15.114C29.5772 11.1055 28.0196 7.26119 25.247 4.42677C22.4744 1.59236 18.714 0 14.793 0Z' fill='currentColor' />
        <path d='M4.94386 32.7464L0 37.8005L24.6453 62.9955L29.5892 57.9414L4.94386 32.7464Z' fill='currentColor' />
        <path d='M24.647 32.7527L0.00170898 57.9477L4.94557 63.0018L29.5909 37.8068L24.647 32.7527Z' fill='currentColor' />
      </svg>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    width: '1em',
    height: '1em',
    overflow: 'hidden'
  },
  spinner: {
    width: '1em',
    height: '2em'
  }
})
