import { useAtom, useAtomValue } from 'jotai'
import { useCallback, useRef, useState, useEffect } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import { navViewAtom, NAV_VIEWS } from './headerState'
import cn from 'classnames'
import Input from './Form/Input'
import Checkbox from './Form/Checkbox'
import SubmitButton from './Form/SubmitButton'
import { ChevronDown } from '../ChevronDown'
import useSignUp from '@bigcommerce/storefront-data-hooks/use-signup'
import Errors from './Form/Errors'
import RichContent from '../RichContent'
import gsap from 'gsap'
import { settingsAtom } from '../../state/content'

export default function SignUpForm ({ title, links, settings }) {
  const classes = useStyles()
  const ref = useRef()
  const { singUpSuccessMessage } = settings
  const [view, setView] = useAtom(navViewAtom)
  const signup = useSignUp()
  const [message, setMessage] = useState()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { signUpLabel } = useAtomValue(settingsAtom)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    company: '',
    subscribe: false
  })

  const { email, password, company, firstName, lastName, subscribe } = formData

  const onChange = useCallback((e) => {
    setFormData(data => ({
      ...data,
      [e.target.name]: e.target.value
    }))
  }, [])

  const [loading, setLoading] = useState()

  const onBack = useCallback((e) => {
    setView(NAV_VIEWS.Nav)
    e.preventDefault()
  }, [])

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    if (!loading) {
      const action = async () => {
        try {
          setMessage(null)
          setLoading(true)
          await signup(formData)
          setShowSuccessMessage(true)
        } catch (e) {
          setMessage(e.errors[0].message)
        } finally {
          setLoading(false)
        }
      }
      action()
    }
  }, [formData, loading])

  const onSignIn = useCallback(() => {
    setView(NAV_VIEWS.SignIn)
  }, [])

  const onSubscribeChange = useCallback((e) => {
    setFormData(data => ({
      ...data,
      subscribe: e.target.checked
    }))
  }, [])

  useEffect(() => {
    const isOpen = (view === NAV_VIEWS.SignUp)
    gsap.to(ref.current.children, {
      y: isOpen ? 0 : 40,
      opacity: isOpen ? 1 : 0,
      duration: 0.5,
      stagger: isOpen ? 0.02 : 0,
      delay: isOpen ? 0.1 : 0,
      ease: 'expo.out'
    })
  }, [view])

  const passwordError = message && message.toLowerCase().indexOf('password') >= 0
  const emailError = message && message.toLowerCase().indexOf('email') >= 0

  return (
    <form className={classes.form} onSubmit={onSubmit} ref={ref}>
      <button onClick={onBack} type='button' className={cn(classes.backButton, 'reset-button')}>Back to menu <ChevronDown className={classes.chevron} /></button>
      <h2 className={classes.title}>{signUpLabel}</h2>
      {showSuccessMessage && (
        <RichContent content={singUpSuccessMessage} className={classes.successMessage} />
      )}
      {!showSuccessMessage && (
        <>
          <Input name='firstName' placeholder='First Name' required value={firstName} onChange={onChange} />
          <Input name='lastName' placeholder='Last Name' required value={lastName} onChange={onChange} />
          <Input name='company' placeholder='Company' required value={company} onChange={onChange} />
          <Input name='email' placeholder='Email Address' required type='email' value={email} onChange={onChange} error={emailError} />
          <Input name='password' placeholder='Password' required type='password' value={password} onChange={onChange} error={passwordError} />
          <Checkbox
            name='subscribe'
            value={subscribe}
            onChange={onSubscribeChange}
            label='Keep me updated on Two Good news'
          />
          <Errors message={message} />
          <SubmitButton type='submit' loading={loading}>{signUpLabel}</SubmitButton>
        </>
      )}
      <p className={classes.signUpText}>Already have an account? <button className={cn(classes.linkButton, 'reset-button')} onClick={onSignIn}>Sign In</button></p>
    </form>
  )
}

const useStyles = createUseStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: 'var(--foreground)'
  },
  title: {
    fontSize: 40,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 80
    }
  },
  signUpText: {
    fontSize: 12,
    fontWeight: 'normal',
    alignSelf: 'flex-end',
    marginBottom: 0
  },
  linkButton: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  backButton: {
    fontSize: 12,
    alignSelf: 'flex-end',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center'
  },
  chevron: {
    marginLeft: theme.spacing(1),
    transform: 'rotate(180deg)',
    width: 8
  },
  successMessage: {
    textTransform: 'none',
    textAlign: 'right',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    '& h3': {
      fontFamily: theme.fonts.body
    }
  }
})
