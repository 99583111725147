import { useCallback, useRef, useState } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import cn from 'classnames'
import gsap from 'gsap'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import ResponsiveImage from '../ResponsiveImage'
import RichContent from '../RichContent'
import { useScrollReadyListener } from '../../hooks/useScrollReady'

export default function imageCarousel ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()

  const [progress, setProgress] = useState(1)

  const [refCallback, slider, sliderNode] = useKeenSlider({
    slides: {
      perView: 1.5,
      spacing: 20
    },
    slideChanged(slider) {
      setProgress(slider.track.details.rel + 1)
    },
    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          perView: 1.33,
          spacing: theme.grid.sm.gutter
        }
      }
    }
  })

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.imageWrap} > div`), {
      trigger: sectionRef.current,
      start: '30% bottom',
      onEnter: item => gsap.to(item, { 
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1, 
      })
    })
  }, []))


  const { title, slides } = data

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <h3 className={classes.title}>{title}</h3>
      <div ref={refCallback} className={cn('keen-slider', classes.slider)}>
        {slides && slides.map((slide, i) => (
          <div key={slide._key} className={cn('keen-slider__slide')}>
            <div className={classes.imageWrap}>
              <ResponsiveImage image={slide.image} aspect={804/452} mobileAspect={1}/>
            </div>
            <div className={classes.caption}>
              <div className={classes.index}>{i+1}.</div>
              <div className={classes.copy}>
                <RichContent content={slide.copy} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.progress}>
        <div className={classes.progressLine} style={{transform: `scaleX(${100 / slides.length * progress}%`}}/> 
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    gridColumn: '1 / -1'
  },
  slider: {
    gridColumn: '1 / -1'
  },
  title: {
    gridColumn: '1 / -1',
    textTransform: 'uppercase',
    fontSize: 40,
    lineHeight: '0.9em',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    }
  },
  caption: {
    marginTop: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridGap: theme.grid.md.gutter
  },
  index: {
    gridColumn: 'span 2',
    fontFamily: theme.fonts.monoFace,
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 1',
    }
  },
  copy: {
    gridColumn: 'span 4',
    [theme.breakpoints.down('md')]: {
      gridColumn: '2 / -1',
    }
  },
  progress: {
    gridColumn: '1 / -1',
    width: '100%',
    height: 1,
    background: 'rgba(0,0,0,0.2)',
    marginTop: 76 - 24,
    marginBottom: 40,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginTop: 45 - 24,
    }
  },
  progressLine: {
    position: 'absolute',
    height: 1,
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,1)',
    width: '100%',
    transformOrigin: '0 0',
    transition: 'transform 0.3s cubic-bezier(0.23, 0, 0, 1)'
  },
  imageWrap: {
    overflow: 'hidden',
    '& > div': {
      opacity: 0,
      transform: 'scale(1.05)'
    }
  }
})
