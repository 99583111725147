import cn from 'classnames'
import { useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import useScrollTrigger from '../../hooks/useScrollTrigger'
import { useAtom } from 'jotai'
import { headerColoursAtom, headerFadeInAtom } from '../Header/headerState'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import Player from '../VimeoPlayer'

export const PathwaysHero = ({ data }) => {
  const { title } = data ?? {}
  const styles = useStyles()
  const [, setHeaderColours] = useAtom(headerColoursAtom)
  const [headerFadeIn] = useAtom(headerFadeInAtom)

  const titleRef = useRef()

  const sectionRef = useScrollTrigger(
    () => {
      return {
        trigger: sectionRef.current,
        start: () => 'top 0',
        end: () =>
          `bottom ${
            window.innerWidth < theme.breakpoints.values.md ? 500 : 350
          }px`,
        scrub: false,
        onLeave: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onEnterBack: () => {
          setHeaderColours({
            foreground: '#000000',
            background: 'rgba(252,191,156,1)',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onLeaveBack: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        }
      }
    },
    tl => {},
    () => {
      setHeaderColours({
        background: theme.colors.pageTheme.default.background,
        foreground: '#000000',
        duration: 0.3,
        ease: 'expo.out'
      })
    }
  )

  useEffect(() => {
    if (!headerFadeIn) return

    const titleSplit = new SplitText(titleRef.current, {
      type: 'words, lines',
      linesClass: 'split-parent',
      wordsClass: 'word-split-parent'
    })
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      }
    })

    tl.add(() => {
      setHeaderColours({
        foreground: '#000000',
        background: 'rgba(252,191,156,1)',
        duration: 0.3,
        ease: 'power2.inOut'
      })
    })
    tl.to(sectionRef.current, { opacity: 1, duration: 0.3 })

    tl.set(titleRef.current, {
      opacity: 1
    })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => titleSplit.revert()
    })
    tl.from(
      sectionRef.current.querySelectorAll(`.${styles.video} > div`),
      {
        opacity: 0,
        scale: 1.05
      },
      '-=0.5'
    )
    return () => {
      titleSplit.revert()
    }
  }, [headerFadeIn])

  return (
    <div className={styles.root} ref={sectionRef}>
      <Section grid className={styles.grid}>
        <h1 className={cn(styles.title)} ref={titleRef}>
          {title}
        </h1>
        <div className={styles.video}>
          <Player
            id={data.vimeoId}
            vimeoData={data.vimeoData}
            previewData={data.previewData}
          />
        </div>
      </Section>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    marginTop: -132,
    paddingTop: 131 + 42,
    opacity: 0,
    background:
      'linear-gradient(180deg, rgba(247, 247, 247, 0.00) 47.40%, #FFF 100%), rgba(252, 202, 156, 0.54)'
  },
  grid: {
    [theme.breakpoints.up('md')]: {
      rowGap: '0!important'
    }
  },
  title: {
    textTransform: 'uppercase',
    gridColumn: '1 / -1',
    margin: 0,
    lineHeight: '0.9em',
    letterSpacing: '-0.04em',
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'wrap',
      fontSize: 'calc((100vw - 48px) * 0.15)'
    },
    opacity: 0,
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1em',
      paddingTop: '0.1em'
    }
  },
  video: {
    position: 'relative',
    gridColumn: '1 / -1',
    overflow: 'hidden'
  }
})
