import { useRef, useState } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import cn from 'classnames'

const Checkbox = ({ children }) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(false)

  return (
    <button className={cn(classes.checkboxWrap, 'reset-button')} onClick={() => setChecked(!checked)}>
      <div className={cn(classes.checkbox, checked && classes.checkboxChecked)} />
      {children}
    </button>
  )
}

export default function RecipeSlice ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()

  const { ingredients, method } = data

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <div className={classes.column}>
        <h4 className={classes.title}>Ingredients</h4>
      
        <ul className={classes.ingredientsList}>
          {ingredients.map(ingredient => <li key={ingredient}>
            <Checkbox>{ingredient}</Checkbox>
          </li>)}
        </ul>
      </div>
      <div className={classes.column}>
        <h4 className={classes.title}>Method</h4>
        <div className={classes.method}>
          <RichContent content={method} />
        </div>
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  column: {
    gridColumn: 'span 6',
    letterSpacing: '0.01em',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
    }
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 24,
    lineHeight: '0.9em',
    marginBottom: 60
  },
  ingredientsList: {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  checkbox: {
    width: 16,
    height: 16,
    border: `1px solid var(--foreground)`,
    background: 'var(--background)',
    borderRadius: '50%',
    transition: 'background 0.2s ease-out',
    marginRight: 18,
    marginTop: -4
  },
  checkboxChecked: {
    background: 'var(--foreground) !important',
  },
  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  method: {
    position: 'relative',
    paddingLeft: theme.span(.75),
    paddingRight: theme.span(.75),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.span(1),
      paddingRight: 0,
    },
    '& .section-label': {
      font: 'inherit',
      left: 0,
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block !important',
        // position: 'static',
        // marginBottom: -24,
      },
    }
  }
})
