import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import ResponsiveImage from '../ResponsiveImage'
import cn from 'classnames'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useScrollReadyListener } from '../../hooks/useScrollReady'

export default function ArticleImageGrid ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()

  const { images } = data

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.image}, .${classes.caption}`), {
      start: '30% bottom',
      onEnter: item => gsap.to(item, { 
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1, 
      })
    })
  }, []))

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      {images && images.map(image => <div key={image._key} className={cn(classes.imageBlock, (image.layout === 'expanded') && classes.imageBlockExpanded)}>
        <div className={classes.imageWrap}>
          <ResponsiveImage className={classes.image} image={image.image} speed='0.9'/>
        </div>
        <div className={classes.caption}>{image.caption}</div>
      </div>)}
    </Section>
  )
}

const useStyles = createUseStyles({
  imageBlock: {
    gridColumn: '7 / -2',
    marginBottom: 128,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      marginBottom: 24,
      '&:last-child': {
        marginBottom: 0
      },
    }
  },
  imageBlockExpanded: {
    gridColumn: '3 / -3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    }
  },
  imageWrap: {
    overflow: 'hidden'
  },
  image: {
    opacity: 0,
    transform: 'scale(1.05)'
  },
  caption: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    marginTop: 8,
    opacity: 0
  }
})
