import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import gsap from 'gsap'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import SplitText from 'gsap/dist/SplitText'

import CtaLink from '../CtaLink'
export default function ProductQuote({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const quoteRef = useRef()

  useScrollReadyListener(
    useCallback(() => {
      const quoteSplit = new SplitText(quoteRef.current, {
        type: 'lines',
        linesClass: 'split-parent'
      })
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: quoteRef.current,
          start: '30% bottom',
          once: true
        },
        onComplete: () => quoteSplit.revert()
      })
      tl.from(quoteSplit.lines, {
        y: '120%',
        opacity: 0,
        stagger: 0.05,
        ease: 'expo.out',
        delay: 0.1
      })
      tl.from(
        sectionRef.current.querySelector(`.${classes.link}`),
        {
          y: 20,
          opacity: 0
        },
        '-=0.4'
      )
    }, [])
  )

  return (
    <Section tag="section" className={classes.section} grid ref={sectionRef}>
      <blockquote className={classes.quote} ref={quoteRef}>
        {data.quote}
      </blockquote>
      {data.attribution && (
        <div className={classes.attribution}>{data.attribution}</div>
      )}
      <div className={classes.link}>
        <CtaLink link={data.link} />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  attribution: {
    gridColumn: '2 / -2',
    textAlign: 'center'
  },
  quote: {
    width: '100%',
    maxWidth: 680,
    marginLeft: 'auto',
    marginRight: 'auto',
    gridColumn: '2 / -2',
    textAlign: 'center',
    fontSize: 56,
    lineHeight: '1em',
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    textTransform: 'uppercase',
    letterSpacing: '-0.03em',
    marginTop: 84,
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'normal',
      gridColumn: '1 / -1',
      fontSize: 40,
      marginTop: 64,
      marginBottom: 32,
      marginLeft: 0,
      marginRight: 0
    },
    '& .split-parent': {
      overflowY: 'hidden'
      // lineHeight: '1.1em',
      // marginBottom: '-0.25em'
    }
  },
  link: {
    gridColumn: '2 / -2',
    textAlign: 'center'
  }
})
