import { useState, useCallback, useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import Link from '../Link'
import cn from 'classnames'
import gsap from 'gsap'
import { useAtom, useAtomValue } from 'jotai'
import { navViewAtom, NAV_VIEWS } from './headerState'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
import useLogout from '@bigcommerce/storefront-data-hooks/use-logout'
import useCustomer from '../../hooks/useCustomer'
import toArray from 'lodash/toArray'
import remove from 'lodash/remove'
import { isMobileAtom } from '../../state/layout'
import { getScrollbarWidth } from '../../helpers/scrollbar'
import Donate from './Donate'
import { settingsAtom } from '../../state/content'

const Line = props => {
  const classes = useStyles()

  return (
    <div className={cn(classes.svgContainer)}>
      <svg
        width="100%"
        height="16"
        viewBox="0 0 255 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <defs>
          <pattern
            id="pencil-stroke"
            patternUnits="userSpaceOnUse"
            width="100%"
            height="100%"
          >
            <image className={classes.svgImage} href="/header-animation.svg" />
          </pattern>
        </defs>
        <path
          className={cn(classes.svgPath)}
          d="M1 8.07463C6 9.07463 12 11.0746 24 10.5746C55.5341 9.26072 88 3.26641 117.5 4.07463C154 5.07463 172.5 4.07463 184.5 4.07463C207 4.07463 230 7.07463 254 11.5746"
          fill="none"
          stroke="url(#pencil-stroke)"
          strokeWidth="8"
        />
      </svg>
    </div>
  )
}

const SubMenu = ({ title, links }) => {
  const classes = useStyles()

  return (
    <nav className={classes.subMenu}>
      <span className={classes.subMenuTitle}>{title}</span>
      {links &&
        links.map(link => (
          <div key={`submenu-${link._key}`} className={classes.subMenuLink}>
            <Link link={link} showExternalIcon={false} />
          </div>
        ))}
    </nav>
  )
}

export default function Nav({ isOpen, navItems, settings }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const viewContainerRef = useRef()
  const [view, setView] = useAtom(navViewAtom)
  const [isMobile] = useAtom(isMobileAtom)
  const [lastActive, setLastActive] = useState(null)

  const { data } = useCustomer()
  const logout = useLogout()

  const { donateLabel, signInLabel, signOutLabel } = useAtomValue(settingsAtom)

  const isAuthenticated = !!data

  useEffect(() => {
    gsap.set(sectionRef.current, {
      pointerEvents: isOpen ? 'all' : 'none'
    })

    gsap.to(sectionRef.current, {
      clipPath: isOpen ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 100% 0%)',
      duration: 0.5,
      ease: 'expo.out'
    })

    gsap.to(
      sectionRef.current.querySelectorAll(
        `.${classes.subMenu} span, .${classes.subMenu} a`
      ),
      {
        y: isOpen ? 0 : 20,
        opacity: isOpen ? 1 : 0,
        duration: 0.5,
        stagger: isOpen ? -0.02 : 0,
        delay: isOpen ? 0.4 : 0,
        ease: 'expo.out'
      }
    )
  }, [isOpen])

  useEffect(() => {
    const _isOpen = view === NAV_VIEWS.Nav && isOpen

    gsap.to(sectionRef.current.querySelectorAll(`.${classes.link}`), {
      y: _isOpen ? 0 : '100%',
      opacity: _isOpen ? 1 : 0,
      duration: 0.5,
      stagger: _isOpen ? 0.02 : 0,
      delay: _isOpen ? 0.2 : 0,
      ease: 'expo.out'
    })
  }, [isOpen, view])

  const onSignInClick = useCallback(() => {
    setView(NAV_VIEWS.SignIn)
  }, [])

  const onSignOutClick = useCallback(() => {
    const run = async () => {
      await logout()
    }
    run()
  }, [])

  const onDonateClick = useCallback(() => {
    setView(NAV_VIEWS.Donate)
  }, [])

  const onHandler = useCallback(e => {
    const elements = sectionRef.current.querySelectorAll(`.${classes.svgPath}`)
    const links = [...elements]

    links.map((item, i) => {
      if (i === e) {
        gsap.fromTo(
          item,
          { strokeDashoffset: 255 },
          { strokeDashoffset: '0', duration: 0.75, ease: 'expo.out' }
        )
      }
    })

    setLastActive(e)
  })

  const offHandler = useCallback(e => {
    const elements = sectionRef.current.querySelectorAll(`.${classes.svgPath}`)
    const links = [...elements]
    const tl = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.75
      }
    })

    links.map((item, i) => {
      if (i === lastActive) {
        tl.to(item, { strokeDashoffset: '-255px' })
        tl.set(item, { strokeDashoffset: '255px' })
      }
    })
  })

  useEffect(() => {
    gsap.set(sectionRef.current, {
      width: `calc(100vw - ${getScrollbarWidth()}px`
    })
  }, [])

  useEffect(() => {
    const views = toArray(viewContainerRef.current.children)
    const selectedView = viewContainerRef.current.querySelectorAll(
      `.${view}`
    )[0]
    remove(views, x => x === selectedView)
    if (isMobile) {
      gsap.set(views, { position: 'absolute', autoAlpha: 0 })
      gsap.set(selectedView, { position: 'static', autoAlpha: 1 })
    } else {
      gsap.set([...views, selectedView], { clearProps: 'position' })
      gsap.to(views, { autoAlpha: 0, duration: 0.35, ease: 'sine.inOut' })
      gsap.to(selectedView, {
        autoAlpha: 1,
        duration: 0.35,
        ease: 'sine.inOut'
      })
    }
  }, [view, isMobile])

  return (
    <Section
      tag="nav"
      grid
      noBottomMargin
      className={cn(classes.nav)}
      fullWidth
      ref={sectionRef}
    >
      <div className={classes.viewContainer} ref={viewContainerRef}>
        <nav className={cn(classes.navItems, NAV_VIEWS.Nav)}>
          {navItems &&
            navItems.map((item, i) => (
              <div
                className={classes.lineWrapper}
                onMouseEnter={() => onHandler(i)}
                onMouseLeave={() => offHandler(i)}
                key={item._key}
              >
                <Link className={classes.link} link={item} />
                <Line index={i} hoverLink={lastActive} />
              </div>
            ))}
          <div
            className={classes.lineWrapper}
            onMouseEnter={() => onHandler(navItems?.length)}
            onMouseLeave={() => offHandler(navItems?.length)}
          >
            <Link className={classes.link} onClick={onDonateClick}>
              {donateLabel}
            </Link>
            <Line index={navItems?.length} hoverLink={lastActive} />
          </div>
          <div
            className={classes.lineWrapper}
            onMouseEnter={() => onHandler(navItems?.length + 1)}
            onMouseLeave={() => offHandler(navItems?.length + 1)}
          >
            <Link
              className={classes.link}
              onClick={isAuthenticated ? onSignOutClick : onSignInClick}
            >
              {isAuthenticated ? signOutLabel : signInLabel}
            </Link>
            <Line index={navItems?.length + 1} hoverLink={lastActive} />
          </div>
        </nav>
        <div className={cn(classes.formContainer, NAV_VIEWS.SignIn)}>
          <SignInForm />
        </div>
        <div className={cn(classes.formContainer, NAV_VIEWS.SignUp)}>
          <SignUpForm settings={settings} />
        </div>
        <div
          className={cn(
            classes.formContainer,
            classes.donateContainer,
            NAV_VIEWS.Donate
          )}
        >
          <Donate settings={settings} />
        </div>
      </div>
      <div className={classes.subMenuWrapper}>
        <SubMenu
          title={settings.footerLeftMenuTitle}
          links={settings.footerLeftMenuItems}
        />
        <SubMenu
          title={settings.centerMenuTitle}
          links={settings.centerMenuItems}
        />
        <SubMenu
          title={settings.rightMenuTitle}
          links={settings.rightMenuItems}
        />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  nav: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    clipPath: 'inset(0% 0% 100% 0%)',
    zIndex: 1,
    paddingTop: theme.spacing(5),
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(14),
      paddingBottom: theme.spacing(5),
      overflow: 'auto'
    }
  },
  bg: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    background: 'black',
    zIndex: '-1'
  },
  lineWrapper: {
    overflow: 'hidden',
    lineHeight: '1em',
    height: '1em',
    width: 'fit-content',
    position: 'relative'
  },
  viewContainer: {
    position: 'relative',
    gridColumn: '9 / -1',
    gridRow: '1',
    marginRight: theme.grid.md.margin,
    marginBottom: theme.grid.md.margin,
    alignSelf: 'flex-end',
    justifySelf: 'end',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
      // width: 'calc(100% - 48px)'
    }
  },
  navItems: {
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: 80,
    '@media(max-height: 760px)': {
      fontSize: 56
    },
    '& .external-link-icon': {
      width: 'auto',
      height: '0.55em'
    },
    '& .external-link-icon path': {
      stroke: 'white'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
      alignSelf: 'flex-end',
      justifySelf: 'flex-end'
    }
  },
  subMenuWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridColumn: '1/9',
    gridRowGap: 30,
    gridRow: '1',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('md')]: {
      gridRow: 2,
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridColumn: '1 / -1'
    }
  },
  subMenu: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.grid.md.margin,
    marginBottom: theme.grid.md.margin,
    gridColumn: 'span 4',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 3',
      '&:first-of-type': {
        gridColumn: 'span 4'
      }
    },

    '& a': {
      alignSelf: 'flex-start',
      textDecoration: 'none',
      color: 'var(--foreground)',
      textTransform: 'none'
    },
    '& span, & a': {
      opacity: 0,
      transform: 'translateY(20px)'
    }
  },
  subMenuTitle: {
    textTransform: 'uppercase',
    opacity: 0.5,
    marginBottom: 32,
    fontSize: 12,
    color: 'rgba(255,255,255, 0.5)',
    [theme.breakpoints.down('md')]: {
      marginBottom: 16
    }
  },
  subMenuLink: {
    '& a': {
      transition: 'opacity 0.25s'
    },
    '& a:hover': {
      opacity: '0.5 !important'
    }
  },
  link: {
    display: 'inline-block',
    color: 'var(--foreground)',
    lineHeight: '1em',
    opacity: 0,
    transform: 'translateY(100%)',
    cursor: 'pointer'
  },
  formContainer: {
    background: theme.colors.background,
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    maxWidth: 365,
    width: '100%'
  },
  donateContainer: {
    zIndex: 100,
    maxWidth: 'unset !important',
    paddingLeft: 24,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  svgContainer: {
    position: 'absolute',
    bottom: -20,
    right: 0,
    width: '100%',
    pointerEvents: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  svgPath: {
    strokeDasharray: '255px',
    strokeDashoffset: '255px',
    transitionDuration: '0s',
    transitionDelay: '0s'
  },
  activeLink: {}
})
