import { forwardRef } from 'react'
import cn from 'classnames'
import { theme } from '../styles/theme'
import createUseStyles from '../lib/createUseStyles'

const Section = forwardRef(({
  className,
  tag = 'section',
  fullWidth,
  insetGutter = false,
  noBottomMargin,
  topPadding = false,
  children,
  grid,
  ...props
}, ref) => {
  const styles = useStyles()
  const Tag = tag
  return (
    <Tag
      ref={ref}
      className={cn(className, styles.section, {
        gutter: !fullWidth,
        bottomMargin: !noBottomMargin,
        grid,
        topPadding,
        insetGutter
      })}
      {...props}
    >
      {children}
    </Tag>
  )
})

Section.displayName = 'Section'

const useStyles = createUseStyles({
  section: {
    '&.gutter': {
      marginLeft: `${theme.grid.sm.margin}px`,
      marginRight: `${theme.grid.sm.margin}px`,
      [theme.breakpoints.up('md')]: {
        marginLeft: `${theme.grid.md.margin}px`,
        marginRight: `${theme.grid.md.margin}px`
      }
    },
    '&.insetGutter': {
      paddingLeft: `${theme.grid.sm.margin}px`,
      paddingRight: `${theme.grid.sm.margin}px`,
      [theme.breakpoints.up('md')]: {
        paddingLeft: `${theme.grid.md.margin}px`,
        paddingRight: `${theme.grid.md.margin}px`
      }
    },
    '&.bottomMargin': {
      marginBottom: theme.section.sm.marginBottom,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.section.md.marginBottom
      }
    },
    '&.grid': {
      display: 'grid',
      gridTemplateColumns: `repeat(${theme.grid.sm.columns}, minmax(0px, 1fr))`,
      gap: `${theme.grid.sm.gutter}px`,
      [theme.breakpoints.up('md')]: {
        gap: `${theme.grid.md.gutter}px`,
        gridTemplateColumns: `repeat(${theme.grid.md.columns}, minmax(0px, 1fr))`
      }
    },
    '&.topPadding': {
      paddingTop: 150,
      [theme.breakpoints.up('md')]: {
        paddingTop: 200
      }
    }
  }
})

export default Section
