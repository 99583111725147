import Player from '../VimeoPlayer'
import Section from '../Section'

export default function VimeoPlayer ({ data }) {
  return (
    <Section>
      <Player id={data.vimeoId} vimeoData={data.vimeoData} previewData={data.previewData}/>
    </Section>
  )
}
