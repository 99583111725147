import { useEffect, useRef } from 'react'
import createUseStyles from '../../../../lib/createUseStyles'
import gsap from 'gsap'

export default function Overlay ({ show, onClick }) {
  const styles = useStyles()
  const ref = useRef()

  useEffect(() => {
    gsap.to(ref.current, {
      opacity: show ? 1 : 0,
      pointerEvents: show ? 'all' : 'none',
      duration: 0.25
    })
  }, [show])

  return <div ref={ref} className={styles.overlay} onClick={onClick} />
}

const useStyles = createUseStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backdropFilter: 'blur(24px)',
    zIndex: 1,
    opacity: 0,
    background: 'rgba(255, 255, 255, 0.5)'
  }
})
