import { useCallback, useMemo, useState } from 'react'

export const useLocalPagination = (list, pageSize = 18) => {
  const [currentPage, setPage] = useState(1)
  const pageCount = Math.ceil(list.length / pageSize)
  const hasMore = currentPage < pageCount

  const handleClickMore = useCallback(() => {
    setPage(page => Math.min(pageCount, page + 1))
  }, [pageCount])

  const visibleItems = useMemo(
    () => list.slice(0, currentPage * pageSize),
    [list, currentPage, pageSize]
  )

  return {
    handleClickMore,
    visibleItems,
    hasMore,
    currentPage
  }
}
