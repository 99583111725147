import { theme } from './theme'

export const roundedButton = {
  textTransform: 'uppercase',
  fontSize: theme.fonts.rem(12),
  height: 35,
  borderRadius: 1000,
  border: '1px solid #000',
  alignSelf: 'flex-start',
  padding: `3px ${theme.spacing(2)}px 0`, // 3px top offset for weird font line height rendering
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  background: 'transparent',
  letterSpacing: 0.6,
  transition: 'all 0.15s',
  '&:hover': {
    opacity: 0.8
  },
  '&:active': {
    opacity: 0.5
  }
}
