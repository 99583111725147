import { useEffect } from 'react'
import remove from 'lodash/remove'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'
import lerp from 'lerp'
import forEach from 'lodash/forEach'

const CURSOR_ENABLED = true

const EASE = 0.14

export const data = {
  mouse: {
    x: 0,
    y: 0
  },
  current: {
    x: undefined,
    y: undefined
  },
  last: {
    x: undefined,
    y: undefined
  },
  ease: EASE,
  fx: {
    diff: 0,
    acc: 0,
    velocity: 0,
    scale: 1
  },
  fy: {
    diff: 0,
    acc: 0,
    velocity: 0,
    scale: 1
  }
}

const mouseMoveCallbacks = []

export const useCursor = (mouseMoveCallback) => {
  useEffect(() => {
    mouseMoveCallbacks.push(mouseMoveCallback)
    return () => {
      remove(mouseMoveCallbacks, x => x === mouseMoveCallback)
    }
  }, [mouseMoveCallback])
}

const Cursor = () => {
  useEffect(() => {
    if (CURSOR_ENABLED && primaryInput !== 'touch') {
      const updateMousePosition = (evnt) => {
        const e = evnt.detail && evnt.detail.pageX ? evnt.detail : evnt
        data.mouse.x = e.pageX
        data.mouse.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)

        data.current.x = e.pageX
        data.current.y = e.pageY - (window.pageYOffset || document.documentElement.scrollTop)
      }
      window.addEventListener('mousemove', updateMousePosition, { passive: true })
      window.addEventListener('dragover', updateMousePosition, { passive: true })
      window.addEventListener('flickity-dragMove', updateMousePosition, { passive: true })
      return () => {
        window.removeEventListener('mousemove', updateMousePosition)
        window.removeEventListener('dragover', updateMousePosition)
        window.removeEventListener('flickity-dragMove', updateMousePosition)
      }
    }
  }, [])

  useEffect(() => {
    if (CURSOR_ENABLED && primaryInput !== 'touch') {
      const track = () => {
        if (data.current.x !== undefined) {
          // on the initial mouse move, we do not want any easing. Just put the mouse on the cursor
          if (data.last.x === undefined) {
            data.last.x = data.current.x
            data.last.y = data.current.y
          } else {
            data.last.x = lerp(data.last.x, data.current.x, data.ease)
            data.last.y = lerp(data.last.y, data.current.y, data.ease)
          }

          data.fx.diff = data.current.x - data.last.x
          data.fx.acc = data.fx.diff / window.innerWidth
          data.fx.velocity = +data.fx.acc

          data.fy.diff = data.current.y - data.last.y
          data.fy.acc = data.fy.diff / window.innerWidth
          data.fy.velocity = +data.fy.acc
          forEach(mouseMoveCallbacks, cb => cb(data))
        }
      }
      gsap.ticker.add(track)
      return () => {
        gsap.ticker.remove(track)
      }
    }
  }, [])

  if (!CURSOR_ENABLED) return null

  return null
}

export default Cursor
