import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import ShopPill from '../ShopPill'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import gsap from 'gsap'

export default function HomeProductCollection ({ data }) {
  const classes = useStyles()
  const sectionRef = useRef()

  const { products: collections } = data

  useScrollReadyListener(useCallback(() => {
    gsap.to(sectionRef.current.querySelectorAll(`.${classes.image}, .${classes.pill} > div`), {
      opacity: 1,
      scale: 1,
      duration: 0.6,
      ease: 'power2.inOut',
      stagger: 0.1,
      scrollTrigger: {
        trigger: sectionRef.current,
        start: '30% bottom'
      }
    })
  }, []))

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <header className={classes.header}>
        <span>{data.leftTitle}</span>
        <span>{data.rightTitle}</span>
        <div className={classes.line} />
      </header>
      {collections &&
        collections.map(collection => (
          <div key={collection._key} className={classes.collection}>
            <ShopPill
              link={collection.link}
              className={classes.pill}
              title={collection.title}
              colour={
                collection.pillButton?.bgColor ||
                collection.image.asset?.metadata?.palette.dominant.background
              }
              hoverColour={
                collection.pillButton?.fgColor ||
                collection.image.asset?.metadata?.palette.darkVibrant.background
              }
              textColor={collection.pillButton?.textColor}
              hoverStyle={collection.pillButton?.hoverStyle}
              products={collection.pillButton?.products}
            />
            <ResponsiveImage aspect={450 / 750} className={classes.image} image={collection.image} speed={0.9} />
          </div>
        ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  collection: {
    gridColumn: 'span 4',
    overflow: 'hidden',
    position: 'relative'
  },
  header: {
    gridColumn: '1 / -1',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '-4px',
    '& span': {
      gridColumn: 'span 1',
      fontSize: 12,
      textTransform: 'uppercase'
    },
    '& span:nth-child(2)': {
      textAlign: 'right'
    }
  },
  line: {
    gridColumn: '1 / -1',
    gridRow: '2',
    width: '100%',
    height: 1,
    background: 'currentColor'
  },
  image: {
    opacity: 0,
    transform: 'scale(1.05)'
  },
  pill: {
    '& > div': {
      opacity: 0
    }
  }
})
