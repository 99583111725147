import { useCallback, useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import cn from 'classnames'
import gsap from 'gsap'
import { useAtom } from 'jotai'
import { navViewAtom, NAV_VIEWS } from './headerState'
import { ChevronDown } from '../ChevronDown'
import Script from 'next/script'

export default function Donate ({ settings }) {
  const classes = useStyles()
  const ref = useRef()

  const [view, setView] = useAtom(navViewAtom)

  useEffect(() => {
    const isOpen = (view === NAV_VIEWS.Donate)
    gsap.set(ref.current, {
      pointerEvents: isOpen ? 'all' : 'none'
    })

    gsap.to(ref.current.children, {
      y: isOpen ? 0 : 40,
      opacity: isOpen ? 1 : 0,
      duration: 0.5,
      stagger: isOpen ? 0.05 : 0,
      delay: isOpen ? 0.1 : 0,
      ease: 'expo.out'
    })
  }, [view])

  const {
    donateText
  } = settings

  const onBack = useCallback((e) => {
    setView(NAV_VIEWS.Nav)
    e.preventDefault()
  }, [])

  return (
    <div className={classes.root} ref={ref}>
      <div>
        <button onClick={onBack} type='button' className={cn(classes.backButton, 'reset-button')}>Back to menu <ChevronDown className={classes.chevron} /></button>
        <h3 className={classes.text}>{donateText}</h3>
      </div>
      <div className={cn(classes.iframeContainer, 'raisely-donate')} data-campaign-path="twogoodco" data-profile="" data-width="100%" data-height="800">
        <Script src="https://cdn.raisely.com/v3/public/embed.js" />
      </div>
      <div className={cn(classes.meta, classes.half)}>
        Donations to Two Good Foundation are tax deductible
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    top: 0,
    left: 'calc(24px * 2)',
    width: 'calc(100% - 24px * 2)'
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '0.05em'
  },
  line: {
    height: 1,
    background: 'white',
    opacity: 0.3,
    transformOrigin: '0 0',
    marginTop: 8,
    marginBottom: 24
  },
  half: {
    marginTop: 12,
    opacity: '0.5 !important'
  },
  text: {
    fontSize: 32,
    lineHeight: '0.9em',
    textTransform: 'uppercase',
    maxWidth: '18em',
    [theme.breakpoints.down('md')]: {
      marginBottom: 4,
      fontSize: 12,
      maxWidth: 'unset'
    }
  },
  donateOptions: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    gap: 4,
    marginBottom: theme.grid.md.margin
  },
  button: {
    flex: '1',
    textAlign: 'center',
    borderRadius: 52,
    padding: 16,
    paddingBottom: 14,
    border: '1px solid',
    borderColor: 'rgba(255,255,255,0.2)',
    transition: 'border-color 0.15s ease-out, background 0.15s ease-out, color 0.15s ease-out',
    background: 'black',
    color: 'white',
    '&:hover': {
      borderColor: 'rgba(255,255,255,1)'
    }
  },
  buttonActive: {
    background: 'white',
    color: 'black'
  },
  donateButton: {
    background: 'white',
    color: 'black',
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: '0.06em',
    marginTop: 8,
    '&:hover': {
      background: 'black',
      color: 'white'
    }
  },
  iframeContainer: {
    marginTop: 24,
    [theme.breakpoints.down('md')]: {
      marginTop: 4
    }
  },
  input: {
    width: '100%',
    background: 'black',
    color: 'white',
    border: '1px solid rgba(255,255,255,0.2)',
    fontSize: 'inherit',
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)'
    },
    '&::selection': {
      color: 'black',
      background: 'white'
    },
    '&:focus': {
      background: 'white !important',
      color: 'black !important'
    }
  },
  inputActive: {
    background: 'white !important',
    color: 'black !important'
  },
  backButton: {
    alignSelf: 'flex-end',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    marginBottom: 16
  },
  chevron: {
    marginLeft: theme.spacing(1),
    transform: 'rotate(180deg)',
    width: 8
  }
})
