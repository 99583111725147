import cn from 'classnames'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import createUseStyles from '../../../../lib/createUseStyles'
import { theme } from '../../../../styles/theme'
import { ChevronDown } from '../../../ChevronDown'
import Link from '../../../Link'
import gsap from 'gsap'
import Overlay from './Overlay'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import SplitText from 'gsap/dist/SplitText'
import { primaryInput } from 'detect-it'
import handleEsc from '../../../../helpers/handleEsc'

const Line = (props) => {
  const classes = useStyles()

  const { index, hoverLink } = props

  return (
    <div className={cn(classes.svgContainer)}>
      <svg width='100%' height='16' viewBox='0 0 255 16' fill='none' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'>
        <defs>
          <pattern id='category-filter' patternUnits='userSpaceOnUse' width='100%' height='100%'>
            <image className={classes.svgImage} href='/filter-animation.svg' />
          </pattern>
        </defs>
        <path className={cn(classes.svgPath, index === hoverLink && classes.activeLink)} d='M1 8.07463C6 9.07463 12 11.0746 24 10.5746C55.5341 9.26072 88 3.26641 117.5 4.07463C154 5.07463 172.5 4.07463 184.5 4.07463C207 4.07463 230 7.07463 254 11.5746' fill='none' stroke='url(#category-filter)' strokeWidth='8' />
      </svg>
    </div>
  )
}

export const CategoryFilter = ({ activeCategory, categories, animationImage }) => {
  const styles = useStyles()
  const [open, setOpen] = useState(false)
  const dropDownRef = useRef()
  const sectionRef = useRef()
  const [hoverLink, setHoverLink] = useState(null)

  const handleClickTrigger = useCallback(ev => {
    setOpen(value => !value)
  }, [])

  useEffect(() => {
    const split = new SplitText(sectionRef.current.querySelectorAll(`.${styles.text}`), { type: 'lines', linesClass: 'split-parent' })

    const tl = gsap.timeline()
    tl.fromTo([split.lines[0], split.lines[1]], {
      y: '120%',
      duration: 1.5,
      delay: 0.3
    }, {
      y: 0,
      stagger: 0.2,
      duration: 1,
      ease: 'expo.out'
    }, 0)
    return () => {
      tl.kill()
    }
  }, [])

  useEffect(() => {
    const tl = gsap.timeline()
    if (open) {
      if (primaryInput !== 'touch') {
        const box = dropDownRef.current.getBoundingClientRect()
        const offsetBottom =
          box.bottom + window.scrollY + window.innerHeight / 3
        tl.set(document.body, { height: offsetBottom })
      } else {
        gsap.set(document.body, { overflow: 'hidden' })
      }
      tl.to(dropDownRef.current.querySelectorAll(`.${styles.itemText}`), {
        y: '1%',
        stagger: 0.05,
        duration: 0.2,
        ease: 'expo.out'
      })
    } else {
      gsap.set(document.body, { overflow: 'auto' })
      if (primaryInput !== 'touch') {
        ScrollSmoother.refresh()
      }
      tl.to(dropDownRef.current.querySelectorAll(`.${styles.itemText}`), {
        y: '-100%',
        stagger: -0.02,
        duration: 0.4,
        ease: 'expo.out'
      })
    }
    return () => {
      tl.kill()
    }
  }, [open])

  useEffect(() => {
    const elements = sectionRef.current.querySelectorAll(`.${styles.svgPath}`)
    const links = [...elements]
    links.map(item => {
      if (item.classList.contains(styles.activeLink)) {
        gsap.fromTo(item, {
          strokeDashoffset: '255px'
        }, {
          strokeDashoffset: '0',
          duration: 0.75,
          ease: 'expo.out',
          delay: 0
        })
      } else {
        gsap.to(item, {
          strokeDashoffset: '-255px',
          duration: 0.75,
          ease: 'expo.out',
          delay: 0
        })
      }
    })
  }, [hoverLink])

  useEffect(() => {
    document.addEventListener('keydown', (e) => handleEsc(e, setOpen), false)
    return () => [document.removeEventListener('keydown', (e) => handleEsc(e, setOpen), false)]
  }, [])

  return (
    <>
      <Overlay show={open} onClick={handleClickTrigger} />
      <div className={styles.root} ref={sectionRef}>
        <button className={styles.trigger} onClick={handleClickTrigger} onMouseEnter={() => setHoverLink(-1)} onMouseLeave={() => setHoverLink(null)}>
          <span className={cn('h', styles.text)}>
            <div>Good</div>
            <div className={styles.flexWrap}>
              {activeCategory?.title?.toLowerCase().replace('good ', '').trim() + ' '}
              <ChevronDown className={cn(styles.chevron, { open })} />
            </div>
          </span>
          <Line index={-1} hoverLink={hoverLink} animationImage={animationImage} />
        </button>
        <div className={cn(styles.dropdown, { open })} ref={dropDownRef}>
          <div className={styles.items}>
            {categories
              .filter(({ _id }) => _id !== activeCategory._id)
              .map((category, idx) => (
                <Link
                  showText={false}
                  className={cn('h', styles.item, styles.text)}
                  link={category}
                  key={idx}
                >
                  <span className={styles.itemText} onMouseEnter={() => setHoverLink(idx)} onMouseLeave={() => setHoverLink(null)}>
                    {category.title?.toLowerCase().replace('good ', '')}
                    <Line index={idx} hoverLink={hoverLink} animationImage={animationImage} />
                  </span>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

const categoryPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  slug: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
})

CategoryFilter.propTypes = {
  activeCategory: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(categoryPropType).isRequired,
  onChange: PropTypes.func.isRequired
}

const useStyles = createUseStyles({
  root: {
    position: 'relative'
  },
  trigger: {
    all: 'unset',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'flex-end',
    zIndex: 2,
    position: 'relative'
  },
  flexWrap: {
    display: 'flex'
  },
  chevron: {
    marginBottom: '1vw',
    marginLeft: theme.spacing(1),
    width: 'min(max(3rem, 10vw), 10rem)',
    transition: 'transform 0.15s',
    '&.open': {
      transform: 'scaleY(-1)'
    }
  },
  dropdown: {
    position: 'absolute',
    left: 0,
    top: '100%',
    padding: `${theme.spacing(3.5)}px 0 0`,
    opacity: 1,
    pointerEvents: 'none',
    zIndex: 100000,
    '&.open': {
      pointerEvents: 'all'
    }
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3.5)
  },
  item: {
    textDecoration: 'none',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  itemText: {
    display: 'block',
    transform: 'translateY(-100%)',
    width: 'fit-content'
  },
  text: {
    fontSize: 'min(max(3.5rem, 10vw), 200px)',
    textTransform: 'uppercase',
    marginBottom: 0,
    lineHeight: 0.95,
    letterSpacing: -1,
    whiteSpace: 'pre-wrap'
  },
  svgContainer: {
    position: 'absolute',
    bottom: -25,
    left: 0,
    width: '100%',
    pointerEvents: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  svgPath: {
    strokeDasharray: '255px',
    strokeDashoffset: '255px',
    transitionDuration: '0s',
    transitionDelay: '0s'
  },
  activeLink: {}
})
