import { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import gsap from 'gsap'
import createUseStyles from '../lib/createUseStyles'
import cn from 'classnames'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import find from 'lodash/find'
import { theme } from '../styles/theme'
import useOnOutsideClick from '../hooks/useOnOutsideClick'

const DropDownItem = ({ value, text, selected, onSelected, classNames = {}, selectedIcon }) => {
  const classes = useStyles()
  
  const onOptionSelected = useCallback(() => {
    onSelected(value)
  }, [onSelected, value])

  return (
    <li className={cn(classes.item, classNames.item, { selected })} onClick={onOptionSelected}>
      {text}
    </li>
  )
}

const DropDown = ({ className, classNames = {}, label, options, value, onChange, placeholderText, dropdownAlignment = 'left', selectedIcon }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const dropDownRef = useRef()
  const ref = useRef()

  const toggleOpen = () => {
    setOpen(!open)
  }

  useOnOutsideClick(ref, useCallback(() => {
    setOpen(false)
  }, []), true)

  const selectedText = useMemo(() => {
    return get(find(options, o => o.value === value), ['text'], placeholderText)
  }, [value, options, placeholderText])

  const onItemSelected = useCallback(value => {
    onChange({ target: { value } })
  }, [onChange])

  useEffect(() => {
    if (dropDownRef.current) {
      gsap.to(dropDownRef.current.querySelector(`.${classes.line}`), { scaleX: open ? 1 : 0, duration: 0.15, ease: 'expo.out' })
      gsap.to(dropDownRef.current, { height: open ? 'auto' : 0, duration: 0.15, ease: 'power2.inOut'})
    }
  }, [open])

  if (isEmpty(options)) return null

  return (
    <div ref={ref} className={cn(classes.container, className)}>
      <div className={cn(classes.dropdown, classNames.dropdown)} onClick={toggleOpen}>
        <span className={cn(classes.value, classNames.value, { open })}>
          <span className={classes.text}><span className={classes.label}>{label}</span>{selectedText}</span>
          <svg className={cn(classes.arrow, { open })} viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='m.549 1 7.275 7 7.274-7' stroke='#282728' strokeWidth='2px' />
          </svg>
        </span>
        <div className={cn(classes.dropdownContent, classNames.content, { open }, dropdownAlignment)} ref={dropDownRef}>
          <ul className={classes.dropdownContentList}>
            <li className={classes.line}/>
            {map(options, (option, i) => <DropDownItem {...option} classNames={classNames} selected={option.value === value} key={i} onSelected={onItemSelected} />)}
          </ul>
        </div>
      </div>
      <select className={cn(classes.select, classNames.select)} value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} className={classes.selectOption} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },
  select: {
    zIndex: 0,
    padding: 0,
    marginTop: 10,
    width: '100%',
    height: '2.5em',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 'auto',
      visibility: 'hidden'
    }
  },
  dropdown: {
    position: 'absolute',
    display: 'inline-block',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      pointerEvents: 'all'
    }
  },
  label: {
    marginRight: 10,
    opacity: 0.5
  },
  dropdownContent: {
    overflow: 'hidden',
    zIndex: 1,
    listStyle: 'none',
    backgroundColor: 'transparent',
    position: 'absolute',
    height: 0,
    transform: 'translateY(-1px)',
    top: 'calc(100% - 1px)',
    minWidth: 'calc(100% + 24px)',
    margin: 0,
    padding: 0,
    textAlign: 'left',
    marginLeft: '-24px',
    '&.left': {
      left: 0,
      transformOrigin: '0% 0%'
    },
    '&.right': {
      right: 0,
      transformOrigin: '100% 0%'
    }
  },
  dropdownContentList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    backgroundColor: theme.colors.background,
    paddingLeft: '24px',
    paddingBottom: 16,
    minWidth: 'calc(100% + 24px)',
  },
  value: {
    display: 'flex',
    zIndex: 2,
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.colors.background,
    }
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginRight: 10,
    paddingRight: 15
  },
  arrow: {
    position: 'absolute',
    right: 0,
    transition: 'transform 0.3s ease-out',
    transform: 'rotateX(0)',
    width: 10,
    height: 6,
    '&.open': {
      transform: 'rotateX(180deg)'
    }
  },
  line: {
    width: '100%',
    height: 1,
    background: 'black',
    marginTop: 14,
    marginBottom: 20,
    transformOrigin: '0 0',
    transform: 'scaleX(0)'
  },
  item: {
    padding: '6px 0px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.5
    },
    '&.selected': {
      opacity: 0.5
    }
  }
})

export default DropDown
