import { useRef, useCallback } from 'react'
import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import Link from './Link'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'

export default function CtaLink ({ link }) {
  const styles = useStyles()
  const ref = useRef()
  const localsRef = useRef({})
  
  const onMouseEnter = useCallback(() => {
    const el = ref.current

    if (localsRef.current.timeline) localsRef.current.timeline.kill()

    const tl = gsap.timeline()
    tl.fromTo(el, { scaleX: 1, transformOrigin: '100% 100%' }, { scaleX: 0, transformOrigin: '100% 0%', duration: 0.3, ease: 'power2.out', force3D: false })
    tl.fromTo(el, { scaleX: 0, transformOrigin: '0% 0%' }, { scaleX: 1, transformOrigin: '0% 0%', duration: 0.3, ease: 'power2.out', force3D: false })
    localsRef.current.timeline = tl
  }, [])

  const onMouseLeave = useCallback(() => {
    const el = ref.current

    if (localsRef.current.timeline) localsRef.current.timeline.kill()

    const tl = gsap.timeline()
    tl.to(el, { scaleX: 1, transformOrigin: '0% 0%', duration: 0.35, ease: 'power2.out', force3D: false })
    localsRef.current.timeline = tl
  }, [])

  const eventListeners = {
    onMouseLeave: primaryInput === 'touch' ? null : onMouseLeave,
    onMouseEnter: primaryInput === 'touch' ? null : onMouseEnter,
  }

  return (
    <span className={styles.wrap} {...eventListeners}>
      <Link className={styles.ctaLink} link={link} />
      <div className={styles.line} ref={ref}/>
    </span>
  )
}

const useStyles = createUseStyles({
  wrap: {
    position: 'relative',
    paddingBottom: 8,
  },
  ctaLink: {
    fontFamily: theme.fonts.mono,
    fontWeight: theme.fonts.monoFontWeight,
    textDecoration: 'none',
    position: 'relative',
    paddingBottom: 8,
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    fontSize: 12,
  },
  line: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    height: '1px',
    background: 'currentColor',
  }
})