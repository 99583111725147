import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import ResponsiveImage from '../ResponsiveImage'
import Link from '../Link'

export default function RelatedArticles({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.tile}`), {
      start: '30% bottom',
      onEnter: item => gsap.to(item, { 
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1, 
      })
    })
  }, []))


  const { title, automatic, articles } = data
  const renderedArticles = automatic ? data.relatedArticles : articles

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <h3 className={classes.title}>{title}</h3>
      {renderedArticles && renderedArticles.map(article => (
        <Link key={article._key} link={article} showText={false} className={classes.article}>
          <div className={classes.imageWrap}>
            <ResponsiveImage className={classes.image} image={article.featuredImage} aspect={688/761} speed='0.8' />
          </div>
          <h4 className={classes.articleTitle}>{article.title}</h4>
          <div className={classes.articleExcerpt}>{article.excerpt}</div>
        </Link>
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    gridColumn: '1 / -1',
    textTransform: 'uppercase',
    fontSize: 40,
    lineHeight: '0.9em',

    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    }
  },
  article: {
    gridColumn: 'span 6',
    textDecoration: 'none',
    // opacity: 0,
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 4',
    }
  },
  imageWrap: {
    overflow: 'hidden'
  },
  articleTitle: {
    textTransform: 'uppercase',
    marginTop: 24,
    marginBottom: 24,
    fontSize: 40,
    lineHeight: '1em',
    maxHeight: `${1 * 3}em`,
    '-webkit-line-clamp': 3,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
    // maxWidth: theme.span(3.5)
  },
  articleExcerpt: {
    maxWidth: theme.span(3),
    '-webkit-line-clamp': 4,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  }
})
