import { createGlobalStyles } from 'goober/global'
import { theme } from '../styles/theme'

export function generateGridCss(
  columns,
  gutter = '0px',
  offset = 0,
  leftOffset = 0
) {
  if (process.env.NODE_ENV === 'development') {
    const lineThickness = '1px'
    const color = 'rgba(255,0,0,0.1)'
    // const color = 'rgba(255,1,1,1.1)'

    const repeatingWidth = `calc(100% / ${columns})`
    const columnWidth = `calc((100% / ${columns}) - ${gutter})`
    const backgroundWidth = `calc(100% + ${gutter})`
    const backgroundColumns = `repeating-linear-gradient(
      to right,
      ${color},
      ${color} ${lineThickness},
      transparent ${lineThickness},
      transparent calc(${columnWidth} - ${lineThickness}),
      ${color} calc(${columnWidth} - ${lineThickness}),
      ${color} ${columnWidth},
      transparent ${columnWidth},
      transparent ${repeatingWidth}
    )`

    return {
      '&::before': {
        content: '""',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: leftOffset,
        margin: {
          right: 'auto',
          left: 'auto'
        },
        width: `calc(100% - ${offset}px - ${leftOffset}px)`,
        'min-height': '100vh',
        'background-image': backgroundColumns,
        'background-size': `${backgroundWidth} 100%`,
        'z-index': 10000,
        'pointer-events': 'none'
      }
    }
  } else {
    return ''
  }
}

const GlobalStyles = createGlobalStyles({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
  },
  html: {},
  ':root': {
    '--background': theme.colors.pageTheme.default.background,
    '--foreground': theme.colors.pageTheme.default.foreground,
    '--border': theme.colors.pageTheme.default.border
  },
  body: {
    padding: 0,
    margin: 0,
    backgroundColor: theme.colors.background,
    color: theme.colors.foreground,
    borderColor: theme.colors.border,
    width: '100%',
    '-webkitFontSmoothing': 'antialiased',
    '-mozOsxFontSmoothing': 'grayscale',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    lineHeight: 1.5,
    minHeight: '100vh',
    fontSize: 14,
    // ...generateGridCss(theme.grid.sm.columns, `${theme.grid.sm.gutter}px`, theme.grid.sm.margin, theme.grid.sm.margin),
    // [theme.breakpoints.up('md')]: {
    //   ...generateGridCss(theme.grid.md.columns, `${theme.grid.md.gutter}px`, theme.grid.md.margin, theme.grid.md.margin)
    // },
    [theme.breakpoints.up('xxl')]: {
      fontSize: 16
    }
  },
  'h1,h2,h3,h4,h5,h6,.h': {
    marginTop: 0,
    letterSpacing: 0,
    fontFamily: theme.fonts.heading,
    fontWeight: theme.fonts.headingFontWeight,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4)
    }
  },
  p: {
    marginTop: 0,
    marginBottom: theme.spacing(4)
  },
  'h1, .as-h1': {
    fontSize: 54,
    lineHeight: 1,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.vw(100)
    }
  },
  h2: {
    fontSize: 40,
    lineHeight: 1.15,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.vw(70)
    }
  },
  h3: {
    fontSize: 30,
    lineHeight: 0.9,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.vw(35)
    }
  },
  h4: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('xxl')]: {
      fontSize: 16
    }
  },
  h6: {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    lineHeight: 1.2,
    fontSize: 24,
    [theme.breakpoints.up('md')]: {
      fontSize: 28
    }
  },
  input: {
    fontFamily: theme.fonts.body,
    color: 'currentColor'
  },
  '.srOnly': {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  },
  button: {
    fontFamily: theme.fonts.body,
    color: 'currentColor',
    cursor: 'pointer'
  },
  a: {
    textDecoration: 'underline',
    color: 'currentColor',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  '.button-link': {
    textDecoration: 'none !important',
    '&:hover': {
      color: 'rgba(255,255,255,0.7) !important'
    },
    '& svg': {
      display: 'none'
    }
  },
  '.clear-fix:after': {
    content: '""',
    clear: 'both',
    display: 'table'
  },
  '.scrollbar-track': {
    zIndex: `${theme.zIndex.scrollbar} !important`
  },
  '::selection': {
    background: 'black',
    color: 'white'
  },
  '::-moz-selection': {
    background: 'black',
    color: 'white'
  },
  '.reset-button': {
    backgroundColor: 'transparent',
    borderWidth: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    padding: 0,
    textAlign: 'left'
  },
  '.cursor-wait, .cursor-wait *': {
    cursor: 'wait !important'
  }
})

export default function Global() {
  return <GlobalStyles />
}
