import { useEffect } from 'react'
import { scrollReadyAtom } from '../components/PageComponent'
import { useAtom } from 'jotai'

export const useScrollReadyListener = (callback) => {
  const [scrollReady] = useAtom(scrollReadyAtom)

  useEffect(() => {
    if (!scrollReady) return
    callback()
  }, [scrollReady])
}