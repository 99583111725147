import cn from 'classnames'
import PropTypes from 'prop-types'
import { useMemo, useRef, useCallback, useEffect, useState } from 'react'
import { useIsBreakpoint } from '../../../hooks/useIsBreakpoint'
import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import Section from '../../Section'
import { productPropType } from './ProductListing.types'
import { ProductListingCopy } from './ProductListingCopy'
import { ProductListingHero } from './ProductListingHero/ProductListingHero'
import { ProductListingItem } from './ProductListingItem'
import reduce from 'lodash/reduce'

import gsap from 'gsap'
import { useScrollReadyListener } from '../../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useProductCustomerGroupFiltering } from './productHooks'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'

export const ProductListing = ({ data, page }) => {
  const { copyList = [], intro, siblings } = data
  const styles = useStyles()
  const sectionRef = useRef()
  const isDesktop = useIsBreakpoint(theme.breakpoints.up('md'), true)
  const products = useProductCustomerGroupFiltering(data.products || [])
  const [isAnimated, setAnimated] = useState(false)

  const tiles = useMemo(() => {
    const copyListClone = [...copyList]
    const COPY_START = isDesktop ? 6 : 4
    const COPY_INTERVAL = isDesktop ? 6 : 5
    return reduce(products, (result, product) => {
      const addText =
        [COPY_START, 0].includes(result.length % COPY_INTERVAL) &&
        result.length >= COPY_START
      return [
        ...result,
        addText ? copyListClone.shift() : null,
        product
      ].filter(Boolean)
    }, [])
  }, [products, copyList, isDesktop])

  useScrollReadyListener(useCallback(() => {
    setTimeout(() => {
      // setAnimated(true)
      ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${styles.item}`), {
        start: '10% bottom',
        onEnter: item => gsap.to(item, {
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: 'power2.inOut',
          stagger: 0.1
        })
      })
    }, 500)
  }, []))

  useEffect(() => {
    // We refresh the scroll when the products, products can change when a user logs into the store
    ScrollSmoother.refresh()
  }, [products])

  return (
    <>
      <ProductListingHero intro={intro} siblings={siblings} page={page} />
      <Section grid className={styles.list} ref={sectionRef}>
        {tiles.map((item, index) =>
          typeof item === 'string'
            ? (
              <ProductListingCopy
                text={item}
                key={index}
                className={cn(styles.item, styles.copy, isAnimated && styles.animated)}
              />
              )
            : (
              <ProductListingItem
                product={item}
                key={item._id}
                className={cn(styles.item, styles.product, isAnimated && styles.animated)}
              />
              )
        )}
      </Section>
    </>
  )
}

const useStyles = createUseStyles({
  list: {
    [theme.breakpoints.up('md')]: {
      rowGap: `${theme.spacing(16)}px!important`
    }
  },
  item: {
    overflow: 'hidden',
    gridColumn: 'span 2',
    minHeight: 200,
    opacity: 0,
    transform: 'translateY(40px)',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 4',
      '&:nth-child(-n + 2)': {
        gridColumn: 'span 6'
      }
    }
  },
  copy: {
    gridColumn: 'span 4',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
    }
  },
  product: {}
})