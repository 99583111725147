import { useEffect, useRef, useState, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import cn from 'classnames'
import gsap from 'gsap'
import debounce from 'lodash/debounce'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import SplitText from 'gsap/dist/SplitText'
import SubmitMessage from './SubmitMessage'

export default function QuoteCarousel ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const quoteRef = useRef()
  const quoteSplits = useRef([])

  const [activeSlide, setActive] = useState(0)
  const [firstLoad, setFirstload] = useState(true)

  const debouncedSetActive = debounce(setActive, 200)

  const { quotes, leftTitle, rightTitle } = data

  useScrollReadyListener(useCallback(() => {
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      },
      scrollTrigger: {
        trigger: sectionRef.current,
        start: '30% bottom',
        once: true
      }
    })

    tl.from(sectionRef.current.querySelectorAll(`.${classes.line}`), {
      scaleX: 0,
      transformOrigin: '0 0'
    })
    tl.from(sectionRef.current.querySelectorAll(`.${classes.quoteButton}`), {
      opacity: 0
    }, '-=0.4')
  }, []))

  const [refCallback, slider, sliderNode] = useKeenSlider({
    slides: {
      perView: 5.4,
      origin: 'center'
    },
    loop: true,
    slideChanged (slider) {
      debouncedSetActive(slider.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          perView: 5.4,
          origin: 'center'
        },
        loop: true
      },
      '(max-width: 700px)': {
        slides: {
          perView: 3.4,
          origin: 'center'
        },
        loop: true
      }
    }
  })

  const jumpToSlide = (i) => {
    slider.current.moveToIdx(i)
  }

  useEffect(() => {
    const els = quoteRef.current.children
    Array.from(els).map((item, i) => {
      const splitItem = new SplitText(item, { type: 'words, lines', linesClass: 'split-parent' })
      quoteSplits.current.push(splitItem)
    })
    gsap.set(quoteRef.current, { opacity: 1 })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      gsap.to(sectionRef.current.querySelectorAll(`.${classes.svgPath}`), {
        strokeDashoffset: '104px',
        duration: 0,
        ease: 'expo.out',
        delay: 0
      })
      gsap.to(sectionRef.current.querySelectorAll(`.${classes.svgPath}.${classes.activeButton}`), {
        strokeDashoffset: '0px',
        duration: 0.4,
        ease: 'expo.out',
        delay: 0,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: '30% bottom'
        }
      })
    }, 200)

    quoteSplits.current.map((item, i) => {
      const isActive = i === activeSlide

      if (firstLoad && !isActive) {
        gsap.set(quoteRef.current, {
          height: quoteRef.current.children[activeSlide].getBoundingClientRect().height
        })
        gsap.set(item.elements, {
          userSelect: isActive ? 'auto' : 'none',
          pointerEvents: isActive ? 'all' : 'none'
        })
        gsap.set(item.words, {
          y: '-110%',
          opacity: 0
        })
        setFirstload(false)
        return
      }

      gsap.set(item.elements, {
        userSelect: isActive ? 'auto' : 'none',
        pointerEvents: isActive ? 'all' : 'none'
      })

      if (isActive) {
        gsap.fromTo(item.words, {
          y: '110%',
          opacity: 0,
        }, {
          y: 0,
          opacity: 1,
          duration: 0.6,
          stagger: 0.01,
          delay: 0.2,
          ease: 'power2.inOut',
          overwrite: true,
        })
      } else {
        gsap.to(item.words, {
          y: '-110%',
          opacity: 0,
          duration: 0.3,
          stagger: 0.01,
          ease: 'power2.inOut',
          overwrite: true,
          onComplete: () => {
            gsap.set(item.words, { y: '110%' })
          }
        })
      }
    })

    gsap.to(quoteRef.current, {
      height: quoteRef.current.children[activeSlide].getBoundingClientRect().height,
      duration: 0.6,
      ease: 'power2.inOut',
      delay: 0.1,
      onComplete: () => ScrollTrigger.refresh()
    })
  }, [activeSlide])

  return (
    <Section tag='section' id='message' className={classes.section} grid fullWidth ref={sectionRef}>
      <header className={classes.header}>
        <span>{leftTitle}</span>
        <span>{rightTitle}</span>
        <div className={classes.line} />
      </header>
      <div ref={refCallback} className={cn('keen-slider', classes.slider)}>
        {quotes && quotes.map((quote, i) => (
          <button key={data._key + i + quote._ref} className={cn(classes.quoteButton, 'reset-button', 'keen-slider__slide', i === activeSlide && classes.activeButton)} onClick={() => jumpToSlide(i)}>
            <div className={classes.dot} />
            <span>
              m// 00{i + 1}<br />
              {quote.name}.
            </span>
            <div className={cn(classes.svgContainer, i === activeSlide && classes.activeButton)}>
              <svg width='101' height='8' viewBox='0 0 104 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <defs>
                  <pattern id='pen-stroke' patternUnits='userSpaceOnUse' width='100%' height='100%'>
                    <image className={classes.svgImage} href='/quote-animation.svg' />
                  </pattern>
                </defs>
                <path className={cn(classes.svgPath, i === activeSlide && classes.activeButton)} d='M1 5.49999C4.16667 5.66666 11.3778 5.49999 15 5.49999C28 5.49999 34 2 43 1.99999C59.5 1.99998 61 3.5 71 4C79 4.4 96.1667 4.83333 103 5' fill='none' stroke='url(#pen-stroke)' strokeWidth='4' />
              </svg>
            </div>
          </button>
        ))}
      </div>
      <blockquote className={classes.quote} ref={quoteRef} style={{ opacity: 0 }}>
        {quotes && quotes.map((quote) => <div className={classes.quoteInner} key={quote.message || quote.quote}>
          {quote.message || quote.quote}
        </div>)}
      </blockquote>
      <SubmitMessage data={data} />
    </Section>
  )
}

const useStyles = createUseStyles({
  header: {
    gridColumn: '1 / -1',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: 22,
    marginLeft: theme.grid.md.margin,
    marginRight: theme.grid.md.margin,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.grid.sm.margin,
      marginRight: theme.grid.sm.margin
    },
    '& span': {
      gridColumn: 'span 1',
      fontSize: 12,
      textTransform: 'uppercase'
    },
    '& span:nth-child(2)': {
      textAlign: 'right'
    }
  },
  line: {
    gridColumn: '1 / -1',
    height: 1,
    background: 'black'
  },
  quoteButton: {
    fontSize: 24,
    lineHeight: '1em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    '& span': {
      opacity: 1,
      transition: 'opacity 0.2s ease-out',
      display: 'block',
      maxWidth: '10em'
    },
    '&:hover span': {
      opacity: 0.5
    }
  },
  slider: {
    gridColumn: '1 / -1',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing'
    },
    '& > button:nth-child(even) > div:last-of-type': {
      top: 0,
      transform: 'scaleY(-1)',
      [theme.breakpoints.down('md')]: {
        top: -6
      }
    }
  },
  quote: {
    gridColumn: '2 / -2',
    textAlign: 'center',
    fontSize: 72,
    lineHeight: '1em',
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    textTransform: 'uppercase',
    letterSpacing: '-0.03em',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      fontSize: 24
    },
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1.1em',
      paddingTop: '0.1em'
    }
  },
  dot: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: '1px solid black',
    marginBottom: 24,
    background: 'transparent',
    transition: 'background 0.3s ease-out'
  },
  svgContainer: {
    position: 'relative',
    top: 0,
    opacity: 0,
    pointEvents: 'none',
    [theme.breakpoints.down('md')]: {
      top: -6
    }
  },
  svgPath: {
    strokeDasharray: '104px',
    strokeDashoffset: '104px',
    transitionDuration: '0s',
    transitionDelay: '0s'
  },
  activeButton: {
    '& > div:first-child': {
      background: 'black'
    },
    '& > div:last-of-type': {
      opacity: 1
    },
    '&:hover span': {
      opacity: '1 !important'
    }
  },
  quoteInner: {
    position: 'absolute',
    width: '100%'
  }
})
