import cn from 'classnames'
import PropTypes from 'prop-types'
import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'

export const ProductListingCopy = ({ text, className }) => {
  const styles = useStyles()
  return (
    <div className={cn(styles.root, className)}>
      <span className={cn('h', styles.text)}>{text}</span>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4)
  },
  text: {
    display: 'block',
    fontSize: '1.5rem',
    lineHeight: 1.1,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 0
  }
})

ProductListingCopy.propTypes = {
  text: PropTypes.string.isRequired
}
