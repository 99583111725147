import { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import Section from '../../Section'
import queryString from 'query-string'

import { useAtom } from 'jotai'
import compact from 'lodash/compact'
import range from 'lodash/range'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { headerFadeInAtom } from '../../Header/headerState'
import { keyframes } from 'goober'
import StoriesListingItem from './StoriesListingItem'
import DropDown from '../../DropDown'

const PAGE_LIMIT = 6

function SkeletonItem () {
  const classes = useStyles()

  return (
    <div className={classes.skeletonItem}>
      <div className={classes.skeletonImage} />
    </div>
  )
}

const createPlaceholders = () => map(range(PAGE_LIMIT), () => ({ placeholder: true }))

export default function StoriesListing ({ data, page }) {
  const classes = useStyles()
  const sectionRef = useRef(null)
  const titleRef = useRef(null)

  const [, setIsLoading] = useState(true)
  const [loadingAnimationComplete, setLoadingAnimation] = useState(false)
  const [apiData, setData] = useState(createPlaceholders())
  const [totalCount, setTotalCount] = useState(null)

  const router = useRouter()

  const localsRef = useRef({ query: null })

  const scroll = ScrollSmoother.get()

  useEffect(() => {
    // We need to use the asPath rather then the query, see https://github.com/vercel/next.js/discussions/11484
    // The query is not set on first load so we can never know when to use the query from router
    const query = queryString.parseUrl(router.asPath)?.query
    if (!isEqual(query, localsRef.current.query)) {
      // This is the first load when we do not have a a query in the locals
      const firstLoad = !localsRef.current.query
      localsRef.current.query = query
      const qs = {
        ...query,
        page: firstLoad ? 1 : (query.page || 1),
        limit: firstLoad ? PAGE_LIMIT * (query.page || 1) : PAGE_LIMIT
      }
      // setPage(1)
      setIsLoading(true)
      setLoadingAnimation(false)
      window.fetch(`/api/journal?${queryString.stringify(qs)}`)
        .then(response => response.json())
        .then(data => {
          setData(current => compact([
            ...filter(current, x => !x.placeholder),
            ...data.data.data
          ]))
          setTotalCount(data.data.totalCount)
          setIsLoading(false)
          setTimeout(() => {
            ScrollTrigger.refresh(true)
            ScrollSmoother.get().effects().forEach(t => t.kill())
            ScrollSmoother.get().effects(
              document.querySelectorAll('*[data-speed], *[data-lag]')
            )
          }, 0)
          setTimeout(() => {
            setLoadingAnimation(true)
          }, 320)
        })
    }
  }, [router.asPath])

  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (!headerFadeIn) return

    const titleSplit = new SplitText(titleRef.current, { type: 'words, lines', linesClass: 'split-parent' })
    const timeline = gsap.timeline({
      defaults: {
        ease: 'power2.out',
        duration: 0.6
      }
      // onComplete: () => {
      //   titleSplit.revert()
      // }
    })

    const ref = sectionRef.current

    timeline.set(titleRef.current, { opacity: 1 })
    timeline.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1
    })
    timeline.to(sectionRef.current.querySelectorAll(`.${classes.copy}, .${classes.articles}`), { opacity: 1, y: 0, stagger: 0.1 }, '-=0.5')
  }, [headerFadeIn])

  useEffect(() => {
    if (!loadingAnimationComplete) return
    gsap.to(sectionRef.current.querySelectorAll(`.${classes.article}`), { opacity: 1, y: 0, duration: 0.6, ease: 'power2.inOut' })
    gsap.to(sectionRef.current.querySelectorAll(`.${classes.noResultsWrap}`), { opacity: 1, y: 0, duration: 0.6, ease: 'power2.inOut' })
  }, [loadingAnimationComplete, apiData])

  const loadMore = () => {
    setData(data => [
      ...data,
      ...createPlaceholders()
    ])
    router.push({
      pathanme: router.pathname,
      query: {
        ...router.query,
        page: (router.query.page ? parseInt(router.query.page) : 1) + 1
      }
    }, null, { shallow: true })
  }

  const onCategoryChange = useCallback((e) => {
    if (router.query.category === e.target.value) return // Same query value, no need to change anything
    setData(createPlaceholders())
    router.push({
      pathanme: router.pathname,
      query: {
        ...router.query,
        page: 1,
        category: e.target.value
      }
    }, null, { shallow: true })
  }, [router.pathname, router.query])

  const { title, copy, articleCategories } = data

  const categories = [{ text: 'Show All', value: '' }, ...articleCategories.map(option => ({ text: option.name, value: option.slug.current }))]

  return (
    <Section tag='div' className={classes.section} ref={sectionRef}>
      <Section tag='header' grid fullWidth noBottomMargin className={classes.header}>
        <h1 className={classes.title} ref={titleRef}>{title}</h1>
        <div className={classes.copy}>
          <div className={classes.copyWrap}>
            {copy}
          </div>

          <div className={classes.categorySelect}>
            <DropDown label='Filter' className={classes.select} options={categories} value={router.query.category || ''} onChange={onCategoryChange} />
          </div>
        </div>
      </Section>

      <Section grid fullWidth noBottomMargin className={classes.articles}>
        {apiData && apiData.map((item, i) => {
          return item.placeholder
            ? <SkeletonItem key={i} />
            : <StoriesListingItem key={item._id} reference={item} aspect={((i % 6) === 3) ? 1.525 : 0.75} className={classes.article} />
        })}
        {(apiData.length < totalCount) && (<div className={classes.buttonWrap}><button className={cn(classes.button, 'reset-button')} onClick={loadMore}>Load More</button></div>)}

        {!apiData.length &&
          <div className={classes.noResultsWrap}>
          <div className={classes.msg}>There's no articles matching your current search. Try changing the filters</div>
        </div>}

      </Section>
    </Section>
  )
}

const wipe = keyframes`
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(30%);
  }
`

const useStyles = createUseStyles({
  title: {
    gridColumn: '1 / -4',
    lineHeight: '1em',
    textTransform: 'uppercase',
    fontSize: 200,
    opacity: 0,
    '& .split-parent': {
      overflowY: 'hidden',
      height: '1.1em',
      marginBottom: '-0.25em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 150
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 62,
      gridColumn: '1 / -1 '
    }
  },
  header: {
    marginTop: 180,
    marginBottom: 154,
    gridColumn: '1 / -1 ',
    [theme.breakpoints.down('md')]: {
      marginTop: 64 - 32,
      marginBottom: 96
    }
  },
  copy: {
    gridColumn: 'span 3',
    maxWidth: '30em',
    opacity: 0,
    transform: 'translateY(20px)',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1 ',
      fontSize: 16
    },
    zIndex: 50
  },
  copyWrap: {
    // height: `${1.5 * 8}em`,
    overflow: 'hidden',
    '-webkit-line-clamp': 8,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical'
  },
  articles: {
    gridColumn: '1 / -1 ',
    opacity: 0,
    transform: 'translateY(20px)',
    '& > a:nth-child(6n+4), & > div:nth-child(6n+4)': {
      gridColumn: 'span 8 !important',
      [theme.breakpoints.down('md')]: {
        gridColumn: '1 / -1 !important'
      }
    },
    '& > div:nth-child(6n+4) div': {
      paddingBottom: '65.8% !important'
    }
  },
  skeletonItem: {
    gridColumn: 'span 4',
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: '50%',
      zIndex: 1,
      width: '500%',
      marginLeft: '-250%',
      animation: `${wipe} 1.2s linear infinite`,
      background: 'linear-gradient(100deg, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%'
    }
  },
  skeletonImage: {
    paddingBottom: '133%',
    width: '100%',
    background: 'var(--foreground)',
    opacity: 0.15
  },
  article: {
    gridColumn: 'span 4'
  },
  buttonWrap: {
    gridColumn: '1 / -1',
    marginTop: 151,
    marginBottom: 100
  },
  button: {
    display: 'block',
    margin: 'auto',
    minWidth: 216,
    textAlign: 'center',
    padding: '16px 32px 14px',
    borderRadius: 41,
    border: '1px solid var(--foreground)',
    textDecoration: 'none',
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    transition: 'background 0.1s cubic-bezier(0.1, 0, 0, 1), border 0.1s cubic-bezier(0.1, 0, 0, 1), color 0.1s cubic-bezier(0.1, 0, 0, 1)',
    '&:hover': {
      background: 'var(--foreground)',
      color: 'var(--background)'
    }
  },
  categorySelect: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 14,
    marginTop: 53,
  },
  selectLabel: {
    opacity: 0.5
  },
  select: {
    flex: 1,
    // maxWidth: theme.span(6, 'md')
    // marginRight: 48
  }
})
