import PropTypes from 'prop-types'

export const teamPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  shortBio: PropTypes.string,
  bio: PropTypes.string,
  headshot: PropTypes.object,
  links: PropTypes.arrayOf(PropTypes.object)
})
