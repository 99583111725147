import { useEffect } from 'react'

const useScrollPosition = (handler) => {
  useEffect(() => {
    const updatePosition = () => {
      handler({ y: window.pageYOffset, x: window.pageXOffset })
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [handler])
}

export default useScrollPosition
