import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import CtaLink from '../CtaLink'
import SplitText from 'gsap/dist/SplitText'

import gsap from 'gsap'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

export default function ProductImpactSlice({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()

  const { blocks, title, link } = data

  useScrollReadyListener(
    useCallback(() => {
      const titleSplit = new SplitText(titleRef.current, {
        type: 'words, lines',
        linesClass: 'split-parent'
      })
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: '30% bottom',
          once: true
        },
        onComplete: () => {
          ScrollTrigger.batch(
            sectionRef.current.querySelectorAll(`.${classes.line}`),
            {
              start: '30% bottom',
              onEnter: item =>
                gsap.to(item, {
                  scaleX: 1,
                  duration: 0.6,
                  ease: 'power2.inOut',
                  stagger: 0.1
                })
            }
          )
          titleSplit.revert()
        }
      })

      tl.from(titleSplit.words, {
        y: '110%',
        opacity: 0,
        stagger: 0.02,
        ease: 'expo.out',
        delay: 0.1
      })
      tl.to(
        sectionRef.current.querySelectorAll(`.${classes.link}`),
        {
          y: 0,
          opacity: 1,
          duration: 0.6,
          ease: 'power2.inOut'
        },
        '-=0.4'
      )
    }, [])
  )

  return (
    <Section tag="section" className={classes.section} grid ref={sectionRef}>
      <h2 className={classes.title} ref={titleRef}>
        {title}
      </h2>
      <div className={classes.blocks}>
        {data &&
          blocks.map(block => (
            <div className={classes.block} key={block._key}>
              <div className={classes.line} />
              <h3>{block.title}</h3>
              <div className={classes.blockCopy}>{block.copy}</div>
            </div>
          ))}
      </div>
      {link && (
        <div className={classes.link}>
          <CtaLink link={link} />
        </div>
      )}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    [theme.breakpoints.up('md')]: {
      marginBottom: '172px !important'
    }
  },
  title: {
    gridColumn: '7 / -2',
    lineHeight: '0.9em',
    textTransform: 'uppercase',
    marginTop: 128 - theme.section.md.marginBottom,
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      fontSize: 40,
      marginTop: 103,
      marginBottom: 75 - 24 - 5
    },
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1em',
      paddingTop: '0.1em'
    }
  },
  link: {
    gridColumn: '7 / -1',
    marginBottom: 64,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
    },
    transform: 'translateY(20px)',
    opacity: 0
  },
  blocks: {
    gridColumn: '7 / -1',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.grid.md.gutter,
    marginTop: 64,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      gridTemplateColumns: '1fr',
      marginTop: 75 - 24
    }
  },
  block: {
    marginBottom: 64,
    fontSize: 16,
    letterSpacing: '0.01em',
    '& h3': {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      marginBottom: 16
    }
  },
  line: {
    width: '100%',
    height: 1,
    background: 'black',
    opacity: 0.1,
    marginBottom: 24,
    transformOrigin: '0 0',
    transform: 'scaleX(0)'
  },
  blockCopy: {
    lineHeight: '130%',
    maxWidth: '27em'
  }
})
