import React, { useCallback, useRef } from 'react'
import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import gsap from 'gsap'
import { useScrollReadyListener } from '../hooks/useScrollReady'
import SplitText from 'gsap/dist/SplitText'
import cn from 'classnames'

const Quote = ({ quote, author, marginBottom }) => {
  const classes = useStyles()
  const quoteRef = useRef()
  const marginBottomSizeSmall = marginBottom === 'sm'
  useScrollReadyListener(useCallback(() => {
    const quoteSplit = new SplitText(quoteRef.current, { type: 'words,lines', linesClass: 'split-parent' })
    gsap.from(quoteSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.02,
      ease: 'expo.out',
      delay: 0.1,
      scrollTrigger: {
        trigger: quoteRef.current,
        start: '30% bottom',
        once: true
      },
      onComplete: () => quoteSplit.revert()
    })
  }, []))

  return (
    <>
      <blockquote className={cn(classes.quote, marginBottomSizeSmall && 'bottom-margin-sm')} ref={quoteRef}>
        <span className={classes.markBefore}>“</span>{quote}<span className={classes.markAfter}>”</span>
      </blockquote>
      {author && <figcaption className={classes.cite}><cite>— {author}</cite></figcaption>}
    </>
  )
}

const useStyles = createUseStyles({
  quote: {
    margin: 0,
    fontSize: 56,
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    lineHeight: '0.9em',
    textTransform: 'uppercase',
    marginTop: 113,
    marginBottom: 152,
    marginLeft: theme.span(-2.4),
    marginRight: theme.span(1.5),
    display: 'inline-block',
    '&.bottom-margin-sm': {
      marginBottom: 60
    },
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1em',
      paddingTop: '0.1em',
      paddingLeft: '1em',
      marginLeft: '-1em'
    },

    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 56,
      marginBottom: 56,
      fontSize: 24,
    },

    // '&::before': {
    //   content: '"“"',
    //   marginLeft: '-0.7em',
    //   marginRight: '0.1em',
    //   [theme.breakpoints.down('md')]: {
    //     marginLeft: 0,
    //   },
    // },
  },
  markBefore: {
    marginLeft: '-0.7em',
    marginRight: '0.1em',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    }
  },
  markAfter: {
    display: 'inline',
  },
  cite: {
    marginLeft: theme.span(-2.4),
    marginRight: theme.span(1.5),
    marginTop: -152 + 45,
    marginBottom: 152,
    '& cite': {
      fontStyle: 'normal'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: -30,
      marginBottom: 56,
    },
  }
})

export default Quote