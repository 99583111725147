import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import RoundButton from '../../RoundButton'
import cn from 'classnames'

export default function SubmitButton ({ loading, ...props }) {
  const classes = useStyles()
  return (
    <RoundButton as='button' {...props} dark className={cn(classes.button, 'reset-button')} showLoading={loading} showArrow={!loading} />
  )
}

const useStyles = createUseStyles({
  button: {
    minHeight: 60,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 700,
    marginBottom: theme.spacing(2)
  }
})
