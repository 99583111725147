import * as breakpoints from './breakpoints'
import * as fonts from './fonts'

const colors = {
  background: 'var(--background)',
  foreground: 'var(--foreground)',
  border: 'var(--border)',
  black: '#000',
  white: '#fff',
  success: '#009506',
  error: '#EC9687',
  pageTheme: {
    default: {
      background: '#F7F7F7',
      foreground: '#000',
      border: '#000'
    }
  }
}

const spacing = (count = 1) => count * 8

const grid = {
  sm: {
    columns: 4,
    gutter: 16,
    margin: 16
  },
  md: {
    columns: 12,
    gutter: 20,
    margin: 24
  }
}

const section = {
  sm: {
    marginBottom: 40
  },
  md: {
    marginBottom: 80
  }
}

export const vw = (size, bp = 1440) => {
  return `${(size / bp) * 100}vw`
}

export const span = (cols, breakpoint = 'sm', padding = 0, containerSize = '100%', wrap = true) => {
  if (!grid[breakpoint]) return null
  const gridGutter = grid[breakpoint].gutter
  const gridColumns = grid[breakpoint].columns
  const calc = `(((${containerSize} - ${gridGutter * (gridColumns - 1)}px - ${padding}px) / ${gridColumns}) * ${cols}) + ${gridGutter * (Math.abs(cols) - 1)}px`
  return wrap ? `calc(${calc})` : calc
}

export const theme = {
  zIndex: {
    header: 1000,
    scrollbar: 1010
  },
  fonts,
  breakpoints,
  colors,
  spacing,
  grid,
  section,
  vw,
  span
}
