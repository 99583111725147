import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { useAtom } from 'jotai'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import { headerFadeInAtom } from '../Header/headerState'
import cn from 'classnames'
import RoundButton from '../RoundButton'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

const Career = ({ career }) => {
  const classes = useStyles()
  const [isOpen, setOpen] = useState(false)
  const detailsRef = useRef()

  useEffect(() => {
    gsap.to(detailsRef.current, {
      height: isOpen ? 'auto' : 0,
      duration: 0.3,
      ease: 'power2.inOut',
      onComplete: () => ScrollTrigger.refresh()
    })
  }, [isOpen])

  return (
    <div className={cn(classes.career)}>
      <button className={cn('reset-button', classes.careerButton)} onClick={() => setOpen(!isOpen)}>
        <h3 className={classes.careerTitle}>{career.title}</h3>
        <div className={cn(classes.locationMeta, classes.desktopMeta)}>{career.location}</div>
        <div className={cn(classes.roleMeta, classes.desktopMeta)}>{career.jobType}</div>
        <div className={cn(classes.chevron, isOpen && classes.chevronOpen)}>
          <svg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1L9 9L17 1' stroke='black' strokeWidth='2' />
          </svg>
        </div>
      </button>
      <div className={cn(classes.details)} ref={detailsRef}>
        <div className={classes.mobileMeta}>
          <div className={cn(classes.locationMeta)}>{career.location}</div>
          <div className={cn(classes.roleMeta)}>{career.jobType}</div>
        </div>
        <div>{career.excerpt}</div>
        <RoundButton link={career} dark bigPadding showArrow={false} showText={false}>Read More</RoundButton>
      </div>
      <div className={cn(classes.line, 'line')} />
    </div>
  )
}

export default function CareerListing ({ data, page }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()

  const { careers } = data

  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (!headerFadeIn) return

    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.inOut',
        duration: 0.6
      }
    })

    tl.set(sectionRef.current, {
      opacity: 1
    })
    tl.to(sectionRef.current.children, {
      opacity: 1,
      y: 0,
      stagger: 0.1,
      delay: 0.5
    })
    tl.to(sectionRef.current.querySelectorAll('.line'), {
      scaleX: 1,
      stagger: 0.1,
      delay: 1
    }, 0)
  }, [headerFadeIn])

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <div className={cn(classes.meta, classes.positionMeta)}>Position</div>
      <div className={cn(classes.meta, classes.locationMeta)}>Location</div>
      <div className={cn(classes.meta, classes.roleMeta)}>Role</div>
      <div className={cn(classes.line, 'line')} />
      {careers.map(career => <Career key={career._id} career={career} />)}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    opacity: 0,
    '& > *': {
      opacity: 0,
      transform: 'translateY(20px)'
    }
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '0.05em',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  line: {
    gridColumn: '1 / -1',
    height: 1,
    background: 'var(--foreground)',
    transform: 'scaleX(0)',
    transformOrigin: '0 0'
  },
  positionMeta: {
    gridColumn: 'span 6'
  },
  locationMeta: {
    gridColumn: 'span 2'
  },
  roleMeta: {
    gridColumn: 'span 4'
  },
  career: {
    gridColumn: '1 / -1'
  },
  careerButton: {
    width: '100%',
    gridColumn: '1 / -1',
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: `repeat(${theme.grid.sm.columns}, minmax(0px, 1fr))`,
    gridColumnGap: `${theme.grid.sm.gutter}px`,
    paddingBottom: 12,
    [theme.breakpoints.up('md')]: {
      gridColumnGap: `${theme.grid.md.gutter}px`,
      gridRowGap: 0,
      gridTemplateColumns: `repeat(${theme.grid.md.columns}, minmax(0px, 1fr))`
    },
    position: 'relative'
  },
  careerTitle: {
    font: 'inherit',
    fontSize: 24,
    gridColumn: 'span 6',
    margin: 0
  },
  details: {
    width: theme.span(4, 'md'),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    overflow: 'hidden',
    height: 0,
    willChange: 'height',
    '& > div': {
      paddingTop: 34,
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        paddingTop: 0
      }
    },
    '& > a': {
      marginBottom: 24
    }
    // paddingBottom: 16
  },
  chevron: {
    position: 'absolute',
    right: 0,
    top: 4,
    transform: 'rotateX(0deg)',
    transition: 'transform 0.3s cubic-bezier(0.2, 0, 0, 1)'
  },
  chevronOpen: {
    transform: 'rotateX(180deg) !important'
  },
  desktopMeta: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  mobileMeta: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      '& > *': {
        flex: 1
      }
    }
  }
})
