import { useRef, useEffect, useState } from 'react'
import createUseStyles from '../lib/createUseStyles'
import gsap from 'gsap'
import cn from 'classnames'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { theme } from '../styles/theme'

function NewlineText ({ text }) {
  return text.split('\n').map((str, i) => <p key={i}>{str}</p>)
}

export default function ViewMore ({ title, copy }) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const copyRef = useRef(null)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    gsap.to(copyRef.current, {
      height: isOpen ? 'auto' : 0,
      y: isOpen ? 8 : 0,
      duration: 0.25,
      ease: 'power3.inOut',
      onComplete: () => {
        const scroller = ScrollSmoother.get()
        if (scroller) {
          scroller.refresh()
          ScrollTrigger.refresh()
        }
      }
    })
  }, [isOpen])

  return (
    <div className={classes.root}>
      <div className={classes.copy} ref={copyRef}>
        <div className={classes.wrapper}>
          <NewlineText text={copy} />
        </div>
      </div>
      <button
        className={cn(classes.titleButton, 'reset-button')}
        onClick={toggle}
      >
        {isOpen ? 'Show Less' : title}
      </button>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    marginTop: -24,
    marginBottom: 32
  },
  titleButton: {
    fontSize: 8,
    textTransform: 'uppercase',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: 17,
    padding: '4px 8px 1px',
    display: 'block',
    marginBottom: 0,
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      backgroundColor: theme.colors.black,
      color: theme.colors.white
    }
  },
  copy: {
    height: 0,
    overflow: 'hidden',
    '& p:last-child': {
      marginBottom: 0
    }
  },
  wrapper: {
    paddingBottom: 16
  }
})
