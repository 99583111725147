import PropTypes from 'prop-types'

const slugPropType = PropTypes.shape({
  current: PropTypes.string.isRequired
})

export const productPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  slug: slugPropType.isRequired,
  bigCommerceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  product: PropTypes.object
})
