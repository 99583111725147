import { useRef, useCallback } from 'react'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import createUseStyles from '../../../lib/createUseStyles'
import cn from 'classnames'
import { theme } from '../../../styles/theme'
import dayjs from 'dayjs'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import color from 'color'
import { isMobileAtom } from '../../../state/layout'
import { useAtom } from 'jotai'

const StoriesListingItem = ({ reference, className, aspect }) => {
  const classes = useStyles()
  const itemRef = useRef()
  const localsRef = useRef({})
  const [isMobile] = useAtom(isMobileAtom)

  const { title, author, publishDate, featuredImage, bgColor, excerpt, excerptAuthor } = reference

  const onMouseEnter = useCallback(() => {
    if (localsRef.current.timeline) localsRef.current.timeline.kill()
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'expo.out',
        stagger: 0.1
      }
    })

    tl.to(itemRef.current.querySelectorAll(`.${classes.hover}`), {
      opacity: 1,
      duration: 0.6
    })

    tl.to(itemRef.current.querySelectorAll(`.${classes.imageWrap} > div`), {
      scale: 1.05
    }, 0)

    tl.fromTo(itemRef.current.querySelectorAll(`.${classes.meta} div`), {
      y: -20, opacity: 0
    }, {
      y: 0, opacity: 1
    }, 0)

    tl.fromTo(itemRef.current.querySelectorAll(`.${classes.title}`), {
      y: 40, opacity: 0
    }, {
      y: 0, opacity: 1
    }, 0)

    localsRef.current.timeline = tl
  }, [])

  const onMouseLeave = useCallback(() => {
    if (localsRef.current.timeline) localsRef.current.timeline.kill()

    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'expo.out',
        stagger: 0.1
      }
    })
    tl.to(itemRef.current.querySelectorAll(`.${classes.hover}`), {
      opacity: 0,
      duration: 0.3
    }, 0)
    tl.to(itemRef.current.querySelectorAll(`.${classes.imageWrap} > div`), {
      scale: 1
    }, 0)
    localsRef.current.timeline = tl
  }, [])

  const eventListeners = {
    onMouseEnter: (primaryInput === 'touch') ? null : onMouseEnter,
    onMouseLeave: (primaryInput === 'touch') ? null : onMouseLeave
  }

  const hasQuote = excerpt && excerptAuthor

  const bg = (bgColor && bgColor !== '#') ? bgColor : color(featuredImage?.asset?.metadata?.palette?.vibrant?.background || '#000').darken(0.3).saturate(0.6)

  return (
    <Link link={reference} showText={false} className={cn(className, classes.item)} ref={itemRef} {...eventListeners}>
      <div className={classes.imageWrap}>
        <ResponsiveImage image={featuredImage} aspect={aspect} mobileAspect={0.75} speed='0.9' />
      </div>
      {!isMobile && <div className={classes.hover} style={{ backgroundColor: bg }}>
        <div className={classes.meta}>
          <div>Written by {author}</div>
          <div>{dayjs(publishDate).format('DD MMMM, YYYY')}</div>
        </div>
        <h2 className={classes.title}>{hasQuote ? <>{excerpt} <div className={classes.quoteAuthor}>— {excerptAuthor}</div></> : title}</h2>
      </div>}
      {isMobile && <h2 className={cn(classes.title, classes.mobileTittle)}>{hasQuote ? <>{excerpt} <div className={classes.quoteAuthor}>— {excerptAuthor}</div></> : title}</h2>}
    </Link>
  )
}

const useStyles = createUseStyles({
  item: {
    position: 'relative',
    textDecoration: 'none !important'
  },
  imageWrap: {
    overflow: 'hidden'
  },
  hover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: 'white',
    opacity: 0,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '0.05em',
    display: 'flex',
    justifyContent: 'space-between'

  },
  title: {
    fontSize: 40,
    lineHeight: '0.9em',
    paddingTop: '0.1em',
    maxHeight: '7.4em',
    textTransform: 'uppercase',
    margin: 0,
    maxWidth: '18em',
    '-webkit-line-clamp': 7,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  mobileTittle: {
    marginTop: 16,
    textDecoration: 'none !important',
    fontSize: 24,
    marginBottom: 16
  },
  quoteAuthor: {
    marginTop: theme.section.sm.marginBottom,
    fontSize: 24,
    lineHeight: 0.92,
    [theme.breakpoints.down('md')]: {
      marginTop: 16
    }
  }
})

export default StoriesListingItem
