import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
// import gsap from 'gsap'
import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import RichContent from '../RichContent'
import { theme } from '../../styles/theme'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'

export default function ImpactSlice({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()

  useScrollReadyListener(
    useCallback(() => {
      ScrollTrigger.batch(
        sectionRef.current.querySelectorAll(`.${classes.image}`),
        {
          start: '30% bottom',
          onEnter: item =>
            gsap.to(item, {
              opacity: 1,
              scale: 1,
              duration: 0.6,
              ease: 'power2.inOut',
              stagger: 0.1
            })
        }
      )
    }, [])
  )

  return (
    <Section tag="section" className={classes.section} grid ref={sectionRef}>
      <div className={classes.copy}>
        <RichContent content={data.copy} />
      </div>

      <div className={classes.images}>
        {data.images &&
          data.images.map(image => (
            <div key={data._key + image._key} className={classes.imageWrap}>
              <ResponsiveImage
                className={classes.image}
                image={image}
                aspect={453 / 670}
                mobileAspect={342 / 404}
                speed="0.9"
              />
            </div>
          ))}
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  copy: {
    h3: {
      fontSize: '1.5rem'
    },
    gridColumn: '1 / span 3',
    maxWidth: '25em',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/ -1',
      gridRow: '2',
      fontSize: 24
    }
  },
  images: {
    gridColumn: '5 / -1',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.grid.md.gutter,
    '& > div': {
      overflow: 'hidden'
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/ -1',
      gridRow: '1',
      // display: 'flex',
      // flexDirection: 'column',
      gap: theme.grid.sm.gutter,

      marginBottom: 10
    }
  },
  image: {
    opacity: 0,
    transform: 'scale(1.05)'
  }
})
