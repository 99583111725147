import { useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import SplitText from 'gsap/dist/SplitText'
import gsap from 'gsap'
import { useAtom } from 'jotai'
import { headerFadeInAtom } from '../Header/headerState'
import compact from 'lodash/compact'
import cn from 'classnames'

export default function RichTextSlice({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()
  const leftCopyRef = useRef()
  const rightCopyRef = useRef()
  const { title, copy, copyLeft, width } = data
  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (headerFadeIn) {
      const tl = gsap.timeline({
        defaults: {
          duration: 0.6,
          ease: 'power2.inOut'
        }
      })

      if (title) {
        const titleSplit = new SplitText(titleRef.current, {
          type: 'words, lines',
          linesClass: 'split-parent'
        })

        tl.set(titleRef.current, {
          opacity: 1
        })
        tl.from(titleSplit.words, {
          y: '110%',
          opacity: 0,
          stagger: 0.1,
          ease: 'expo.out',
          delay: 0.1,
          onComplete: () => titleSplit.revert()
        })
      }

      tl.to(
        compact([leftCopyRef.current, rightCopyRef.current]),
        { opacity: 1, y: 0, stagger: 0.05 },
        0.2
      )

      return () => {
        tl.kill()
      }
    }
  }, [headerFadeIn])

  return (
    <Section tag="section" className={classes.section} grid ref={sectionRef}>
      {title && (
        <h1 className={classes.title} ref={titleRef}>
          {title}
        </h1>
      )}
      {copyLeft && (
        <RichContent
          content={copyLeft}
          className={classes.leftCopy}
          ref={leftCopyRef}
        />
      )}
      <RichContent
        content={copy}
        className={cn(classes.rightCopy, width)}
        ref={rightCopyRef}
      />
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    gridColumn: '1 /-1',
    textTransform: 'uppercase',
    marginTop: theme.spacing(11),
    marginBottom: theme.spacing(10),
    opacity: 0,
    lineHeight: '0.9em',
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(8)
    },
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1em',
      paddingTop: '0.1em'
    }
  },
  leftCopy: {
    transform: 'translateY(20px)',
    gridColumn: '1 / 6',
    marginBottom: 0,
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      gridColumn: '1 / -1'
    },
    '& h2, & h3, & h4': {
      textTransform: 'uppercase'
    }
  },
  rightCopy: {
    transform: 'translateY(20px)',
    opacity: 0,
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridColumn: '7 / -2'
    },
    '&.four': {
      [theme.breakpoints.up('md')]: {
        gridColumn: '7 / span 4'
      }
    },
    '&.five': {
      [theme.breakpoints.up('md')]: {
        gridColumn: '7 / span 5'
      }
    }
  }
})
