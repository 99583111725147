import { useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { useAtom } from 'jotai'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import cn from 'classnames'
import Section from '../Section'
import { headerFadeInAtom } from '../Header/headerState'
import ResponsiveImage from '../ResponsiveImage'
import ShopPill from '../ShopPill'
import { keyframes } from 'goober'
import Link from '../Link'
import { theme } from '../../styles/theme'
import ChangeTheCourse from '../../icons/ChangeTheCourse.svg.js'
import VimeoPlayer from '../VimeoPlayer'

export default function HomeHero ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()
  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (!headerFadeIn) return
    const titleSplit = new SplitText(titleRef.current, { type: 'words, lines', linesClass: 'split-parent' })
    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.inOut',
        duration: 0.8
      }
    })
    tl.set(titleRef.current, { opacity: 1 })
    // tl.set(sectionRef.current.querySelector('.vimeo-player'), { opacity: 1 })
    tl.set(sectionRef.current.querySelector(`.${classes.imageWrap}`), { opacity: 1 })
    tl.set(sectionRef.current.querySelector(`.${classes.bookImageWrap}`), { opacity: 1 })

    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.2,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => titleSplit.revert()
    })

    if (isImage) {
      tl.from(sectionRef.current.querySelector(`.${classes.imageWrap} > div:last-child`), {
        scale: 1.1,
        opacity: 0
      }, '-=0.6')
    }

    if (isBook) {
      tl.from(sectionRef.current.querySelector(`.${classes.bookImageWrap} > div`), {
        y: 80,
        opacity: 0
      }, '-=0.8')
      tl.from(sectionRef.current.querySelector(`.${classes.bookSvg}`), {
        scale: 0.8,
        rotate: -45,
        opacity: 0
      }, '-=0.2')
    }

    if (isVideo) {
      tl.fromTo(sectionRef.current.querySelector('.vimeo-player'), {
        y: 80,
        opacity: 0
      }, {
        y: 0,
        opacity: 1
      }, '-=1')
    }
  }, [headerFadeIn])

  const isBook = data.layout === 'book'
  const isImage = data.layout === 'image'
  const isVideo = data.layout === 'video'

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef} noBottomMargin={isBook}>
      <h1 className={cn(classes.header, isBook && classes.bookHeader)} ref={titleRef}>
        {data.textRow1}<br/>
        {data.textRow2}
      </h1>
      {isImage && <div className={classes.imageWrap}>
        {data.showPill && <ShopPill
          title='home goods'
          colour={data.pillButton?.bgColor || data.image.asset?.metadata?.palette.dominant.background}
          hoverColour={data.pillButton?.fgColor || data.image.asset?.metadata?.palette.darkVibrant.background}
          textColor={data.pillButton?.textColor}
          hoverStyle={data.pillButton?.hoverStyle}
          products={data.pillButton?.products}
                          />}
        {data.image && <ResponsiveImage image={data.image} aspect={1391 / 755} speed='0.8' />}
      </div>}
      {isBook && <div className={classes.bookImageWrap}>
        <ResponsiveImage className={classes.bookImage} image={data.image} aspect={1391 / 755} background='transparent' showPreview={false} mobileAspect='1' />
        <Link link={data.link} className={classes.bookSvg} data-speed='0.8' showText={false}>
          <ChangeTheCourse />
          <span>BUY</span>
        </Link>
      </div>}

      {isVideo && <VimeoPlayer video={data.video} vimeoData={data.vimeoData} previewData={data.previewData} background style={{opacity: 0}}/>}
    </Section>
  )
}

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
`

const useStyles = createUseStyles({
  header: {
    marginTop: 90,
    textTransform: 'uppercase',
    width: '100%',
    gridColumn: '1 / -1',
    fontSize: 'calc((100vw - 48px) * 0.1695)',
    letterSpacing: '-0.05em',
    lineHeight: '0.85em',
    whiteSpace: 'nowrap',
    opacity: 0,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 'calc((100vw - 32px) * 0.168)',
    },
    // '& > div': {
    //   marginBottom: '-0.2em',
    //   paddingBottom: '0.15em'
    // },
    '& .split-parent': {
      overflow: 'hidden',
      marginTop: '-0.1em',
      height: '.95em',
      paddingTop: '0.1em',
      marginBottom: '0em'
    }
  },
  bookHeader: {
    textAlign: 'center'
  },
  imageWrap: {
    gridColumn: '1 / -1',
    overflow: 'hidden',
    opacity: 0,
    position: 'relative'
  },
  sticky: {
    position: 'absolute',
    background: '#744F4D',
    padding: '0 32px',
    zIndex: 200,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 303,
    height: 50,
    borderRadius: 41,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: '0.06em',
    lineHeight: '.9em',
    '& div:nth-child(2), & div:nth-child(3)': {
      marginTop: 4
    },
    '& div:nth-child(3)': {
      fontWeight: '700'
    }
  },
  dot: {
    width: 8,
    height: 8,
    background: 'white',
    borderRadius: '50%'
  },
  bookImageWrap: {
    gridColumn: '1 / -1',
    overflow: 'hidden',
    position: 'relative',
    opacity: 0
  },
  bookSvg: {
    position: 'absolute',
    top: '20%',
    left: '60.3%',
    textDecoration: 'none',
    [theme.breakpoints.up('xl')]: {
      left: '62%'
    },
    [theme.breakpoints.down('md')]: {
      left: '69%'
    },
    '& svg': {
      animation: `${rotate} 10s linear infinite`,
      [theme.breakpoints.down('md')]: {
        width: 112,
        height: 112
      }
    },
    '& span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontFamily: theme.fonts.headingFace,
      fontWeight: theme.fonts.headingFontWeight,
      fontSize: 30,
      [theme.breakpoints.down('md')]: {
        fontSize: 18
      }
    }
  }
})
