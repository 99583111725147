import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import { useCallback, useEffect, useRef } from 'react'
import { theme } from '../styles/theme'

gsap.registerPlugin(ScrollTrigger)

export default function useScrollTrigger (props = () => ({}), animation, cleanup) {
  const ref = useRef()
  const localsRef = useRef({})
  useEffect(() => {
    if (ref.current) {
      const tl = gsap.timeline()
      const scrollTrigger = ScrollTrigger.create({
        trigger: document.documentBody,
        start: 'top top',
        end: 'bottom top',
        scrub: 0.1,
        animation: tl,
        // scroller: scrollContext.current.scrollElements,
        ...props()
      })
      animation(tl, ref)
      localsRef.current.tl = tl
      localsRef.current.scrollTrigger = scrollTrigger
    }
  }, [])

  useEffect(() => {
    return () => {
      localsRef.current.tl?.kill()
      localsRef.current.scrollTrigger?.kill()
      if (cleanup) cleanup()
    }
  }, [])

  return ref
}

export function useScrollTriggerOnDesktop (props = () => ({}), animation, cleanup) {
  const tlRef = useRef({})
  const ref = useRef()
  useEffect(() => {
    ScrollTrigger.matchMedia({
      [`(min-width: ${theme.breakpoints.values.md}px)`]: () => {
        const tl = gsap.timeline()
        tlRef.current.tl = tl
        tlRef.current.trigger = ScrollTrigger.create({
          trigger: document.documentBody,
          start: 'top top',
          end: 'bottom top',
          scrub: 0.5,
          animation: tl,
          ...props()
        })
        animation(tl, ref)
        return () => {
          tl.kill()
        }
      }
    })
  }, [])

  useEffect(() => {
    return () => {
      if (tlRef.current.tl) {
        tlRef.current.tl.kill()
        tlRef.current.trigger.kill()
      }
      if (cleanup) cleanup()
    }
  }, [])

  return ref
}
