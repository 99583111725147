import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useCallback, useRef, useState } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import { navViewAtom, NAV_VIEWS } from './headerState'
import cn from 'classnames'
import Input from './Form/Input'
import SubmitButton from './Form/SubmitButton'
import { ChevronDown } from '../ChevronDown'
import useLogin from '@bigcommerce/storefront-data-hooks/use-login'
import Errors from './Form/Errors'
import gsap from 'gsap'
import { settingsAtom } from '../../state/content'

export default function SignInForm ({ title, links }) {
  const ref = useRef()
  const classes = useStyles()
  const [view, setView] = useAtom(navViewAtom)
  const login = useLogin()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState()
  const [message, setMessage] = useState()
  const { signUpLabel } = useAtomValue(settingsAtom)

  const onBack = useCallback((e) => {
    setView(NAV_VIEWS.Nav)
    e.preventDefault()
  }, [])

  const onSignUp = useCallback((e) => {
    setView(NAV_VIEWS.SignUp)
    e.preventDefault()
  }, [])

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    if (!loading) {
      const action = async () => {
        try {
          setMessage(null)
          setLoading(true)
          await login({
            email,
            password
          })
          setLoading(false)
          setView(NAV_VIEWS.Nav)
        } catch (e) {
          if (e.errors) {
            setMessage(e.errors[0].message)
          } else {
            console.error(e)
            setMessage(e.message)
          }
        } finally {
          setLoading(false)
        }
      }
      action()
    }
  }, [email, password, loading])

  useEffect(() => {
    const isOpen = (view === NAV_VIEWS.SignIn)
    gsap.to(ref.current.children, {
      y: isOpen ? 0 : 40,
      opacity: isOpen ? 1 : 0,
      duration: 0.5,
      stagger: isOpen ? 0.02 : 0,
      delay: isOpen ? 0.1 : 0,
      ease: 'expo.out'
    })
  }, [view])

  return (
    <form className={classes.form} onSubmit={onSubmit} ref={ref}>
      <button onClick={onBack} type='button' className={cn(classes.backButton, 'reset-button')}>Back to menu <ChevronDown className={classes.chevron} /></button>
      <h2 className={classes.title}>Sign In</h2>
      <Input name='username' placeholder='Email Address' required type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
      <Input name='password' placeholder='Password' required type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
      <Errors message={message} />
      <SubmitButton type='submit' loading={loading}>Sign In</SubmitButton>
      <p className={classes.signUpText}>Need an account? <button className={cn(classes.linkButton, 'reset-button')} onClick={onSignUp}>{signUpLabel}</button> or <button className={cn(classes.linkButton, 'reset-button')}><a href='https://shop.twogood.com.au/login.php?action=reset_password'>Forgot password?</a></button></p>
    </form>
  )
}

const useStyles = createUseStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: 'var(--foreground)'
  },
  title: {
    fontSize: 40,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 80
    }
  },
  signUpText: {
    fontSize: 12,
    fontWeight: 'normal',
    alignSelf: 'flex-end',
    marginBottom: 0
  },
  linkButton: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  backButton: {
    fontSize: 12,
    alignSelf: 'flex-end',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center'
  },
  chevron: {
    marginLeft: theme.spacing(1),
    transform: 'rotate(180deg)',
    width: 8
  }
})
