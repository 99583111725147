import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import Link from './Link'
import cn from 'classnames'

export default function CtaButtons({ data }) {
  const styles = useStyles()

  return (
    <div className={styles.wrapper}>
      {data.buttons.map((button, k) => {
        console.log(button, 'button')
        return (
          <Link
          key={k}
          className={cn(styles.button, 'button-link')}
          link={button}
          onClick={() => window.gtag_report_conversion(button?.url)}
        />
        )
      })}
    </div>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
    marginBottom: 40
  },
  button: {
    textAlign: 'center',
    background: 'var(--foreground)',
    color: 'rgba(255,255,255,1)',
    padding: '16px 32px',
    borderRadius: '50px',
    fontSize: 16,
    border: 0,
    display: 'block',
    width: 'fit-content',
    fontFamily: theme.fonts.body,
    fontWeight: 700,
    letterSpacing: '0.07em',
    transition: 'color 0.2s ease-out',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: 12
    },
    '&:hover': {
      color: 'rgba(255,255,255,0.7)'
    }
  }
})
