import * as React from 'react'

export const ArrowUp = props => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m12.716 6.869-.565.565-3.561-3.56v9.384h-.798V3.873L4.231 7.434l-.566-.565 4.526-4.526 4.525 4.526Z" />
  </svg>
)
