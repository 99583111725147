import { useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import cn from 'classnames'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import useCart from '@bigcommerce/storefront-data-hooks/cart/use-cart'

import { useAtom, atom } from 'jotai'
import { formatCurrency } from '../../helpers/format'
import LineItem from './LineItem'
import Link from '../Link'
import { Marquee } from '../Slices/MarqueeSlice'
import useWindowResize from '../../hooks/useWindowResize'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import { ArrowUp } from '../icons/ArrowUp'

export const cartOpenAtom = atom(false)

export default function Cart ({ settings }) {
  const classes = useStyles()
  const [isOpen] = useAtom(cartOpenAtom)

  const sectionRef = useRef()
  const bgRef = useRef()
  const quoteRef = useRef()
  const localsRef = useRef({})

  const cart = useCart()
  const { data, isEmpty } = cart

  const initSplitText = () => {
    if (localsRef.current.quoteSplit) localsRef.current.quoteSplit.revert()
    const quoteSplit = new SplitText(quoteRef.current, { type: 'words, lines', linesClass: 'split-parent' })
    localsRef.current.quoteSplit = quoteSplit
  }

  useEffect(() => initSplitText(), [])
  useWindowResize(useDebouncedCallback(() => initSplitText()), false, true)

  useEffect(() => {
    const quoteSplit = localsRef.current.quoteSplit

    gsap.set(sectionRef.current, {
      pointerEvents: isOpen ? 'all' : 'none'
    })

    gsap.to([sectionRef.current, bgRef.current], {
      clipPath: isOpen ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 100% 0%)',
      duration: 0.5,
      stagger: 0.1,
      ease: 'expo.out'
    })

    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      }
    })
    if (localsRef.current.tl) localsRef.current.tl.kill()

    if (isOpen) {
      tl.fromTo(quoteSplit.words, {
        y: '120%',
        opacity: 0
      }, {
        y: 0,
        opacity: 1,
        stagger: 0.02,
        ease: 'expo.out',
        delay: 0.3
      }, 0)
      tl.fromTo(sectionRef.current.querySelectorAll(`.${classes.meta},  .${classes.lineItem}, .${classes.subtotalRow}, .${classes.checkoutBtn}, .${classes.empty}, .${classes.giftMessage} > *`), {
        opacity: 0,
        y: -10
      }, {
        opacity: 1,
        y: 0,
        stagger: 0.05,
        delay: 0.15
      }, 0)
      tl.fromTo(sectionRef.current.querySelectorAll(`.${classes.line}`), {
        scaleX: 0,
        transformOrigin: '0 0'
      }, {
        scaleX: 1,
        stagger: 0.1,
        delay: 0.3
      }, 0)
    } else {
      tl.to(quoteSplit.words, {
        y: '120%',
        opacity: 0,
        stagger: 0.02,
        ease: 'expo.out'
      }, 0)
    }

    localsRef.current.tl = tl
  }, [isOpen])

  return (
    <Section tag='div' noBottomMargin className={cn(classes.cart)} fullWidth ref={sectionRef}>
      <Section tag='div' grid noBottomMargin className={classes.inner} fullWidth insetGutter ref={bgRef}>
        {!isEmpty && (
          <>
            <div className={cn(classes.meta, classes.metaCart)}>Cart</div>
            <div className={cn(classes.meta, classes.metaDetails)}>
              {/* Details */}
            </div>
            <div className={cn(classes.meta, classes.metaQuantity)}>Quantity</div>
            <div className={cn(classes.line, 'line')} />

            {data && !isEmpty && [...data.line_items.physical_items, ...data.line_items.digital_items].map(lineItem => (
              <LineItem key={lineItem.id} className={classes.lineItem} lineItem={lineItem} />
            ))}

            <div className={classes.giftMessage}>
              <div className={classes.underline}>
                <Link to={`/${settings?.cartGiftMessageLink?.page?.slug}`} className={classes.giftMessageText}>{settings.cartGiftMessageLink?.text}</Link>
                <ArrowUp className={classes.rotate} />
              </div>
            </div>

            <div className={classes.subtotals}>
              {settings.cartShippingLabel && <div className={classes.subtotalRow}>
                <span>Delivery</span>
                <span>{settings.cartShippingLabel}</span>
              </div>}
              <div className={classes.subtotalRow}>
                <span>Subtotal</span>
                <span>{data && formatCurrency(data.cart_amount)}</span>
              </div>

              <Link to='/checkout' className={cn('reset-button', classes.checkoutBtn)}>
                Checkout
              </Link>
            </div>
          </>
        )}
        {isEmpty && (
          <div className={classes.empty}>
            <p>{settings.cartEmptyText}</p>
            <Link link={settings.cartEmptyLink} className={classes.checkoutBtn} />

            <div className={classes.marquees}>
              <Marquee className={classes.marquee} text='cart' count={8} speed='4s' />
              <Marquee className={classes.marquee} text='empty' flip count={8} speed='4s' />
            </div>
          </div>
        )}
      </Section>
      <div className={cn(classes.bgQuoteWrap)} style={{ visibility: isEmpty ? 'hidden' : 'visible' }}>
        <div className={classes.bgQuote} ref={quoteRef}>{settings.cartQuote}</div>
      </div>

    </Section>
  )
}

const useStyles = createUseStyles({
  cart: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    minHeight: '100vh',
    overflow: 'auto',
    background: 'none',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    clipPath: 'inset(0% 0% 100% 0%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  bgQuoteWrap: {
    paddingTop: 80,
    paddingBottom: theme.grid.md.margin - 5,
    paddingLeft: theme.grid.md.margin
  },
  bgQuoteWrapHidden: {
    display: 'none'
  },
  bgQuote: {
    marginTop: 8,
    gridColumn: '1/ -1',
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    color: theme.colors.white,
    fontSize: 72,
    textTransform: 'uppercase',
    lineHeight: '1em',
    maxWidth: theme.span(9, 'md'),
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1em',
      paddingTop: '0.1em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 36,
      maxWidth: `calc(100% - ${theme.grid.md.margin * 2}px)`
    }
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '0.05em',
    color: 'rgba(255, 255, 255, 0.5)',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  metaCart: {
    gridColumn: 'span 3'
  },
  metaDetails: {
    gridColumn: 'span 4'
  },
  metaQuantity: {
    gridColumn: 'span 4'
  },
  inner: {
    // position: 'absolute',
    color: 'var(--foreground)',
    zIndex: 40,
    paddingTop: 150,
    paddingBottom: 19,
    height: 'auto',
    width: '100%',
    background: 'black',
    clipPath: 'inset(0% 0% 100% 0%)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
  },
  line: {
    gridColumn: '1 / -1',
    height: 1,
    background: 'var(--foreground)',
    opacity: 0.2
  },
  giftMessage: {
    gridColumn: '1 / 6',
    fontSize: 12,
    textTransform: 'uppercase',
    opacity: 0.5
  },
  giftMessageText: {
    cursor: 'pointer',
    borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
  },
  rotate: {
    position: 'relative',
    top: 4,
    transform: 'rotate(90deg)'
  },
  subtotals: {
    gridColumn: '8 / -1',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
    }
  },
  subtotalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    marginBottom: 12,
    letterSpacing: '0.05em',
    fontSize: 12,

    '& span:first-child': {
      opacity: 0.5,
      letterSpacing: '0.06em'
    }
  },
  checkoutBtn: {
    display: 'block',
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 700,
    padding: 24,
    paddingBottom: 22,
    borderRadius: 55,
    letterSpacing: '0.06em',
    border: '1px solid var(--foreground)',
    textAlign: 'center',
    marginTop: 51,
    transition: 'color 0.1s ease-out, background 0.1s ease-out',
    '&:hover': {
      color: 'var(--background)',
      background: 'var(--foreground)'
    }
  },
  lineItem: {},
  empty: {
    gridColumn: '1 / -1',
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 42,
    '& p': {
      fontSize: 28
    },
    '& a': {
      marginTop: 0,
      width: 'auto !important',
      padding: '24px 72px'
    }
  },
  marquees: {
    // alignSelf: 'flex-end',
    paddingTop: 32,
    marginTop: 'auto',
    // justifySelf: 'flex-end',
    marginBottom: -20
  },
  marquee: {
    fontSize: '88px !important',
    paddingTop: '8px !important',
    marginTop: '0 !important',
    lineHeight: '1em !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '72px !important'
    }
  }
})
