import { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import dayjs from 'dayjs'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import { useAtom } from 'jotai'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import ResponsiveImage from '../ResponsiveImage'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import { headerFadeInAtom } from '../Header/headerState'
import RoundButton from '../RoundButton'

export default function ArticleHero ({ data, page }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()

  const { title, useBigFont, image, imageCaption } = data
  const { author, publishDate, parent } = page

  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (!headerFadeIn) return

    const titleSplit = new SplitText(titleRef.current, { type: 'words, lines', linesClass: 'split-parent' })

    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.inOut',
        duration: 0.6
      }
    })

    tl.set(titleRef.current, {
      opacity: 1
    })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => titleSplit.revert()
    })

    tl.to(sectionRef.current.querySelectorAll(`.${classes.author}, .${classes.date}, .${classes.share}, .${classes.backBtn}`), {
      opacity: 1,
      stagger: 0.1
    }, '-=0.3')
    tl.to(sectionRef.current.querySelectorAll(`.${classes.image}`), {
      opacity: 1,
      scale: 1
    }, '-=0.6')
    if (window.innerWidth < theme.breakpoints.values.md) {
      tl.to(sectionRef.current.querySelector(`.${classes.imageCaption}`), {
        opacity: 1,
        stagger: 0.1
      }, '-=0.3')
    }
  }, [headerFadeIn])

  useScrollReadyListener(useCallback(() => {
    if (window.innerWidth < theme.breakpoints.values.md) return
    const el = sectionRef.current.querySelector(`.${classes.imageCaption}`)
    gsap.to(el, {
      opacity: 1,
      scrollTrigger: {
        trigger: el,
        start: '30% bottom',
        once: true
      }
    })
  }, []))

  const [pageUrl, setPageUrl] = useState()
  useEffect(() => { setPageUrl(window.location.href) })

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      {parent && (
        <div className={classes.backBtn}>
          <RoundButton link={parent} dark bigPadding showBackArrow showArrow={false} showText={false}>
            Back to {parent.title}
          </RoundButton>
        </div>
      )}

      <h1 className={cn(classes.title, useBigFont && classes.bigTitle)} ref={titleRef}>{title}</h1>

      {author && <div className={cn(classes.meta, classes.author)}>Written by {author}</div>}
      {publishDate && <div className={cn(classes.date, classes.meta)}>{dayjs(publishDate).format('DD MMMM, YYYY')}</div>}

      <div className={cn(classes.meta, classes.share)}>
        <span>share</span>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`} target='_blank' rel='noopener noreferrer nofollow'>Facebook</a>
        {/* <a href={`https://www.twitter.com/share?url=${pageUrl}`} target='_blank' rel='noopener noreferrer nofollow'>Twitter</a> */}
        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`} target='_blank' rel='noopener noreferrer nofollow'>LinkedIn</a>
        <a href={`mailto:?body=${pageUrl}`}>email</a>
      </div>
      {image && (
        <div className={classes.imageWrap}>
          <ResponsiveImage className={classes.image} image={image} aspect={1392 / 761} speed='0.8' />
        </div>
      )}
      {imageCaption && <div className={cn(classes.meta, classes.imageCaption)}>{imageCaption}</div>}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    marginTop: 180,
    [theme.breakpoints.down('md')]: {
      marginTop: 64 - 24
    }
  },
  backBtn: {
    gridColumn: '1 / -1',
    opacity: 0,
    marginBottom: 16
  },
  title: {
    marginBottom: 90,
    gridColumn: '1 / -1',
    lineHeight: '1em',
    textTransform: 'uppercase',
    fontSize: 88,
    opacity: 0,
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1.1em',
      paddingTop: '0.1em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
      // marginTop: 64 - 24,
      marginBottom: 0
    }
  },
  bigTitle: {
    fontSize: 200,
    [theme.breakpoints.down('lg')]: {
      fontSize: 150
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 40
    }
  },
  imageWrap: {
    gridColumn: '1 / -1',
    overflow: 'hidden'
  },
  image: {
    opacity: 0,
    transform: 'scale(1.1)'
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    marginBottom: -16,
    letterSpacing: '0.05em',
    [theme.breakpoints.down('md')]: {
      marginBottom: -8
    },
    opacity: 0
  },
  date: {
    [theme.breakpoints.down('md')]: {
      gridColumn: '3 / -1',
      textAlign: 'right'
    }
  },
  author: {
    gridColumn: '1 / span 4',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 2'
    }
  },
  imageCaption: {
    marginBottom: 0,
    marginTop: -16,
    [theme.breakpoints.down('md')]: {
      marginTop: -8
    }
  },
  share: {
    gridColumn: '10 / -1',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      marginTop: 96 - 16
    },
    '& span': {
      opacity: 0.5,
      marginRight: 16,
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        flex: 1
      }
    },
    '& a': {
      textDecoration: 'none',
      marginLeft: 16
    }
  }
})
