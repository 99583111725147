import { useCallback, useRef } from 'react'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import createUseStyles from '../../lib/createUseStyles'
import gsap from 'gsap'
import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import ShopPill from '../ShopPill'

export const StoriesCollection = ({ data }) => {
  const { pathways } = data ?? {}
  const classes = useStyles()
  const sectionRef = useRef()
  useScrollReadyListener(
    useCallback(() => {
      gsap.to(
        sectionRef.current.querySelectorAll(
          `.${classes.image}, .${classes.pill} > div`
        ),
        {
          opacity: 1,
          scale: 1,
          duration: 0.6,
          ease: 'power2.inOut',
          stagger: 0.1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: '10% bottom'
          }
        }
      )
    }, [])
  )
  return (
    <Section tag="section" className={classes.section} grid ref={sectionRef}>
      {pathways &&
        pathways.map((pathway, i) => (
          <div key={pathway._key} className={classes.pathway}>
            <ShopPill
              link={pathway.link}
              actionTitle={pathway.pathwayRef.title}
              className={classes.pill}
              title={pathway.title}
              colour={pathway.pillButton?.bgColor}
              hoverColour={pathway.pillButton?.fgColor}
              textColor={pathway.pillButton?.textColor}
              hoverStyle={pathway.pillButton?.hoverStyle}
              pathway={pathway}
              index={i}
            />
            <ResponsiveImage
              aspect={450 / 750}
              className={classes.image}
              image={pathway.image}
              speed={0.9}
            />
          </div>
        ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  pathway: {
    gridColumn: 'span 4',
    overflow: 'hidden',
    position: 'relative'
  },
  image: {
    opacity: 0,
    transform: 'scale(1.05)'
  },
  pill: {
    '& > div': {
      opacity: 0
    }
  }
})
