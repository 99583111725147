import createUseStyles from '../lib/createUseStyles'
import { urlFor } from '../server/sanity.server'

export default function LogoImage({ image }) {
  const styles = useStyles()
  return <img src={urlFor(image)} className={styles.image} />
}

const useStyles = createUseStyles({
  image: {
    maxWidth: 163,
    marginBottom: 20
  }
})
