import { useRef, useEffect } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import RoundButton from '../RoundButton'
import { useAtom } from 'jotai'
import { headerFadeInAtom } from '../Header/headerState'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'

export default function CareerDetails ({ data, page }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()

  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (!headerFadeIn) return

    const titleSplit = new SplitText(titleRef.current, { type: 'words, lines', linesClass: 'split-parent' })

    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.inOut',
        duration: 0.6
      }
    })

    tl.set(sectionRef.current, { opacity: 1 })
    tl.set(titleRef.current, { opacity: 1 })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1
      // onComplete: () => titleSplit.revert()
    })
    tl.to(sectionRef.current.querySelectorAll(`.${classes.details}, .${classes.backButton}, .${classes.copy}`), {
      opacity: 1,
      y: 0,
      stagger: 0.1
    }, '-=0.6')
  }, [headerFadeIn])

  const { copy, link } = data
  const { title, location, jobType, parent } = page

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <div className={classes.aside}>
        <h1 className={classes.title} ref={titleRef}>{title}</h1>
        <div div className={classes.detailsWrap}>
          <div className={classes.details}>
            <div className={classes.meta}>Location</div>
            <div>{location}</div>
          </div>
          <div className={classes.details}>
            <div className={classes.meta}>Role</div>
            <div>{jobType}</div>
          </div>
        </div>
        <RoundButton className={classes.backButton} link={parent} dark bigPadding showArrow={false} showBackArrow showText={false}>Back to Careers</RoundButton>
      </div>
      <div className={classes.copy}>
        <RichContent content={copy} />
        <RoundButton className={classes.applyButton} link={link} yellow bigPadding showArrow={false} />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    marginTop: 90,
    [theme.breakpoints.down('md')]: {
      marginTop: 64 / 2 
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 72,
    maxWidth: '12em',
    marginBottom: 64,
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
      marginBottom: 40 - 16
    },
    '& .split-parent': {
      overflowY: 'hidden',
      height: '1.1em',
      marginBottom: '-0.25em'
    }
  },
  copy: {
    gridColumn: '7 /-1',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 /-1'
    },
    opacity: 0,
    transform: 'translateY(20px)',
    '& ul': {
      paddingLeft: '.9em'
    },
    '& h2, & h3, & h4': {
      fontFamily: 'inherit'
    },
    '& h2': {
      fontSize: 28,
      maxWidth: '23em',
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'unset'
      },
    },
    '& h3': {
      fontSize: 24,
      marginBottom: 32
    }
  },
  backButton: {
    opacity: 0,
    transform: 'translateY(20px)',
    [theme.breakpoints.down('md')]: {
      marginBottom: 56 - 16 - 8
    },
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '0.05em',
    marginBottom: 16
  },
  aside: {
    gridColumn: 'span 6',
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.grid.md.gutter,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
    }
  },
  detailsWrap: {
    display: 'flex',
    width: '100%',
    marginBottom: 72,
    [theme.breakpoints.down('md')]: {
      marginBottom: 32 - 16
    },
    '& > *': {
      width: theme.span(3, 'md'),
      [theme.breakpoints.down('md')]: {
        width: theme.span(2, 'sm')
      },
      // flex: 1
    }
  },
  details: {
    gridRow: 2,
    gridColumn: 'span 2',
    opacity: 0,
    transform: 'translateY(20px)'
  },
  applyButton: {
    marginTop: 54
  }
})
