import { useRef, useEffect, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import ResponsiveImage from '../ResponsiveImage'
import gsap from 'gsap'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import VimeoPlayer from '../VimeoPlayer'

export default function ImageGrid ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const { images } = data

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.image}, .vimeo-player`), {
      start: '30% bottom',
      onEnter: item => gsap.to(item, { 
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1, 
      })
    })
  }, []))

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      {images && images.map((image, i) => {
        if (image._type === 'image') {
          return (
            <div className={classes.imageWrap} key={image._key}>
              <ResponsiveImage image={image} className={classes.image} speed='0.9'/>
            </div>
          )
        } else if (image._type === 'aspectRatioImage') {
          return (
            <div className={classes.imageWrap} key={image._key}>
              <ResponsiveImage image={image.image} className={classes.image} aspect={Number(image.aspect)} speed='0.9' />
            </div>
          )
        } else if (image._type === 'video') {
          return (
            <div className={classes.imageWrap} key={image._key}>
              <VimeoPlayer vimeoData={image.videoData} previewData={image.videoData} inline/>
            </div>
          )
        }
      })}
    </Section>
  )
}

const useStyles = createUseStyles({
  imageWrap: {
    gridColumn: 'span 4',
    marginBottom: 64,
    overflow: 'hidden',
    '&:last-child': {
      marginBottom: 36
    },
    '&:nth-child(3n-4)': {
      gridColumn: '6 / span 4'
    },
    '&:nth-child(3n-3)': {
      gridColumn: '5 / -1'
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1 !important',
      marginBottom: 0,
    },
    '& .vimeo-player': {
      opacity: 0
    }
  },
  image: {
    opacity: 0,
    transform: 'scale(1.05)'
  }
})
