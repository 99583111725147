import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import { keyframes } from 'goober'
import cn from 'classnames'
import map from 'lodash/map'
import range from 'lodash/range'

export const Marquee = ({
  text,
  flip = false,
  className,
  count = 6,
  speed = '60s'
}) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.marquee, className)}>
      {map(range(count), (x, i) => (
        <div
          key={i}
          className={cn(classes.marqueeInner, flip && classes.marqueeRight)}
          style={{ animationDuration: speed }}
        >
          <div>{text}</div>
        </div>
      ))}
      {map(range(count), (x, i) => (
        <div
          key={i}
          className={cn(classes.marqueeInner, flip && classes.marqueeRight)}
          style={{ animationDuration: speed }}
        >
          <div>{text}</div>
        </div>
      ))}
    </div>
  )
}

export default function MarqueeSlice({ data, pageData }) {
  const classes = useStyles()

  return (
    <>
      <div className={classes.marqueeWrapper}>
        {data.marqueeRows.map((text, k) => (
          <Marquee key={k} text={text} />
        ))}
      </div>
    </>
  )
}

const marquee = keyframes`
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
`

const marqueeRight = keyframes`
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
`

const useStyles = createUseStyles({
  marqueeWrapper: {
    marginTop: 98,
    marginBottom: 124,
    borderBottom: '1px solid var(--foreground)',
    [theme.breakpoints.down('md')]: {
      marginTop: 42,
      marginBottom: 59
    }
  },
  marquee: {
    paddingTop: 38,
    borderTop: '1px solid var(--foreground)',
    display: 'block',
    width: '100%',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    overflow: 'hidden',
    marginTop: 14,
    fontFamily: theme.fonts.heading,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: 200,
    lineHeight: '148px',
    [theme.breakpoints.down('md')]: {
      paddingTop: 12,
      marginTop: 6,
      fontSize: 72,
      lineHeight: '62px'
    }
  },
  marqueeInner: {
    display: 'inline-block',
    animation: `${marquee} 60s infinite linear`,
    '& div': {
      display: 'inline-block',
      margin: '0 48px',
      [theme.breakpoints.down('md')]: {
        margin: '0 14px'
      }
    }
  },
  marqueeRight: {
    animation: `${marqueeRight} 60s infinite linear`
  }
})
