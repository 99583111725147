import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import cn from 'classnames'

export default function Checkbox ({ className, label, id, value, onChange, ...props }) {
  const classes = useStyles()

  return (
    <label className={cn(className, classes.container)}>
      <input className={classes.checkbox} type='checkbox' value={value} onChange={onChange} {...props} />
      {label}
    </label>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontSize: 16,
    textTransform: 'none'
  },
  checkbox: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    width: 20,
    height: 20,
    appearance: 'none',
    backgroundColor: theme.colors.background,
    color: 'currentColor',
    border: '1px solid currentColor',
    position: 'relative',
    '&::before': {
      content: '""',
      width: 12,
      height: 12,
      transform: 'scale(0)',
      transition: '0.12s transform ease-in-out',
      backgroundColor: 'currentColor',
      borderRadius: '50%',
      display: 'block',
      position: 'absolute',
      left: 3,
      top: 3
    },
    '&:checked::before': {
      transform: 'scale(1)'
    }
  }
})
