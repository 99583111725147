import { useEffect, useState, useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import useScrollTrigger from '../../hooks/useScrollTrigger'
import { useAtom } from 'jotai'
import { headerColoursAtom, headerFadeInAtom } from '../Header/headerState'
import gsap from 'gsap'
import { useKeenSlider } from 'keen-slider/react'
import cn from 'classnames'
import VimeoPlayer from '../VimeoPlayer'
import maxBy from 'lodash/maxBy'
import defer from 'lodash/defer'
import useWindowResize from '../../hooks/useWindowResize'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import NumberTicker from '../NumberTicker'

export const ImpactHero = ({ data, page }) => {
  const classes = useStyles()
  const [, setHeaderColours] = useAtom(headerColoursAtom)
  const [headerFadeIn] = useAtom(headerFadeInAtom)
  const controlsRef = useRef()

  const sectionRef = useScrollTrigger(
    () => {
      return {
        trigger: sectionRef.current,
        start: () => 'top 0',
        end: () => `bottom ${window.innerWidth < theme.breakpoints.values.md ? 350 : 350}px`,
        scrub: false,
        onLeave: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onEnterBack: () => {
          setHeaderColours({
            foreground: '#000000',
            background: 'rgba(233, 252, 156,1)',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onLeaveBack: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        }
      }
    },
    (tl) => { },
    () => {
      setHeaderColours({
        background: theme.colors.pageTheme.default.background,
        foreground: '#000000',
        duration: 0.3,
        ease: 'expo.out'
      })
    }
  )

  useEffect(() => {
    if (!headerFadeIn) return

    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      }
    })

    tl.add(() => {
      setHeaderColours({
        foreground: '#000000',
        background: 'rgba(233, 252, 156,1)',
        duration: 0.3,
        ease: 'power2.inOut'
      })
    })
    tl.to(sectionRef.current, { opacity: 1, duration: 0.3 })
    tl.from(sectionRef.current.querySelectorAll(`.${classes.slide} > *`), {
      y: '110%',
      stagger: 0.1
    })
    tl.from(sectionRef.current.querySelectorAll(`.${classes.controls}`), {
      opacity: 0
    }, '-=0.6')
    if (video) {
      tl.fromTo(sectionRef.current.querySelector('.vimeo-player'), {
        y: 80,
        opacity: 0
      }, {
        y: 0,
        opacity: 1
      }, '-=1')
    }
    tl.from(sectionRef.current.querySelectorAll(`.${classes.meta}`), {
      y: 40,
      opacity: 0
    }, '-=0.55')
  }, [headerFadeIn])

  const [progress, setProgress] = useState(0)

  const positionControls = useCallback(() => {
    const textComponents = sectionRef.current.querySelectorAll(`.${classes.text}`)
    const largestComponent = maxBy(textComponents, x => x.getBoundingClientRect().height)
    const isMobile = window.innerWidth < theme.breakpoints.values.md
    const spacing = isMobile ? theme.spacing(1) : theme.spacing(4)
    controlsRef.current.style.bottom = `${largestComponent.getBoundingClientRect().height + spacing}px`
  }, [])

  useWindowResize(useDebouncedCallback(positionControls, 200, []), true)

  const [refCallback, slider] = useKeenSlider({
    slides: {
      perView: 1
    },
    slideChanged (slider) {
      setProgress(slider.track.details.rel)
    },
    created: () => {
      defer(positionControls)
    }
  })

  const sliderBack = () => {
    if (progress === 0) return
    slider.current.prev()
  }
  const sliderNext = () => {
    slider.current.next()
  }

  const { countCarousel, video, metaText, secondMetaText } = data

  return (
    <div className={classes.root} ref={sectionRef}>

      <div ref={refCallback} className={cn('keen-slider', classes.slider)}>
        {countCarousel && countCarousel.map((count, i) => (
          <div className={cn('keen-slider__slide', classes.slide)} key={count._key}>
            <div className={classes.count}>
              {count.showDollarSign && '$'}<NumberTicker fromCount={count.startNumber} toCount={count.endNumber} animate={progress === i} duration={i === 0 ? 2.5 : 1.5} />
            </div>
            <div className={classes.text}>
              {count.text}
            </div>
          </div>
        ))}
        <div className={classes.controls} ref={controlsRef}>
          <button className={cn('reset-button', classes.arrowBtn, progress === 0 && classes.btnDisabled)} disabled={progress === 0} onClick={sliderBack}>
            <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M8.55353 0.686707L9.50528 1.63846L3.514 7.62974H19.3036L19.3036 8.97171L3.514 8.97171L9.50528 14.963L8.55353 15.9147L0.939511 8.30072L8.55353 0.686707Z' fill='black' />
            </svg>
          </button>
          <button className={cn('reset-button', classes.arrowBtn, progress === countCarousel.length - 1 && classes.btnDisabled)} disabled={progress === countCarousel.length - 1} onClick={sliderNext}>
            <svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M10.8879 0.686707L9.93613 1.63846L15.9274 7.62974H0.13784L0.13784 8.97171L15.9274 8.97171L9.93613 14.963L10.8879 15.9147L18.5019 8.30072L10.8879 0.686707Z' fill='black' />
            </svg>
          </button>
        </div>
      </div>

      <Section grid fullWidth className={classes.grid}>
        {video && <VimeoPlayer video={video} vimeoData={data.vimeoData} previewData={data.previewData} autoplay={false} style={{ opacity: 0 }}/>}
        {metaText && <div className={cn(classes.meta)}>{metaText}</div>}
        {secondMetaText && <div className={cn(classes.meta)}>{secondMetaText}</div>}
      </Section>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    marginTop: -132,
    paddingTop: 131,
    [theme.breakpoints.down('md')]: {
      marginTop: -132,
      paddingTop: 84
    },
    opacity: 0,
    background:
      'linear-gradient(0deg, rgba(233, 252, 156,0) 0%, rgba(233, 252, 156,1) 47%, rgba(233, 252, 156, 1) 100%)'
  },
  grid: {
    padding: `0 ${theme.grid.md.gutter}px`,
    rowGap: '8px !important'
  },
  controls: {
    position: 'absolute',
    right: theme.grid.md.gutter,
    bottom: '6.5em'
  },
  arrowBtn: {
    marginLeft: 22
  },
  btnDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    marginBottom: -16,
    letterSpacing: '0.05em',
    gridColumn: 'span 3',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  slider: {
    gridColumn: '1/ -1',
    width: '100%',
    paddingTop: 110,
    position: 'relative',
    marginBottom: theme.grid.md.gutter,
    [theme.breakpoints.down('md')]: {
      marginBottom: 94
    },
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing !important'
    }
  },
  slide: {
    padding: `0 ${theme.grid.md.gutter}px`,
    flex: '1',
    flexShrink: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'column'
    }
  },
  count: {
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: '10vw',
    letterSpacing: '-0.1em',
    lineHeight: '1em',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'end',
      fontSize: '13vw',
      width: '100%',
      marginBottom: 48
    }
  },
  text: {
    maxWidth: 540,
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: 40,
    textAlign: 'right',
    textTransform: 'uppercase',
    lineHeight: '1em',
    marginLeft: theme.spacing(4),
    paddingBottom: '1.2vw',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-end',
      paddingBottom: '0'
    }
  }
})
