const ChangeTheCourse = () => (
  <svg width="182" height="183" viewBox="0 0 182 183" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.7068 176.578C78.6977 178.898 80.3575 180.366 82.5834 180.697C85.87 181.186 88.0083 178.923 88.4683 175.831C88.9304 172.724 87.6814 170.034 84.3649 169.54C81.6759 169.141 79.7776 170.507 79.1132 173.126L80.5325 173.338C80.9291 171.595 82.2002 170.44 84.0676 170.718C86.6222 171.098 87.3957 173.289 87.0335 175.725C86.7024 177.95 85.3157 179.882 82.7761 179.504C81.2971 179.284 80.2004 178.342 80.126 176.789L78.7068 176.578Z" fill="black"/>
    <path d="M74.501 167.276L73.1167 166.899L71.8176 171.664L66.3387 170.17L67.6378 165.405L66.2535 165.028L63.4168 175.432L64.8011 175.809L66.0208 171.336L71.4997 172.83L70.28 177.303L71.6643 177.68L74.501 167.276Z" fill="black"/>
    <path d="M60.2398 162.835L58.9178 162.17L56.3784 164.527L52.3043 162.476L52.7121 159.046L51.2956 158.333L50.2105 169.86L51.627 170.573L60.2398 162.835ZM51.5366 169.073L51.5096 169.06L52.1657 163.759L55.4304 165.403L51.5366 169.073Z" fill="black"/>
    <path d="M45.8859 154.848L44.8176 154.007L39.4668 160.809L39.443 160.791L40.3661 150.506L39.1315 149.534L32.464 158.01L33.5324 158.85L38.9392 151.977L38.963 151.996L38.0195 162.38L39.2184 163.323L45.8859 154.848Z" fill="black"/>
    <path d="M28.7032 138.339L28.1251 137.641L23.7522 141.264L26.7683 144.905L27.6987 144.134L25.5017 141.482C26.9557 140.16 29.0016 140.191 30.4182 141.9C31.9503 143.749 31.1974 146.06 29.4645 147.496C27.6851 148.97 25.2468 149.578 23.5797 147.566C22.5583 146.333 22.2898 144.85 23.3627 143.588L22.4473 142.483C20.7653 144.406 21.1461 146.523 22.6493 148.337C24.8271 150.966 28.0191 150.557 30.4381 148.553C32.6013 146.76 33.4011 143.606 31.3486 141.129C30.5391 140.152 29.3113 139.404 27.9091 139.487L28.7032 138.339Z" fill="black"/>
    <path d="M23.253 130.291L19.716 123.687L18.6509 124.257L21.5105 129.596L18.2219 131.358L15.5762 126.418L14.5111 126.989L17.1568 131.928L14.1345 133.547L11.2963 128.248L10.2312 128.819L13.7469 135.382L23.253 130.291Z" fill="black"/>
    <path d="M14.037 106.101L13.7682 104.692L4.3621 106.485L3.6888 102.954L2.50191 103.181L4.11726 111.652L5.30416 111.426L4.63086 107.895L14.037 106.101Z" fill="black"/>
    <path d="M12.4521 94.4975L12.4603 93.0627L7.52154 93.0346L7.55391 87.3557L12.4927 87.3839L12.5008 85.9491L1.71713 85.8876L1.70896 87.3224L6.34565 87.3488L6.31328 93.0277L1.67659 93.0012L1.66841 94.436L12.4521 94.4975Z" fill="black"/>
    <path d="M13.4091 78.0507L14.896 70.7085L13.7118 70.4686L12.5096 76.4046L8.8533 75.6641L9.96553 70.1722L8.7813 69.9324L7.66906 75.4243L4.30879 74.7438L5.50197 68.8522L4.31774 68.6123L2.83975 75.9102L13.4091 78.0507Z" fill="black"/>
    <path d="M17.8853 45.0176C15.7239 44.1755 13.7571 45.1966 12.6482 47.1548C11.0108 50.0461 12.3537 52.8547 15.0742 54.3953C17.8078 55.9434 20.767 55.7446 22.4192 52.8269C23.7589 50.4613 23.1656 48.1987 20.9602 46.6374L20.2532 47.8859C21.737 48.8824 22.3577 50.4836 21.4273 52.1264C20.1547 54.3738 17.8314 54.3078 15.6892 53.0947C13.731 51.9858 12.4269 49.9976 13.6921 47.7633C14.4289 46.4622 15.702 45.7773 17.1783 46.2661L17.8853 45.0176Z" fill="black"/>
    <path d="M24.7684 38.7663C26.9565 40.715 30.1419 41.226 32.4221 38.6657C34.7022 36.1053 33.8272 33.0002 31.6391 31.0515C29.4509 29.1028 26.2655 28.5918 23.9854 31.1521C21.7052 33.7125 22.5802 36.8176 24.7684 38.7663ZM25.7226 37.6948C24.0985 36.2483 23.1399 33.9183 24.8877 31.9557C26.6355 29.9932 29.0606 30.6765 30.6848 32.123C32.309 33.5694 33.2675 35.8995 31.5197 37.8621C29.7719 39.8246 27.3468 39.1413 25.7226 37.6948Z" fill="black"/>
    <path d="M40.8601 16.6209L39.7014 17.4672L43.7639 23.0286C44.8865 24.5653 44.6963 25.9949 43.2327 27.0639C41.696 28.1865 40.2032 27.9864 39.0806 26.4497L35.0181 20.8883L33.8595 21.7346L37.922 27.296C39.6147 29.6133 41.7014 29.6789 43.9454 28.0396C46.1163 26.4538 46.5262 24.3776 44.9226 22.1823L40.8601 16.6209Z" fill="black"/>
    <path d="M53.6494 14.9618L52.0792 11.5445L55.3181 10.0563C56.5395 9.49506 57.389 9.96909 57.8304 10.9298C58.4862 12.3571 57.5882 13.152 56.3667 13.7132L53.6494 14.9618ZM54.7733 20.8446L56.0771 20.2456L54.1538 16.0597L57.3378 14.5967C58.6004 14.0166 59.2058 14.7191 59.7844 15.6167C60.418 16.4892 60.6977 17.6405 61.2511 17.8682L62.7059 17.1998C61.8479 16.7796 61.411 15.6839 60.8872 14.761C60.3497 13.8445 59.7317 13.1145 58.4918 13.4183L58.4791 13.3908C59.5081 12.5192 59.5819 11.3051 59.0396 10.1249C58.3207 8.56031 56.7213 8.08184 54.8822 8.92682L50.2709 11.0456L54.7733 20.8446Z" fill="black"/>
    <path d="M73.3492 6.27499C72.7054 3.99104 70.8313 3.37087 68.6876 3.88771C66.7789 4.34791 65.1114 5.74427 65.6247 7.87327C66.0884 9.79671 67.8937 9.87414 69.6561 9.83765C71.4184 9.80116 73.1524 9.6472 73.4993 11.0861C73.864 12.5984 72.5647 13.3312 71.302 13.6356C69.7162 14.0179 68.0639 13.6084 67.6391 11.8465L66.3176 12.1651C66.9336 14.7199 69.1919 15.3873 71.5411 14.8209C73.4499 14.3607 75.4217 12.9375 74.8836 10.7058C74.388 8.65018 72.5725 8.46643 70.8067 8.48824C69.0261 8.51359 67.3063 8.72627 66.9947 7.43419C66.6655 6.06869 67.7375 5.35969 68.9121 5.07649C70.351 4.72957 71.5052 5.13489 72.0278 6.59359L73.3492 6.27499Z" fill="black"/>
    <path d="M83.8801 12.3955L91.3626 12.0321L91.3039 10.8252L85.2546 11.119L85.0736 7.39285L90.6704 7.12102L90.6118 5.91416L85.015 6.18599L84.8487 2.76154L90.8528 2.46993L90.7942 1.26307L83.3569 1.62429L83.8801 12.3955Z" fill="black"/>
    <path d="M108.313 8.43017C107.523 11.2517 108.463 14.338 111.764 15.2623C115.066 16.1867 117.471 14.037 118.261 11.2154C119.051 8.39381 118.111 5.30758 114.81 4.38324C111.508 3.45891 109.103 5.60859 108.313 8.43017ZM109.695 8.81701C110.281 6.72264 111.953 4.83826 114.484 5.54678C117.015 6.2553 117.466 8.73419 116.879 10.8286C116.293 12.9229 114.621 14.8073 112.09 14.0988C109.559 13.3903 109.108 10.9114 109.695 8.81701Z" fill="black"/>
    <path d="M122.696 18.7896L123.974 19.4434L126.224 15.0472L130.634 17.3048L131.185 16.2293L126.775 13.9717L128.337 10.9199L133.365 13.4941L133.916 12.4185L127.611 9.19052L122.696 18.7896Z" fill="black"/>
    <path d="M142.402 31.2811L143.452 32.3247L146.598 30.874L149.833 34.09L148.379 37.2234L149.503 38.3415L154.119 27.7233L152.994 26.6052L142.402 31.2811ZM152.614 28.0587L152.635 28.08L150.363 32.9137L147.772 30.3366L152.614 28.0587Z" fill="black"/>
    <path d="M174.08 57.9196L173.458 56.6267L164.424 58.5438L164.411 58.5166L171.46 52.4758L170.785 51.0741L161.653 52.787L161.64 52.7598L168.807 46.964L168.172 45.6439L159.654 52.8108L160.302 54.1582L169.548 52.474L169.561 52.5012L162.463 58.6493L163.112 59.9966L174.08 57.9196Z" fill="black"/>
    <path d="M171.46 67.2195C168.599 67.8533 166.32 70.1371 167.062 73.4844C167.804 76.8317 170.834 77.9395 173.694 77.3057C176.555 76.672 178.834 74.3882 178.092 71.0408C177.35 67.6935 174.32 66.5857 171.46 67.2195ZM171.77 68.6204C173.894 68.15 176.344 68.7364 176.912 71.3022C177.481 73.868 175.507 75.4345 173.384 75.9049C171.26 76.3753 168.81 75.7889 168.242 73.2231C167.673 70.6573 169.647 69.0908 171.77 68.6204Z" fill="black"/>
    <path d="M169.226 86.0352L169.217 87.3945L178.189 87.4522L178.188 87.4824L169.195 90.7927L169.188 92.0161L178.137 95.4418L178.137 95.472L169.166 95.4143L169.157 96.7736L179.941 96.8429L179.953 94.8795L170.913 91.4079L179.997 88.068L180.01 86.1046L169.226 86.0352Z" fill="black"/>
    <path d="M168.454 102.443L168.133 103.888L171.041 105.772L170.051 110.225L166.626 110.67L166.281 112.218L177.723 110.445L178.067 108.897L168.454 102.443ZM176.635 109.352L176.629 109.382L171.329 110.045L172.122 106.477L176.635 109.352Z" fill="black"/>
    <path d="M164.23 118.319L163.677 119.561L171.583 123.082L171.57 123.109L161.373 124.735L160.734 126.17L170.585 130.557L171.138 129.315L163.15 125.758L163.162 125.73L173.461 124.1L174.081 122.706L164.23 118.319Z" fill="black"/>
    <path d="M164.258 138.051L163.767 138.795C163.187 138.413 162.652 137.825 163.026 137.257L162.396 136.842C161.756 137.813 162.339 138.794 163.335 139.451L164.735 140.373L165.658 138.974L164.258 138.051Z" fill="black"/>
    <path d="M152.457 150.541C154.268 152.074 156.168 151.54 157.656 149.912C158.981 148.462 159.581 146.372 157.965 144.895C156.504 143.56 154.965 144.506 153.524 145.522C152.084 146.538 150.733 147.636 149.64 146.638C148.492 145.588 149.159 144.254 150.035 143.295C151.136 142.091 152.734 141.506 154.072 142.729L154.989 141.725C153.049 139.952 150.804 140.663 149.174 142.446C147.849 143.896 147.011 146.178 148.706 147.727C150.267 149.154 151.874 148.29 153.326 147.284C154.787 146.267 156.094 145.129 157.075 146.025C158.112 146.973 157.62 148.16 156.805 149.052C155.806 150.145 154.623 150.454 153.374 149.538L152.457 150.541Z" fill="black"/>
    <path d="M139.639 155.844L133.464 159.438L134.072 160.482L139.006 157.61L143.823 165.887L145.063 165.165L139.639 155.844Z" fill="black"/>
    <path d="M128.005 161.837L126.673 162.371L130.688 172.38L132.019 171.845L128.005 161.837Z" fill="black"/>
    <path d="M119.892 164.824L118.495 165.151L119.62 169.96L114.796 171.088L115.071 172.265L119.895 171.136L120.676 174.475L115.176 175.761L115.451 176.938L122.348 175.325L119.892 164.824Z" fill="black"/>
    <path d="M108.096 168.076L100.807 169.806L101.086 170.982L106.979 169.583L107.84 173.212L102.389 174.507L102.668 175.682L108.12 174.388L108.911 177.724L103.063 179.112L103.342 180.288L110.587 178.568L108.096 168.076Z" fill="black"/>
  </svg>
)

export default ChangeTheCourse