import get from 'lodash/get'
import compact from 'lodash/compact'

function resolveSlug(document) {
  const slug = get(document, ['slug'], get(document, ['current'], document))
  if (slug !== 'home') {
    // home resolves to `/`
    return slug
  }
}

export function resolveInternalLinkUrl({ parent, slug, wholesale }) {
  const parts = compact([
    wholesale && 'wholesale',
    resolveSlug(parent),
    resolveSlug(slug)
  ])

  return `/${compact(parts).join('/')}`
}

export function resolveQueryParams(params) {
  const urlParams = new URLSearchParams(params)
  return Object.fromEntries(urlParams)
}

export function resolveLink(linkOrPage) {
  if (!linkOrPage) return null
  if (linkOrPage.linkType) {
    if (linkOrPage.linkType === 'external') {
      return linkOrPage
    }
    return {
      text: get(linkOrPage, ['text'], get(linkOrPage, ['page', 'title'])),
      url: linkOrPage.page ? resolveInternalLinkUrl(linkOrPage.page) : '',
      hash: get(linkOrPage, ['pageAnchor']),
      query: linkOrPage.query ? resolveQueryParams(linkOrPage.query) : null
    }
  }
  return {
    text: get(linkOrPage, ['title']),
    url: resolveInternalLinkUrl(linkOrPage),
    hash: get(linkOrPage, ['pageAnchor']),
    query: linkOrPage.query ? resolveQueryParams(linkOrPage.query) : null
  }
}
