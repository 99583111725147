import { useCallback, useRef } from 'react'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'

export default function CallToAction({ data }) {
  const { topTitleLeft, topTitleRight, copy, bgColor } = data ?? {}
  const styles = useStyles()
  const sectionRef = useRef()
  useScrollReadyListener(
    useCallback(() => {
      ScrollTrigger.batch(
        sectionRef.current.querySelectorAll(
          `.${styles.header}, .${styles.copy}`
        ),
        {
          start: '30% bottom',
          onEnter: item =>
            gsap.to(item, {
              opacity: 1,
              y: 0,
              duration: 0.6,
              ease: 'power2.inOut',
              stagger: 0.1
            })
        }
      )
    }, [])
  )
  return (
    <div
      className={styles.root}
      style={{ backgroundColor: bgColor.value }}
      ref={sectionRef}
    >
      <div className={styles.header}>
        <div>{topTitleLeft}</div>
        <div>{topTitleRight}</div>
      </div>
      {copy && <RichContent className={styles.copy} content={copy} />}
      <div />
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: 800,
    padding: `${theme.grid.sm.margin}px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.grid.md.margin}px`
    }
  },
  header: {
    opacity: 0,
    transform: 'translateY(40px)',
    borderTop: '1px solid black',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    fontSize: 12,
    textTransform: 'uppercase',
    paddingTop: `${theme.grid.sm.margin}px`,
    '> div': {
      letterSpacing: '0.04em'
    }
  },
  copy: {
    opacity: 0,
    transform: 'translateY(40px)',
    textTransform: 'uppercase',
    margin: '0 auto',
    // width: '100%',
    // maxWidth: 924,
    textAlign: 'center'
  }
})
