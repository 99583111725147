import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'

export const Plus = ({ open, ...props }) => {
  const line1Ref = useRef()
  const line2Ref = useRef()

  useEffect(() => {
    gsap.to(line1Ref.current, { rotate: open ? 90 : 0, duration: 0.4, ease: 'expo.inOut', transformOrigin: '50% 50%' })
    gsap.to(line2Ref.current, { rotate: open ? 180 : 0, duration: 0.4, ease: 'expo.inOut', transformOrigin: '50% 50%' })
  }, [open])

  return (
    <svg
      width={16}
      fill='none'
      stroke='var(--foreground)'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <line x1='8' y1='0' x2='8' y2='16' strokeWidth={1} ref={line1Ref} />
      <line x1='0' y1='8' x2='16' y2='8' strokeWidth={1} ref={line2Ref} />
    </svg>
  )
}
