import { decode } from 'blurhash'
import { useEffect, useRef } from 'react'
import createUseStyles from '../../lib/createUseStyles'

export default function BlurHash ({ blurHash, width = 120, height = 120 }) {
  const ref = useRef()
  const styles = useStyles()

  useEffect(() => {
    const canvas = ref.current
    if (canvas && blurHash) {
      const pixels = decode(blurHash, width, height)
      const ctx = canvas.getContext('2d')
      const imageData = ctx.createImageData(width, height)
      imageData.data.set(pixels)
      ctx.putImageData(imageData, 0, 0)
    }
  }, [])

  return <canvas width={width} height={height} ref={ref} className={styles.canvas} />
}

const useStyles = createUseStyles({
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})
