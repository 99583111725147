import { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import createUseStyles from '../../../../lib/createUseStyles'
import { theme } from '../../../../styles/theme'
import Section from '../../../Section'
import { CategoryFilter } from './CategoryFilter'
import gsap from 'gsap'

export const ProductListingHero = ({ intro, page, siblings }) => {
  const styles = useStyles()
  const sectionRef = useRef()

  const [activeCategory, setActiveCategory] = useState(page)

  const { animationImage } = page

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut',
      }
    })
    tl.set(sectionRef.current, { opacity: 1 })
    tl.from(sectionRef.current.children[0].children, {
      onComplete: () => gsap.set(sectionRef.current.children[0], { overflow: 'unset' })
    })
    tl.from(sectionRef.current.children[1], {
      opacity: 0,
      y: 20
    }, '-=0.4')
  }, [])

  return (
    <Section grid className={styles.root} ref={sectionRef}>
      <div className={styles.dropdown}>
        <CategoryFilter
          categories={siblings}
          onChange={setActiveCategory}
          activeCategory={activeCategory}
          animationImage={animationImage}
        />
      </div>
      <div className={styles.details}>
        <p>{intro}</p>
      </div>
    </Section>
  )
}

ProductListingHero.propTypes = {
  intro: PropTypes.string,
  page: PropTypes.object.isRequired,
  siblings: PropTypes.arrayOf(PropTypes.object).isRequired
}

const useStyles = createUseStyles({
  root: {
    opacity: 0,
    rowGap: `${theme.spacing(5)}px!important`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(18)}px 0 ${theme.spacing(8)}px`
    }
  },
  dropdown: {
    gridColumn: '1 / -1',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 8'
    },
    [theme.breakpoints.up('lg')]: {
      gridColumn: 'span 9'
    }
  },
  details: {
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 4',
      // alignSelf: 'flex-end',
      '& p': {
        marginBottom: '1vw'
      }
    },
    [theme.breakpoints.up('lg')]: {
      gridColumn: 'span 3'
    }
  }
})
