import { useCallback, useRef } from 'react'
import cn from 'classnames'
import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import Link from '../../Link'
import { formatCurrency } from '../../../helpers/format'
import ResponsiveImage from '../../ResponsiveImage'
import { primaryInput } from 'detect-it'
import { showCursorAtom, cursorColourAtom, cursorSizeAtom } from '../../Header/ProductCursor'
import { useAtom, useSetAtom } from 'jotai'
import { headerColoursAtom } from '../../Header/headerState'

export const ProductListingItem = ({ product, className }) => {
  const styles = useStyles()

  const isSale = !!product.product?.sale_price
  const price = formatCurrency(product.product?.calculated_price)
  const oldPrice = isSale && formatCurrency(product.product?.price)

  const setCursor = useSetAtom(showCursorAtom)
  const setCursorColour = useSetAtom(cursorColourAtom)
  const [cursorSize, setCursorSize] = useAtom(cursorSizeAtom)
  const setHeaderColours = useSetAtom(headerColoursAtom)



  const onMouseEnter = useCallback(() => {
    if (cursorSize === 'big') return
    const colour = product.productBg
    setCursor(true)
    setCursorColour(colour)
  }, [cursorSize])

  const onMouseLeave = useCallback(() => {
    if (cursorSize === 'big') return
    setCursor(false)
  }, [cursorSize])

  const onClick = useCallback(() => {
    setCursorSize('big')
    setHeaderColours({
      background: product.productBg || theme.colors.pageTheme.default.background,
      foreground: theme.colors.pageTheme.default.foreground,
      duration: 0.3,
      ease: 'power3.out'
    })
  }, [])

  const eventListeners = {
    onMouseEnter: (primaryInput === 'touch') ? null : onMouseEnter,
    onClick: (primaryInput === 'touch') ? null : onClick,
    onMouseLeave: (primaryInput === 'touch') ? null : onMouseLeave
  }
  return (
    <Link
      link={product}
      showText={false}
      className={cn(styles.root, className)}
      {...eventListeners}
    >
      <div className={styles.media}>
        {product.featuredImage && (
          <ResponsiveImage
            image={product.featuredImage}
            className={styles.image}
            showPreview={false}
            contain
            containAspect={1}
            inset
          />
        )}
      </div>
      <div className={styles.details}>
        <span className={styles.onSale}>
          {isSale && <span>on sale</span>}
        </span>
        <h3 className={styles.title}>{product.title}</h3>
        <span className={styles.price}>
          {isSale && <span className={styles.oldPrice}>{oldPrice}</span>}
          {price}
        </span>
      </div>
    </Link>
  )
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(8)
    }
  },
  media: {
    position: 'relative',
    width: '100%',
    aspectRatio: 1.3,
    paddingTop: '60%'
  },
  image: {
    padding: 5
  },
  details: {
    fontSize: '0.85rem',
    fontFamily: theme.fonts.bodyFace,
    fontWeight: theme.fonts.bodyFontWeight,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(1)
    }
  },
  title: {
    font: 'inherit',
    fontSize: '0.75rem',
    letterSpacing: '0.06em',
    textTransform: 'uppercase',
    lineHeight: 1.1,
    textAlign: 'center',
    margin: 0
  },
  category: {
    color: 'rgb(17,17,17, 0.4)',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  price: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.625rem'
    }
  },
  onSale: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.625rem'
    }
  },
  oldPrice: {
    opacity: 0.5,
    marginRight: 4,
    textDecoration: 'line-through'
  }
})
