import { useEffect, useState, useMemo } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import RichContent from '../RichContent'
import ResponsiveImage from '../ResponsiveImage'
import gsap from 'gsap'
import cn from 'classnames'
import useAddItem from '@bigcommerce/storefront-data-hooks/cart/use-add-item'
import { giftMessageDialogOpenAtom } from './GiftMessage'

import useScrollTrigger from '../../hooks/useScrollTrigger'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'

import { useAtom, useSetAtom } from 'jotai'
import { headerColoursAtom, headerFadeInAtom, headerCurrentlyOpenAtom } from '../Header/headerState'
import { Marquee } from '../ShopPill'
import QuantitySelector from '../QuantitySelector'

import { formatCurrency } from '../../helpers/format'
import VariantDropdown from '../VariantDropdown'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import { useProductVisibleToCustomer, useCustomerGroupProductPrice } from './ProductListing/productHooks'
import { useRouter } from 'next/router'

export default function ProductHero ({ data, page }) {
  const classes = useStyles()
  const setHeaderColours = useSetAtom(headerColoursAtom)
  const [headerFadeIn] = useAtom(headerFadeInAtom)
  const [quantity, setQuantity] = useState(1)
  const setCurrentlyOpen = useSetAtom(headerCurrentlyOpenAtom)
  const isVisible = useProductVisibleToCustomer(page)
  const scroller = ScrollSmoother.get()
  const setGiftMessageDialogOpen = useSetAtom(giftMessageDialogOpenAtom)

  const { title, image, bgColor, fgColor, hoverBgColor, blocks, additionalText, giftMessageHero } = data

  // This will update the product price with the wholesale price
  const productPage = useCustomerGroupProductPrice(page)
  const { options, variants } = productPage.product

  const dropdowns = useMemo(() => {
    return options.map(option => ({
      ...option,
      values: option.option_values.map(x => ({ text: x.label, value: x.label }))
    }))
  }, [options])

  const [selectedOptions, setSelectedOptions] = useState(
    reduce(dropdowns, (result, o) => {
      result[o.id] = o?.option_values.find(x => x.is_default)?.label
      return result
    }, {})
  )

  const selectedVariant = useMemo(() => {
    if (isEmpty(selectedOptions)) return variants[0]
    const results = reduce(selectedOptions, (reducedVariants, label, optionId) => {
      const ret = reducedVariants.filter(v => v.option_values.find(o => o.label === label))
      return ret
    }, variants)
    return results[0]
  }, [selectedOptions, variants])

  const soldOut = selectedVariant?.inventory_level < 1
  const price = selectedVariant ? formatCurrency(selectedVariant?.calculated_price) : 'Unavailable'
  const addItem = useAddItem()

  const sectionRef = useScrollTrigger(
    () => {
      if (!sectionRef.current) return null
      return {
        trigger: sectionRef.current,
        start: () => `top ${window.innerWidth < theme.breakpoints.values.md ? 58 : 94}px`,
        end: () => `bottom ${window.innerWidth < theme.breakpoints.values.md ? 58 : 40}px`,
        scrub: false,
        onLeave: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.3,
            ease: 'expo.out'
          })
        },
        onEnterBack: () => {
          setHeaderColours({
            foreground: fgColor,
            background: bgColor,
            duration: 0.3,
            ease: 'expo.out'
          })
        },
        onLeaveBack: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.3,
            ease: 'expo.out'
          })
        }
      }
    },
    (tl) => {},
    () => {
      setHeaderColours({
        background: theme.colors.pageTheme.default.background,
        foreground: '#000000',
        duration: 0.3,
        ease: 'expo.out'
      })
    }
  )

  useEffect(() => {
    if (!headerFadeIn || !sectionRef.current) return

    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      }
    })

    tl.add(() => {
      setHeaderColours({
        foreground: fgColor,
        background: bgColor,
        duration: 0.3,
        ease: 'power2.inOut'
      })
    })
    tl.to(sectionRef.current, { opacity: 1, duration: 0.3 })
    tl.from(sectionRef.current.querySelectorAll(`.${classes.saleLabel},.${classes.title}, .${classes.pillWrap}, .${classes.block}, .${classes.variantWrap}`), {
      y: 20,
      opacity: 0,
      stagger: 0.1,
      clearProps: 'transform'
    }, '-=0.2')
    tl.from(sectionRef.current.querySelectorAll(`.${classes.image}`), {
      opacity: 0,
      scale: 0.9,
      clearProps: 'transform'
    }, '-=0.8')
  }, [headerFadeIn])

  const addToCart = async () => {
    if (!selectedVariant) return
    await addItem({
      productId: selectedVariant.product_id,
      variantId: selectedVariant.id,
      quantity
    })

    fbq('track', 'AddToCart', {
      content_ids: [selectedVariant.sku, selectedVariant.product_id],
      content_name: title,
      currency: 'AUD',
      value: selectedVariant.calculated_price
    })

    setCurrentlyOpen('cart')
  }

  const scrollToAnchor = () => {
    const element = document.querySelector('#message-dialog')
    if (element && scroller) {
      gsap.to(scroller, {
        scrollTop: scroller.offset(element, 'top 25%'),
        duration: 0.6,
        ease: 'power2.inOut',
        onComplete: () => setGiftMessageDialogOpen(true)
      })
    }
  }

  const isSale = !!selectedVariant?.sale_price
  const { isPreview } = useRouter()

  // We do not what to show this to anyone who is not a
  if (!isVisible && !isPreview) {
    return null
  }

  return (
    <Section tag='section' className={classes.section} topPadding={false} fullWidth insetGutter grid ref={sectionRef} style={{ '--background': bgColor, '--foreground': fgColor }}>
      <div className={classes.left}>
        {isSale && <span className={cn(classes.saleLabel)}>on sale</span>}
        <h2 className={classes.title}>{title}</h2>
        <div className={cn(classes.pillWrap, giftMessageHero && classes.halfWidth)}>
          <div className={classes.addToCartPill}>
            {!giftMessageHero && <QuantitySelector quantity={quantity} setQuantity={setQuantity} />}
            <div className={giftMessageHero && classes.marginLeft}>{isSale ? <span><span className={classes.oldPrice}>{formatCurrency(selectedVariant.sale_price)}</span> {price}</span> : price}</div>
            <button className={cn(classes.addToCartBtn, 'reset-button')} onClick={giftMessageHero ? scrollToAnchor : addToCart} disabled={!selectedVariant || soldOut}>{!soldOut ? 'Add To Cart' : 'Out Of Stock'}</button>
          </div>
          <div className={classes.pillHover} style={{ backgroundColor: hoverBgColor }}>
            <Marquee text={additionalText} />
          </div>
        </div>

        {dropdowns && dropdowns.length >= 1 &&
          <div>
            {dropdowns.map((option, i) => {
              return (
                <div key={option.name} className={classes.variantWrap}>
                  <span className={classes.variantLabel}>{option.display_name}</span>
                  <VariantDropdown
                    z={i}
                    onChange={(e) => setSelectedOptions({ ...selectedOptions, [option.id]: e.target.value })}
                    value={selectedOptions[option.id]}
                    className={classes.variantSelect}
                    options={option.values}
                  />
                </div>
              )
            })}
          </div>}
      </div>

      <ResponsiveImage className={classes.image} image={image} contain showPreview={false} containAspect={436 / 666} mobileContainAspect={339 / 422} />

      <div className={classes.right}>
        {blocks?.map((block, i) => block.copy && (
          <div key={block._key} className={classes.block}>
            <h4>{block.title}</h4>
            <div><RichContent content={block.copy} /></div>
          </div>
        ))}
      </div>

      <Section tag='div' grid className={classes.meta} topPadding={false} noBottomMargin>
        <div className={classes.line} />
        <div>{price}</div>
        <div>{additionalText}</div>
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    minHeight: '100vh',
    background: 'var(--background)',
    color: 'var(--foreground)',
    marginTop: -131,
    paddingTop: '30vh',
    position: 'relative',
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      paddingTop: '131px !important'
    }
  },
  left: {
    gridColumn: '1 / span 3',
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      gridRow: '2',
      marginBottom: 0
    }
  },
  right: {
    gridColumn: '-4 / -1',
    paddingBottom: 36 + theme.grid.md.gutter,
    [theme.breakpoints.down('md')]: {
      gridRow: '3',
      gridColumn: '1 / -1',
      marginTop: 56 - theme.grid.sm.gutter,
      paddingBottom: 0
    }
  },
  title: {
    fontSize: 48,
    lineHeight: '90%',
    textTransform: 'uppercase',
    overflowWrap: 'break-word',
    maxWidth: '30rem'
  },
  block: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.grid.md.gutter,
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      gridGap: theme.grid.sm.gutter
    },
    '& h4': {
      fontFamily: theme.fonts.monoFace,
      fontWeight: theme.fonts.monoFontWeight,
      textTransform: 'uppercase',
      fontSize: 10
    },
    '& div': {
      gridColumn: 'span 2'
    }
  },
  marginLeft: {
    marginLeft: 16,
  },
  halfWidth: {
    width: '65%'
  },
  meta: {
    position: 'absolute',
    bottom: theme.grid.md.margin,
    left: 0,
    right: 0,
    zIndex: 40,
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    '& div': {
      gridColumn: 'span 6',
      [theme.breakpoints.down('md')]: {
        gridColumn: 'span 2'
      }
    },
    '& div:last-child': {
      textAlign: 'right'
    },
    [theme.breakpoints.down('md')]: {
      position: 'static',
      gridColumn: '1 / -1',
      marginLeft: '0 !important',
      marginRight: '0 !important',
      marginBottom: theme.grid.sm.margin
    }
  },
  saleLabel: {
    display: 'block',
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    marginBottom: 10
  },
  line: {
    gridColumn: '1 / -1 !important',
    width: '100%',
    height: 1,
    background: 'var(--foreground)'
  },

  addToCartPill: {
    background: 'var(--foreground)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '25rem',
    height: 43,
    borderRadius: 41,
    paddingRight: 32,
    paddingLeft: 8,
    paddingTop: 2,
    // paddingTop: 16 - 2,
    textTransform: 'uppercase',
    fontSize: 12,
    marginTop: 72
  },
  addToCartBtn: {
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '0.06em',
    minHeight: 42,
    whiteSpace: 'nowrap',
    flexShrink: 0,
    marginLeft: theme.spacing(1)
  },
  image: {
    position: 'absolute !important',
    top: '55vh',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${theme.span(1.6)} !important`,
    height: '87vh !important',
    [theme.breakpoints.down('md')]: {
      position: 'relative !important',
      gridRow: '1  !important',
      gridColumn: '1 / -1 !important',
      top: '0 !important',
      left: '0 !important',
      transform: 'none !important',
      height: 'auto !important',
      width: '100% !important'
    }
  },

  pillWrap: {
    position: 'relative',
    marginBottom: 40,
    [theme.breakpoints.up('md')]: {
      '&:hover div:last-child': {
        height: 68
      }
    },
    zIndex: 8
  },
  pillHover: {
    position: 'absolute',
    top: 1,
    left: 0,
    width: '100%',
    maxWidth: '25rem',
    height: 42,
    borderRadius: 22,
    overflow: 'hidden',
    paddingTop: 34,
    background: 'black',
    zIndex: -1,
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
    transition: 'height 0.25s cubic-bezier(.22,.57,.12,.96)',
    willChange: 'height'
  },
  oldPrice: {
    opacity: 0.5,
    marginRight: 4,
    textDecoration: 'line-through'
  },
  variantWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 39,
    fontSize: 12
  },
  variantLabel: {
    width: 80,
    textTransform: 'uppercase',
    opacity: 0.5
  },
  variantSelect: {
    flex: 1,
    zIndex: 2
  }
})
