import { useEffect, useRef, useState } from 'react'
import createUseStyles from '../lib/createUseStyles'
import useHlsPlayer from '../hooks/useHlsPlayer'
import cn from 'classnames'
import { theme } from '../styles/theme'
import { primaryInput } from 'detect-it'

import { showVideoCursorAtom } from './Header/ProductCursor'
import { useSetAtom } from 'jotai'

function FullVideo({ url }) {
  const classes = useStyles()
  const fullRef = useRef()
  const [fullHlsRef] = useHlsPlayer(fullRef, url)

  return <video ref={fullRef} controls loop playsInline autoPlay />
}

export default function VimeoPlayer({
  video,
  autoplay = true,
  background,
  vimeoData,
  previewData,
  style,
  poster,
  inline = false
}) {
  const classes = useStyles()
  const [isPreview, setPreview] = useState(true)
  const ref = useRef()

  const setCursor = useSetAtom(showVideoCursorAtom)

  const play = () => {
    setPreview(!isPreview)
  }

  useEffect(() => {
    // if(isPreview && !previewData) {
    //   ref.current.currentTime = video.previewRange?.start || 95
    // }
    if (!isPreview && !inline) {
      setCursor(false)
    }
  }, [isPreview])

  // const handleProgress = useCallback((e) => {
  //   if(isPreview && !previewData) {
  //     const { currentTime } = e.target
  //     if(currentTime > (video.previewRange?.end || 102) && isPreview) {
  //       ref.current.currentTime = video.previewRange?.start || 95
  //     }
  //   }
  // }, [isPreview])

  // useEffect(() => {
  //   if (previewData) return
  //   localEl = ref.current
  //   ref.current.addEventListener('timeupdate', handleProgress)
  //   return () => {
  //     localEl.removeEventListener('timeupdate', handleProgress)
  //   }
  // }, [isPreview])

  const getHlsLink = data => {
    return data.files.filter(x => x.quality === 'hls')[0].link
  }

  const [hlsRef] = useHlsPlayer(ref, getHlsLink(previewData))

  const eventListeners = {
    onMouseEnter:
      primaryInput === 'touch'
        ? null
        : () => {
            setCursor(true)
          },
    onMouseLeave:
      primaryInput === 'touch'
        ? null
        : () => {
            setCursor(false)
          }
  }

  return (
    <div className={cn(classes.root, 'vimeo-player')} style={style}>
      <div className={cn(classes.vimeoWrapper, 'vimeo-player-aspect')}>
        {isPreview && !inline && (
          <button
            className={cn(
              classes.playBtn,
              'reset-button',
              !isPreview && classes.hide
            )}
            onClick={play}
            {...eventListeners}
            title="Play Video"
          >
            <div className={classes.cursor}>PLAY</div>
          </button>
        )}
        {isPreview && !inline && (
          <video
            ref={ref}
            controls={!isPreview}
            loop
            autoPlay
            muted={isPreview}
            playsInline
          />
        )}
        {!isPreview && !inline && <FullVideo url={getHlsLink(vimeoData)} />}
        {inline && (
          <video ref={ref} controls={false} loop autoPlay muted playsInline />
        )}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    gridColumn: '1 / -1'
  },
  vimeoWrapper: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%',
    gridColumn: '1 / -1',
    '& video': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%'
    }
  },
  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
  playBtn: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    textAlign: 'center',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: 18,
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
    // [theme.breakpoints.down('md')]: {
    //   fontSize: 24,
    // }
  },
  hide: {
    opacity: 0
  },
  cursor: {
    // position: 'absolute',
    // left: '50%',
    width: 130,
    height: 130,
    borderRadius: '50%',
    background: 'black',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    letterSpacing: '0.04em',
    mixBlendMode: 'difference',
    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 100,
      fontSize: 16
    },
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    }
  }
})
