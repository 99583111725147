import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import cn from 'classnames'

export default function Input ({ as = 'input', error, className, ...props }) {
  const classes = useStyles()

  const Component = as
  return (
    <Component className={cn(className, classes.input, { error })} {...props} />
  )
}

const useStyles = createUseStyles({
  input: {
    color: theme.colors.background,
    border: 'none',
    borderRadius: 41,
    fontSize: 12,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2) + 2,
    paddingBottom: theme.spacing(2) - 2,
    marginBottom: theme.spacing(2),
    '&::placeholder': {
      color: theme.colors.background,
      textTransform: 'uppercase'
    },
    '&.error': {
      backgroundColor: theme.colors.error
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  }
})
