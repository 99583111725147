import cn from 'classnames'
import PropTypes from 'prop-types'
import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import ResponsiveImage from '../../ResponsiveImage'
import { teamPropType } from './TeamListing.types'

export const TeamListingItem = ({ className, teamMember, onClick, square }) => {
  const styles = useStyles()
  const Wrapper = typeof onClick === 'function' ? 'button' : 'div'
  return (
    <Wrapper
      className={cn(styles.root, className)}
      onClick={onClick ? () => onClick(teamMember) : null}
    >
      <div className={styles.image}>
        <ResponsiveImage aspect={square ? 434 / 434 : 450 / 610} image={teamMember.headshot} />
      </div>
      <span className={styles.name}>{teamMember.name}</span>
      <span className={styles.jobTitle}>{teamMember.jobTitle}</span>
    </Wrapper>
  )
}

const useStyles = createUseStyles({
  root: {
    padding: 0,
    margin: 0,
    border: '0 none',
    background: 'transparent'
  },
  image: {
    marginBottom: theme.spacing(2)
  },
  name: {
    display: 'block',
    lineHeight: 1
  },
  jobTitle: {
    color: 'rgba(0,0,0,0.5)'
  }
})

TeamListingItem.propTypes = {
  className: PropTypes.string,
  teamMember: teamPropType.isRequired,
  onClick: PropTypes.func
}
