import cn from 'classnames'
import gsap from 'gsap'
import get from 'lodash/get'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import BlurHash from './BlurHash'
import transform, { MOBILE_WIDTHS } from './transform'

export const getObjectPosition = (hotspot, crop) => {
  if (!hotspot) return '50% 50%'
  if (!crop) return `${hotspot.x * 100}% ${hotspot.y * 100}%`
  return `${
    ((hotspot.x - crop.left) / (1 - (crop.left + crop.right))) * 100
  }% ${((hotspot.y - crop.top) / (1 - (crop.top + crop.bottom))) * 100}%`
}

const Source = ({ media, sizes, lazy }) => {
  const srcName = lazy ? 'data-srcset' : 'srcSet'
  const srcset = key => sizes.map(item => `${item[key]} ${item.width}w`).join()
  return <source media={media} {...{ [srcName]: srcset('url') }} suppressHydrationWarning />
}

const Picture = forwardRef(
  (
    {
      pictureClassName,
      style,
      className,
      alt,
      sizes,
      mobileSizes,
      onLoad,
      loading = 'lazy',
      contain
    },
    ref
  ) => {
    const lazy = loading === 'lazy'

    return (
      <picture className={pictureClassName}>
        {mobileSizes && sizes && (
          <>
            <Source
              sizes={mobileSizes}
              media={`(max-width: ${theme.breakpoints.values.md - 1}px)`}
              lazy={lazy}
            />
            <Source
              sizes={sizes}
              media={`(min-width: ${theme.breakpoints.values.md}px)`}
              lazy={lazy}
            />
          </>
        )}
        {!mobileSizes && sizes && <Source sizes={sizes} lazy={lazy} />}
        <img
          ref={ref}
          data-sizes='auto'
          alt={alt}
          className={cn(
            (sizes && lazy && 'lazyload') || (!lazy && 'lazyloaded'),
            className,
            contain && 'img-contain'
          )}
          onLoad={onLoad}
          style={style}
        />
      </picture>
    )
  }
)

Picture.displayName = 'Picture'

const Preview = forwardRef(
  ({ className, aspect, alt, lqip, blurHash }, ref) => {
    if (blurHash) {
      return <BlurHash blurHash={blurHash} />
    }
    return <img ref={ref} alt={alt} src={lqip} className={className} />
  }
)

Preview.displayName = 'Preview'

const ResponsiveImage = forwardRef(
  (
    {
      className,
      classNames = {},
      aspect,
      mobileAspect,
      children,
      image,
      mobileImage,
      onLoad,
      loading = 'lazy',
      showPreview = true,
      style,
      altFallback,
      fadeIn = true,
      fadeInDelay = 0,
      fadeInDuration = 1,
      speed,
      lag,
      contain = false,
      containAspect,
      inset = false
    },
    ref
  ) => {
    const data = useMemo(() => transform(image, aspect), [image, aspect])
    const mobileData = useMemo(
      () =>
        mobileImage
          ? transform(mobileImage, mobileAspect, MOBILE_WIDTHS)
          : null,
      [mobileImage, mobileAspect]
    )
    const { hotspot, crop } = {
      hotspot: get(image, ['hotspot']),
      crop: get(image, ['crop'])
    }
    const classes = useStyles()
    const localsRef = useRef({ fadingIn: false })

    const pictureRef = useRef()

    const lqip = get(image, ['asset', 'metadata', 'lqip'])
    const blurHash = get(image, ['asset', 'metadata', 'blurHash'])
    const preview = lqip || blurHash

    const handleLoad = useCallback(() => {
      if (onLoad) onLoad()
      if (fadeIn && localsRef.current.fadingIn === false) {
        localsRef.current.fadingIn = true
        gsap.to(pictureRef.current, {
          opacity: 1,
          duration: fadeInDuration,
          ease: 'power2.inOut',
          delay: fadeInDelay,
          onComplete: () => {
            localsRef.current.fadingIn = false
          }
        })
      }
    }, [onLoad, fadeIn, fadeInDelay, fadeInDuration])

    useEffect(() => {
      if (pictureRef.current && pictureRef.current.complete) {
        handleLoad()
      }
    }, [handleLoad])

    const imageClassName = cn(classes.image, classNames.image)

    const sizes = get(data, ['sizes'])
    const mobileSizes = get(mobileData, ['sizes'])
    const alt =
      get(image, ['alt']) || get(image, ['asset', 'altText']) || altFallback

    const resolvedMobileAspect =
      mobileAspect || get(mobileData, ['sourceAspect'])
    const resolvedAspect = aspect || data?.sourceAspect

    // Needed to add theses silly div's to set the aspect for mobile and desktop as there is no way
    // to have a dynamic prop in a media query and have the css ssr
    return (
      <div
        className={cn('res-image', className, classes.imageContainer, { inset })}
        ref={ref}
        style={style}
        data-speed={speed}
        data-lag={lag}
      >
        {!resolvedMobileAspect && containAspect && (
          <div style={{ paddingTop: `${100 / containAspect}%` }} />
        )}
        {!resolvedMobileAspect && !containAspect && (
          <div style={{ paddingTop: `${100 / resolvedAspect}%` }} />
        )}
        {resolvedMobileAspect && (
          <>
            <div
              className={classes.desktopAspect}
              style={{ paddingTop: `${100 / resolvedAspect}%` }}
            />
            <div
              className={classes.mobileAspect}
              style={{ paddingTop: `${100 / resolvedMobileAspect}%` }}
            />
          </>
        )}
        {showPreview && preview && (
          <Preview
            lqip={lqip}
            aspect={resolvedAspect}
            blurHash={blurHash}
            alt={alt}
            className={imageClassName}
          />
        )}
        <Picture
          className={cn(imageClassName, { fadeIn })}
          alt={alt}
          sizes={sizes}
          mobileSizes={mobileSizes}
          onLoad={handleLoad}
          ref={pictureRef}
          loading={loading}
          style={{ objectPosition: getObjectPosition(hotspot, crop) }}
          contain={contain}
        />
        {children}
      </div>
    )
  }
)

ResponsiveImage.displayName = 'ResponsiveImage'

export default ResponsiveImage

const useStyles = createUseStyles({
  imageContainer: {
    position: 'relative',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    '&.inset': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&.fadeIn': {
      opacity: 0
    },
    '&.img-contain': {
      objectFit: 'contain'
    }
  },
  desktopAspect: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileAspect: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
})
