import cn from 'classnames'
import PropTypes from 'prop-types'
import createUseStyles from '../../../lib/createUseStyles'
import { roundedButton } from '../../../styles/buttons'
import { theme } from '../../../styles/theme'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import Section from '../../Section'
import { teamPropType } from './TeamListing.types'

export const TeamListingHighlight = ({ className, teamMember, onClick }) => {
  const styles = useStyles()
  return (
    <Section grid noBottomMargin className={cn(styles.root, className)}>
      <div className={styles.details}>
        <span className={styles.highlight}>Highlight</span>
        <h2 className={styles.name}>{teamMember.name}</h2>
        <p className={styles.bio}>{teamMember.shortBio}</p>
        <Link onClick={() => onClick(teamMember)} className={styles.button}>
          Read bio
        </Link>
      </div>
      <div className={styles.image}>
        <ResponsiveImage image={teamMember.headshot} />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  root: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      display: 'none!important' // Hide until we have a mobile design
    }
  },
  details: {
    gridColumn: '2 / span 4',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  highlight: {
    marginBottom: theme.spacing(4),
    fontSize: theme.fonts.rem(11), // 10px in design but renders too small
    textTransform: 'uppercase',
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight
  },
  name: {
    textTransform: 'uppercase'
  },
  bio: {
    fontSize: theme.fonts.rem(14),
    marginBottom: theme.spacing(3)
  },
  button: roundedButton,
  image: {
    gridColumn: '7 / -1'
  }
})

TeamListingHighlight.propTypes = {
  className: PropTypes.string,
  teamMember: teamPropType.isRequired
}
