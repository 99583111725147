import { useKeenSlider } from 'keen-slider/react'
import { defer, maxBy } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import useScrollTrigger from '../../hooks/useScrollTrigger'
import { theme } from '../../styles/theme'
import createUseStyles from '../../lib/createUseStyles'
import useWindowResize from '../../hooks/useWindowResize'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import NumberTicker from '../NumberTicker'
import cn from 'classnames'
import gsap from 'gsap'

export const CountCarousel = ({ data }) => {
  const { countCarouselSlides } = data
  const controlsRef = useRef()
  const [progress, setProgress] = useState(0)
  const classes = useStyles()
  const sectionRef = useScrollTrigger(
    () => {
      return {
        trigger: sectionRef.current,
        start: () => 'top 0',
        end: () =>
          `bottom ${
            window.innerWidth < theme.breakpoints.values.md ? 350 : 350
          }px`,
        scrub: false
      }
    },
    tl => {}
  )

  const positionControls = useCallback(() => {
    const textComponents = sectionRef.current.querySelectorAll(
      `.${classes.text}`
    )
    const largestComponent = maxBy(
      textComponents,
      x => x.getBoundingClientRect().height
    )
    const isMobile = window.innerWidth < theme.breakpoints.values.md
    const spacing = isMobile ? theme.spacing(1) : theme.spacing(4)
    !isMobile &&
      (controlsRef.current.style.bottom = `${
        largestComponent.getBoundingClientRect().height + spacing
      }px`)
  }, [])
  useWindowResize(useDebouncedCallback(positionControls, 200, []), true)
  const [refCallback, slider] = useKeenSlider({
    slides: {
      perView: 1
    },
    slideChanged(slider) {
      setProgress(slider.track.details.rel)
    },
    created: () => {
      defer(positionControls)
    }
  })

  const sliderBack = () => {
    if (progress === 0) return
    slider.current.prev()
  }
  const sliderNext = () => {
    slider.current.next()
  }
  useEffect(() => {
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      }
    })
    tl.to(sectionRef.current, { opacity: 1, duration: 0.3 })
    tl.from(sectionRef.current.querySelectorAll(`.${classes.slide} > *`), {
      y: '110%',
      stagger: 0.1
    })
    tl.from(
      sectionRef.current.querySelectorAll(`.${classes.controls}`),
      {
        opacity: 0
      },
      '-=0.6'
    )
  }, [])

  return (
    <div className={classes.root} ref={sectionRef}>
      <div ref={refCallback} className={cn('keen-slider', classes.slider)}>
        {countCarouselSlides &&
          countCarouselSlides.map((count, i) => (
            <div
              className={cn('keen-slider__slide', classes.slide)}
              key={count._key}
            >
              <div className={classes.count}>
                {count.showDollarSign && '$'}
                <NumberTicker
                  fromCount={count.startNumber}
                  toCount={count.endNumber}
                  animate={progress === i}
                  duration={i === 0 ? 2.5 : 1.5}
                />
              </div>
              <div className={classes.text}>{count.text}</div>
            </div>
          ))}
        <div className={classes.controls} ref={controlsRef}>
          <button
            className={cn(
              'reset-button',
              classes.arrowBtn,
              progress === 0 && classes.btnDisabled
            )}
            disabled={progress === 0}
            onClick={sliderBack}
          >
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.55353 0.686707L9.50528 1.63846L3.514 7.62974H19.3036L19.3036 8.97171L3.514 8.97171L9.50528 14.963L8.55353 15.9147L0.939511 8.30072L8.55353 0.686707Z"
                fill="black"
              />
            </svg>
          </button>
          <button
            className={cn(
              'reset-button',
              classes.arrowBtn,
              progress === countCarouselSlides.length - 1 && classes.btnDisabled
            )}
            disabled={progress === countCarouselSlides.length - 1}
            onClick={sliderNext}
          >
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8879 0.686707L9.93613 1.63846L15.9274 7.62974H0.13784L0.13784 8.97171L15.9274 8.97171L9.93613 14.963L10.8879 15.9147L18.5019 8.30072L10.8879 0.686707Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    paddingBottom: 131,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      paddingTop: 80
    },
    opacity: 0
  },
  grid: {
    padding: `0 ${theme.grid.md.gutter}px`,
    rowGap: '8px !important'
  },
  controls: {
    position: 'absolute',
    right: theme.grid.md.gutter,
    bottom: 'auto',
    top: 0,
    [theme.breakpoints.up('md')]: {
      bottom: '6.5em',
      top: 'auto'
    }
  },
  arrowBtn: {
    marginLeft: 22
  },
  btnDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  slider: {
    gridColumn: '1/ -1',
    width: '100%',
    paddingTop: 80,
    position: 'relative',
    marginBottom: theme.grid.md.gutter,
    [theme.breakpoints.down('md')]: {
      marginBottom: 80,
      paddingTop: 30
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 110
    },
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing !important'
    }
  },
  slide: {
    padding: `0 ${theme.grid.md.gutter}px`,
    flex: '1',
    flexShrink: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'column'
    }
  },
  count: {
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: '10vw',
    letterSpacing: '-0.1em',
    lineHeight: '1em',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'end',
      fontSize: '18vw',
      width: '100%',
      marginBottom: 5
    }
  },
  text: {
    maxWidth: 540,
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: 40,
    textAlign: 'right',
    textTransform: 'uppercase',
    lineHeight: '1em',
    marginLeft: theme.spacing(4),
    paddingBottom: '1.2vw',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-end',
      paddingBottom: '0',
      fontSize: 24
    }
  }
})
