import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import RichContent from '../RichContent'
import { ProductListingItem } from './ProductListing/ProductListingItem'
import gsap from 'gsap'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import cn from 'classnames'

function NewlineText ({ text }) {
  return text.split('\n').map((str, i) => <p key={i}>{str}</p>)
}

export default function FeaturedProducts ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  // const [isAnimated, setAnimated] = useState(false)

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.product}`), {
      start: '30% bottom',
      onEnter: item => gsap.to(item, {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1
      })
    })
  }, []))

  const { title, copy, products } = data

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      <h2 className={classes.title}><NewlineText text={title} /></h2>
      <div className={classes.copy}>
        <RichContent content={copy} />
      </div>
      {products && products.map(product => (
        <ProductListingItem className={cn(classes.product)} product={product} key={data._key + product._id} link={product} />
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    paddingBottom: 128,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 40
    }
  },
  title: {
    fontSize: 56,
    gridColumn: 'span 7',
    textTransform: 'uppercase',
    lineHeight: '1em',
    maxWidth: '14em',
    '& p': {
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
      textAlign: 'center',
      gridColumn: '1/-1',
      maxWidth: 'unset'
    }
  },
  copy: {
    gridColumn: ' 9 / span 3',
    maxWidth: '27em',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/-1',
      maxWidth: 'unset',
      textAlign: 'center'
    }
  },
  product: {
    marginTop: 128,
    gridColumn: 'span 6',
    opacity: 0,
    transform: 'translateY(40px)',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 2',
      marginTop: 80
    }
  }
})
