import PropTypes from 'prop-types'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import { builder } from '../ResponsiveImage/transform'
import Section from '../Section'

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const Partners = ({ data: { partners = [], title, intro } }) => {
  const styles = useStyles()
  return (
    <>
      <Section grid noBottomMargin className={styles.details}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.intro}>{intro}</p>
      </Section>
      <Section grid className={styles.list}>
        {partners.map((partner, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.imageContainer}>
              {partner.logo && (
                <img
                  className={styles.image}
                  src={builder.image(partner.logo).height(90).url()}
                />
              )}
            </div>
            <span className={styles.name}>{partner.name}</span>
            {partner.amount && <span className={styles.amount}>
              ${numberWithCommas(partner.amount)}
            </span>}
          </div>
        ))}
      </Section>
    </>
  )
}

const useStyles = createUseStyles({
  details: {
    marginBottom: theme.spacing(4),
    '& > *': {
      gridColumn: '1 / -1'
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
      '& > *': {
        gridColumn: 'span 6'
      }
    }
  },
  title: {
    textTransform: 'uppercase',
  },
  intro: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 4'
    }
  },
  list: {
    rowGap: '0!important'
  },
  item: {
    gridColumn: '1 / -1',
    height: 75,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid rgba(0,0,0,0.2)',
    '&:last-child': {
      borderBottom: '1px solid rgba(0,0,0,0.2)'
    },
    // Select the last row of the grid in desktop (where 2 = number of columns)
    '&:nth-child(2n+1):nth-last-child(-n+2), &:nth-child(2n+1):nth-last-child(-n+2) ~ &':
      {
        [theme.breakpoints.up('md')]: {
          borderBottom: '1px solid rgba(0,0,0,0.2)'
        }
      },
    [theme.breakpoints.up('md')]: {
      gridColumn: 'span 6'
    }
  },
  imageContainer: {
    position: 'relative',
    width: 120,
    height: 45,
    [theme.breakpoints.up('md')]: {
      width: 150,
      marginRight: theme.spacing(10)
    }
  },
  image: {
    height: 45,
    objectFit: 'contain',
    objectPosition: 'left',
  },
  name: {
    flexGrow: 1,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  amount: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(5)
    }
  }
})

const partnerPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  logo: PropTypes.object
})

Partners.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    intro: PropTypes.string,
    partners: PropTypes.arrayOf(partnerPropType).isRequired
  }).isRequired
}
