import * as React from 'react'

export const ChevronDown = props => (
  <svg
    width={38}
    fill='none'
    stroke='var(--foreground)'
    viewBox='0 0 38 23'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='m2.832 3.429 16 14.769 16-14.77' strokeWidth={7} />
  </svg>
)
