import { atom } from 'jotai'
import { theme } from '../../styles/theme'

const menuOpenPrimitive = atom(false)

export const headerFadeInAtom = atom(false)
export const isFooterVisibleAtom = atom(false)
export const donateOpenAtom = atom(false)
export const headerCurrentlyOpenAtom = atom('none')

export const headerColoursAtom = atom({
  background: 'transparent',
  foreground: theme.colors.pageTheme.default.foreground,
  duration: 0,
  ease: 'expo.out'
})

export const NAV_VIEWS = {
  Nav: 'nav',
  SignIn: 'sign-in',
  SignUp: 'sign-up',
  Donate: 'donate'
}

export const navViewAtom = atom(NAV_VIEWS.Nav)

export const menuOpenAtom = atom(
  (get) => get(menuOpenPrimitive),
  (get, set, update) => {
    if (!update) {
      set(navViewAtom, NAV_VIEWS.Nav)
    }
    set(menuOpenPrimitive, update)
  }
)
