import { useEffect, useRef, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import Logo from './Logo'
import Link from '../Link'
import cn from 'classnames'
import gsap from 'gsap'
import useWindowResize from '../../hooks/useWindowResize'
import useCart from '@bigcommerce/storefront-data-hooks/cart/use-cart'
import ProductCursor from './ProductCursor'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import Nav from './Nav'
import Cart, { cartOpenAtom } from '../Cart'
import {
  headerColoursAtom,
  headerCurrentlyOpenAtom,
  headerFadeInAtom,
  isFooterVisibleAtom,
  menuOpenAtom,
  donateOpenAtom,
  navViewAtom,
  NAV_VIEWS
} from './headerState'
import { primaryInput } from 'detect-it'
import handleEsc from '../../helpers/handleEsc'

import { getScrollbarWidth } from '../../helpers/scrollbar'
import useScrollPosition from '../../hooks/useScrollPosition'
import { settingsAtom } from '../../state/content'

export default function Header() {
  const settings = useAtomValue(settingsAtom)
  const classes = useStyles()
  const headerRef = useRef()
  const bgRef = useRef()
  const logoRef = useRef()
  const localsRef = useRef({})
  const listenersRef = useRef({})
  const linkRefs = useRef([])

  const [isOpen, setIsOpen] = useAtom(menuOpenAtom)
  const [isCartOpen, setCartOpen] = useAtom(cartOpenAtom)
  const setDonateOpen = useSetAtom(donateOpenAtom)
  const setView = useSetAtom(navViewAtom)

  const cartHook = useCart()
  const isCartEmpty = cartHook.isEmpty

  const [currentlyOpen, setCurrentlyOpen] = useAtom(headerCurrentlyOpenAtom)

  useEffect(() => {
    switch (currentlyOpen) {
      case 'nav':
        setIsOpen(true)
        setCartOpen(false)
        break
      case 'cart':
        setIsOpen(false)
        setCartOpen(true)
        break
      default:
        setIsOpen(false)
        setCartOpen(false)
        break
    }
  }, [currentlyOpen])

  const [headerFadeIn, setHeaderFadeInAtom] = useAtom(headerFadeInAtom)

  const [headerColours] = useAtom(headerColoursAtom)
  const [isFooterVisible] = useAtom(isFooterVisibleAtom)
  const [isScrolled, setScrolled] = useState(false)
  useScrollPosition(
    useCallback(({ y }) => {
      setScrolled(y > 100)
    }, [])
  )

  useWindowResize(
    useCallback(() => {
      localsRef.current.windowWidth = window.innerWidth
    }, []),
    true
  )

  const router = useRouter()
  useEffect(() => {
    const handleRouteStart = () => {
      setCurrentlyOpen('none')
      setDonateOpen(false)
    }

    router.events.on('routeChangeStart', handleRouteStart)
    return () => {
      router.events.off('routeChangeStart', handleRouteStart)
    }
  }, [router.events]) // eslint-disable-line

  useEffect(() => {
    if (router.query.hasOwnProperty('openCart')) {
      setCurrentlyOpen('cart')
    } else if (router.query.hasOwnProperty('openDonate')) {
      setTimeout(() => {
        setCurrentlyOpen('nav')
        setView(NAV_VIEWS.Donate)
      }, 600)
    } else if (router.query.hasOwnProperty('openSignin')) {
      setTimeout(() => {
        setCurrentlyOpen('nav')
        setView(NAV_VIEWS.SignIn)
      }, 600)
    } else if (router.query.hasOwnProperty('openSignup')) {
      setTimeout(() => {
        setCurrentlyOpen('nav')
        setView(NAV_VIEWS.SignUp)
      }, 600)
    } else if (router.query.hasOwnProperty('newsletter')) {
      ScrollSmoother.get().scrollTo('#newsletter', false, 'top 200px')
    } else if (
      router.query.hasOwnProperty('words-of-goodness') ||
      router.query.hasOwnProperty('a-good-word') ||
      router.query.hasOwnProperty('message') ||
      router.query.hasOwnProperty('messages')
    ) {
      ScrollSmoother.get().scrollTo('#message', false, 'top 200px')
    }
  }, [router.query])

  useEffect(() => {
    gsap.set(headerRef.current.querySelectorAll(`.${classes.buttonsBg}`), {
      background: 'var(--background)'
    })
    gsap.set(headerRef.current.querySelectorAll(`.${classes.links}`), {
      y: -10
    })
    gsap.to(
      headerRef.current.querySelectorAll(
        `.${classes.logoWrapper}, .${classes.links}, .${classes.buttons}`
      ),
      {
        y: 0,
        opacity: 1,
        ease: 'power3.out',
        duration: 0.8,
        stagger: 0.1,
        delay: 0.1
      }
    )
    setTimeout(() => setHeaderFadeInAtom(true), 500)
  }, [])

  useEffect(() => {
    if (!headerFadeIn) return
    gsap.to(headerRef.current.querySelectorAll(`.${classes.links}`), {
      y: isScrolled ? -10 : 0,
      autoAlpha: isScrolled ? 0 : 1,
      ease: 'expo.out',
      duration: 0.3
    })

    const amount = localsRef.current.windowWidth > 1000 ? '-103' : '-88'
    gsap.to(logoRef.current.children, {
      y: isScrolled && !isFooterVisible ? amount : 0,
      ease: 'expo.out',
      duration: 0.3
    })
  }, [isScrolled, isFooterVisible])

  useEffect(() => {
    const open = isOpen || isCartOpen

    gsap.to(headerRef.current, {
      '--background': open ? 'black' : headerColours.background,
      duration: 0.3,
      ease: 'expo.out'
    })

    gsap.to(headerRef.current, {
      '--foreground': open
        ? theme.colors.pageTheme.default.background
        : headerColours.foreground,
      duration: 0.3,
      ease: open ? 'expo.out' : 'expo.in'
    })

    gsap.to(bgRef.current, {
      clipPath: open ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 100% 0%)',
      duration: 0.5,
      ease: 'expo.out'
    })
  }, [isOpen, isCartOpen])

  useEffect(() => {
    gsap.to(headerRef.current, {
      '--background': headerColours.background,
      '--foreground': headerColours.foreground,
      duration: headerColours.duration,
      ease: headerColours.ease
    })
  }, [headerColours])

  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth()
    if (scrollbarWidth) {
      gsap.set(headerRef.current, {
        width: `calc(100vw - ${scrollbarWidth}px)`
      })
    }
  }, [])

  useEffect(() => {
    document.addEventListener(
      'keydown',
      e => handleEsc(e, setCurrentlyOpen),
      false
    )
    return () => [
      document.removeEventListener(
        'keydown',
        e => handleEsc(e, setCurrentlyOpen),
        false
      )
    ]
  }, [])

  const onMouseEnter = useCallback(e => {
    const el = linkRefs.current[e.target.getAttribute('data-idx')]

    if (listenersRef.current.onEnter) listenersRef.current.onEnter.kill()

    const tl = gsap.timeline()
    tl.to(el, {
      scaleX: 1,
      transformOrigin: '0% 100%',
      duration: 0.3,
      ease: 'power2.out',
      force3D: false
    })
    listenersRef.current.onEnter = tl
  }, [])

  const onMouseLeave = useCallback(e => {
    const el = linkRefs.current

    if (listenersRef.current.onLeave) listenersRef.current.onLeave.kill()

    const tl = gsap.timeline()
    tl.to(el, {
      scaleX: 0,
      transformOrigin: '100% 0%',
      duration: 0.35,
      ease: 'power2.out',
      force3D: false
    })
    listenersRef.current.onLeave = tl
  }, [])

  const eventListeners = {
    onMouseLeave: primaryInput === 'touch' ? null : onMouseLeave,
    onMouseEnter: primaryInput === 'touch' ? null : onMouseEnter
  }

  const openDonate = () => {
    setCurrentlyOpen('nav')
    setView(NAV_VIEWS.Donate)
  }

  return (
    <>
      <Section
        tag="header"
        noBottomMargin
        className={cn(classes.header)}
        fullWidth
        ref={headerRef}
      >
        <Link to="/">
          <span className="srOnly">Two Good Co.</span>
          <div className={classes.logoWrapper} ref={logoRef}>
            <Logo />
            <svg
              width="62"
              height="63"
              viewBox="0 0 62 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.xxooLogo}
            >
              <path
                d="M46.8316 0C42.9105 0 39.1501 1.59236 36.3775 4.42677C33.605 7.26119 32.0474 11.1055 32.0474 15.114C32.0474 19.1224 33.605 22.9667 36.3775 25.8011C39.1501 28.6355 42.9105 30.2279 46.8316 30.2279C50.7526 30.2279 54.513 28.6355 57.2856 25.8011C60.0582 22.9667 61.6158 19.1224 61.6158 15.114C61.6158 11.1055 60.0582 7.26119 57.2856 4.42677C54.513 1.59236 50.7526 0 46.8316 0Z"
                fill="currentColor"
              />
              <path
                d="M14.793 0C10.872 0 7.11154 1.59236 4.33897 4.42677C1.56639 7.26119 0.00878906 11.1055 0.00878906 15.114C0.00878906 19.1224 1.56639 22.9667 4.33897 25.8011C7.11154 28.6355 10.872 30.2279 14.793 30.2279C18.714 30.2279 22.4744 28.6355 25.247 25.8011C28.0196 22.9667 29.5772 19.1224 29.5772 15.114C29.5772 11.1055 28.0196 7.26119 25.247 4.42677C22.4744 1.59236 18.714 0 14.793 0Z"
                fill="currentColor"
              />
              <path
                d="M36.9805 32.7422L32.0366 37.7963L56.6819 62.9913L61.6258 57.9372L36.9805 32.7422Z"
                fill="currentColor"
              />
              <path
                d="M56.6836 32.7485L32.0383 57.9435L36.9822 62.9976L61.6275 37.8026L56.6836 32.7485Z"
                fill="currentColor"
              />
              <path
                d="M4.94386 32.7464L0 37.8005L24.6453 62.9955L29.5892 57.9414L4.94386 32.7464Z"
                fill="currentColor"
              />
              <path
                d="M24.647 32.7527L0.00170898 57.9477L4.94557 63.0018L29.5909 37.8068L24.647 32.7527Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </Link>

        <div>
          <nav className={cn(classes.links)}>
            {settings?.headerLinks?.map((link, i) => {
              return (
                <span
                  key={link._key}
                  className={classes.linkWrapper}
                  {...eventListeners}
                >
                  <Link data-idx={i} link={link} />
                  <div
                    className={classes.line}
                    ref={el => (linkRefs.current[i] = el)}
                  />
                </span>
              )
            })}
            <span className={classes.linkWrapper} {...eventListeners}>
              <button
                data-idx={settings?.headerLinks?.length}
                className={cn('reset-button')}
                title="Donate"
                onClick={openDonate}
              >
                Donate
              </button>
              <div
                className={classes.line}
                ref={el => (linkRefs.current[settings?.headerLinks?.length] = el)}
              />
            </span>
          </nav>
          <nav className={cn(classes.buttons)}>
            <button
              className="reset-button"
              onClick={() => setCurrentlyOpen(!isOpen ? 'nav' : 'none')}
              title={isOpen ? 'Close Menu' : 'Open Menu'}
            >
              <div
                className={cn(
                  classes.hamburger,
                  isOpen && classes.hamburgerOpen
                )}
              >
                <span />
                <span />
              </div>
            </button>
            <button
              className="reset-button"
              onClick={() => setCurrentlyOpen(!isCartOpen ? 'cart' : 'none')}
              title={isCartOpen ? 'Close Cart' : 'Open Cart'}
            >
              <svg
                className={cn(
                  classes.cartIcon,
                  isCartOpen && classes.cartIconActive
                )}
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.9987 14.6668C6.36689 14.6668 6.66536 14.3684 6.66536 14.0002C6.66536 13.632 6.36689 13.3335 5.9987 13.3335C5.63051 13.3335 5.33203 13.632 5.33203 14.0002C5.33203 14.3684 5.63051 14.6668 5.9987 14.6668Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.3346 14.6668C13.7028 14.6668 14.0013 14.3684 14.0013 14.0002C14.0013 13.632 13.7028 13.3335 13.3346 13.3335C12.9664 13.3335 12.668 13.632 12.668 14.0002C12.668 14.3684 12.9664 14.6668 13.3346 14.6668Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.667969 0.666504H3.33464L5.1213 9.59317C5.18226 9.9001 5.34924 10.1758 5.593 10.372C5.83676 10.5683 6.14177 10.6725 6.45464 10.6665H12.9346C13.2475 10.6725 13.5525 10.5683 13.7963 10.372C14.04 10.1758 14.207 9.9001 14.268 9.59317L15.3346 3.99984H4.0013"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 30.5L8.25 24.25M14.5 18L8.25 24.25M8.25 24.25L14.5 30.5L2 18"
                  stroke="currentColor"
                />
                <circle
                  className={cn(
                    !isCartEmpty && classes.cartIndicatorActive,
                    classes.cartIndicator
                  )}
                  cx="15.1653"
                  cy="2.83331"
                  r="2.83331"
                  fill="#9FF97F"
                />
              </svg>
            </button>
          </nav>
        </div>
        <Nav
          isOpen={isOpen}
          navItems={settings.menuLinks}
          settings={settings}
        />
        <div className={classes.buttonsBg} id="nav-background" />
        <Cart settings={settings} />
        <div
          className={cn(
            classes.bg,
            isCartEmpty && isCartOpen ? classes.transparent : null
          )}
          ref={bgRef}
        />
      </Section>
      {/* <button className={cn(classes.donateButton, 'reset-button')} onClick={() => openDonate()}>
        <span>donate — two good</span>
        <svg width='35' height='10' viewBox='0 0 35 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M25.3418 7.84214L18.5078 0.950675L17.1369 2.33311L23.9709 9.22457L25.3418 7.84214Z' fill='var(--foreground)' />
          <path d='M25.3435 2.33308L23.9726 0.950651L17.1387 7.84213L18.5096 9.22456L25.3435 2.33308Z' fill='var(--foreground)' />
          <path d='M34.2246 5.0847C34.2246 3.98828 33.7926 2.93677 33.0238 2.16148C32.255 1.3862 31.2123 0.950652 30.125 0.950651C29.0378 0.950651 27.995 1.3862 27.2262 2.16148C26.4574 2.93677 26.0255 3.98828 26.0255 5.0847C26.0255 6.18111 26.4574 7.23263 27.2262 8.00791C27.995 8.78319 29.0378 9.21874 30.125 9.21874C31.2123 9.21874 32.255 8.78319 33.0238 8.00791C33.7926 7.23263 34.2246 6.18111 34.2246 5.0847Z' fill='var(--foreground)' />
          <path d='M8.2542 7.84214L1.42026 0.950675L0.0493633 2.33311L6.88331 9.22457L8.2542 7.84214Z' fill='var(--foreground)' />
          <path d='M8.25582 2.33311L6.88493 0.950675L0.050974 7.84215L1.42187 9.22458L8.25582 2.33311Z' fill='var(--foreground)' />
          <path d='M17.137 5.08469C17.137 3.98828 16.705 2.93676 15.9362 2.16148C15.1674 1.3862 14.1247 0.950651 13.0374 0.950651C11.9502 0.95065 10.9074 1.3862 10.1386 2.16148C9.3698 2.93676 8.93788 3.98828 8.93788 5.08469C8.93788 6.18111 9.3698 7.23262 10.1386 8.00791C10.9074 8.78319 11.9502 9.21874 13.0374 9.21874C14.1247 9.21874 15.1674 8.78319 15.9362 8.00791C16.705 7.23262 17.137 6.18111 17.137 5.08469Z' fill='var(--foreground)' />
        </svg>
      </button> */}
      <ProductCursor />
    </>
  )
}

const useStyles = createUseStyles({
  header: {
    '--background': 'white',
    position: 'fixed',
    width: '100vw',
    top: 0,
    left: 0,
    background: 'transparent',
    display: 'flex',
    padding: theme.grid.sm.margin,
    zIndex: 1001,
    justifyContent: 'space-between',
    pointerEvents: 'none',

    '& > a, & > div:nth-child(2)': {
      zIndex: 2
    },

    '& > svg, & > div nav': {
      opacity: 0,
      transform: 'translateY(-10px)'
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.grid.md.margin
    },
    '& > svg': {
      justifySelf: 'flex-start'
    },
    '& > div': {
      display: 'flex'
    },
    '& nav': {
      textTransform: 'uppercase'
    },
    '& a': {
      textDecoration: 'none',
      color: 'var(--foreground)'
    }
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'black',
    zIndex: '-1',
    clipPath: 'inset(0% 0% 100% 0%)'
  },
  links: {
    fontSize: 12,
    pointerEvents: 'all',
    marginTop: 2,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    '& a, & button': {
      textTransform: 'uppercase',
      color: 'var(--foreground)',
      opacity: 1,
      transition: 'opacity 0.2s ease-out'
    },
    '& a:hover, & button:hover': {
      opacity: 0.5
    }
  },
  buttons: {
    // width: 206,
    width: 130,
    height: 36,
    padding: '10px 14px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -10,
    marginRight: -10,
    position: 'relative',
    pointerEvents: 'all',
    '& svg': {
      zIndex: '1000',
      color: 'var(--foreground)'
    },
    '& button': {
      width: 42,
      height: 42,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: -11,
      '&:first-child': {
        marginTop: -12,
        marginLeft: -11
      },
      '&:last-child': {
        marginRight: -13
      }
    }
  },
  buttonsBg: {
    position: 'fixed',
    borderRadius: 18,
    background: 'transparent',
    top: theme.grid.sm.margin - 10,
    right: theme.grid.sm.margin - 10,
    [theme.breakpoints.up('md')]: {
      top: theme.grid.md.margin - 10,
      right: theme.grid.md.margin - 10
    },
    // width: 206,
    width: 128,
    height: 36,
    zIndex: '-2'
  },
  hamburger: {
    width: 18,
    height: 6,
    position: 'relative',
    '& span': {
      position: 'absolute',
      width: '100%',
      height: 1,
      background: 'var(--foreground)',
      top: 0,
      left: 0,
      transition: 'top 0.2s ease-out, transform 0.2s ease-out'
    },
    '& span:last-child': {
      top: '100%'
    },
    '&:hover': {
      '& span': {
        transform: 'translateY(1px) scaleX(1.1)'
      },
      '& span:last-child': {
        transform: 'translateY(-1px) scaleX(1.1)'
      }
    }
  },
  hamburgerOpen: {
    '& span': {
      top: '50%',
      transform: 'translateY(0) scale(1) rotate(45deg) !important'
    },
    '& span:last-child': {
      top: '50%',
      transform: 'translateY(0) scale(1) rotate(-45deg) !important'
    }
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 106,
    height: 83,
    overflow: 'hidden',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'all',
    '& svg': {
      flexShrink: 0,
      marginBottom: 20
    },
    [theme.breakpoints.down('md')]: {
      height: 68,
      width: 'auto',
      '& svg:first-child': {
        height: 68,
        width: 'auto'
      }
    }
  },
  xxooLogo: {
    [theme.breakpoints.down('md')]: {
      height: 38,
      width: 37
    }
  },
  donateButton: {
    position: 'fixed',
    bottom: 14,
    left: 24,
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.headingFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    transform: 'rotate(-90deg)',
    transformOrigin: '0 100%',
    display: 'flex',
    alignItems: 'center',
    zIndex: '40',
    '& span': {
      marginTop: 1,
      marginRight: 10,
      color: 'var(--foreground)'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  cartIcon: {
    transition: 'transform 0.25s ease-out',
    '&:hover': {
      transform: 'rotate(-5deg)'
    },
    '& path': {
      transform: 'translateY(0)',
      transition: 'transform 0.25s ease-out'
    }
  },
  cartIconActive: {
    '& path, & circle': {
      transform: 'translateY(-100%)'
    }
  },
  cartIndicator: {
    transform: 'scale(0) translateY(0)',
    transformOrigin: '50% 50%',
    transition: 'transform 0.25s ease-out'
  },
  cartIndicatorActive: {
    transform: 'scale(1)'
  },
  transparent: {
    background: 'transparent'
  },
  linkWrapper: {
    marginRight: 64,
    position: 'relative',
    width: 'fit-content',
    button: {
      margin: 0
    }
  },
  line: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    height: '1px',
    background: 'var(--foreground)',
    opacity: 0.5,
    transform: 'scaleX(0)'
  }
})
