import useAddItem from '@bigcommerce/storefront-data-hooks/cart/use-add-item'
import cn from 'classnames'
import gsap from 'gsap'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import handleEsc from '../../helpers/handleEsc'
import useOnOutsideClick from '../../hooks/useOnOutsideClick'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import { headerCurrentlyOpenAtom } from '../Header/headerState'
import { giftMessageDialogOpenAtom } from './GiftMessage'

export default function SubmitMessage ({ data: { buttonText, nameFields, messageFields, characterLimit, subtitle }, pageData, giftMessage }) {
  const classes = useStyles()
  const ref = useRef()
  const outsideClickRef = useRef()
  const nameRef = useRef()
  const messageRef = useRef()

  const [isOpen, setOpen] = useState(false)
  const [label, setLabel] = useState(buttonText)
  const [giftMessageDialogOpen, setGiftMessageDialogOpen] = useAtom(giftMessageDialogOpenAtom)
  const addItem = useAddItem()
  const setCurrentlyOpen = useSetAtom(headerCurrentlyOpenAtom)

  const [name, setName] = useState('')
  const [message, setMessage] = useState('')

  const localsRef = useRef({})

  useEffect(() => {
    if (localsRef.current.tl) {
      localsRef.current.tl.kill()
    }
    const defaults = {
      duration: 0.6,
      ease: 'expo.out'
    }
    const tl = gsap.timeline({
      defaults
    })
    tl.to(ref.current.querySelector(`.${classes.container}`), {
      height: isOpen ? (giftMessage ? 430 : 360) : 60
    }, 0)
    tl.to(ref.current.querySelector(`.${classes.btn}`), {
      y: isOpen ? (giftMessage ? 430 : 360) - 48 - 12 : 0,
      background: isOpen ? '#222222' : 'black'
    }, 0)
    tl.to(ref.current.querySelectorAll(`.${classes.form} > *`), {
      y: isOpen ? 0 : 10,
      opacity: isOpen ? 1 : 0,
      duration: isOpen ? 0.6 : 0.3,
      ease: 'expo.out',
      stagger: isOpen ? 0.05 : 0,
      delay: isOpen ? 0 : 0
    }, 0)
    localsRef.current.tl = tl
  }, [isOpen])

  const handleOpen = (e) => {
    if (!isOpen && label !== 'Thank you!') {
      e.preventDefault()
      setOpen(true)
      setTimeout(() => {
        nameRef.current.focus()
      }, 100)
      return
    }
    if (name.trim() === '' || message.trim() === '' || !nameRef.current.checkValidity() || !messageRef.current.checkValidity()) {
      return
    }
    if (isOpen && label !== 'Thank you!' && label !== 'Submitting...') {
      if (giftMessage) {
        addToCart()
      } else {
        submit()
      }
    }
  }

  async function addToCart () {
    // There is only one variant on the Gift Message product so we can just grab it directly
    const product = pageData?.product?.variants?.[0]
    const modifierValues = [name, message]

    await addItem({
      productId: product?.product_id,
      variantId: product?.id,
      quantity: 1,
      optionSelections: pageData?.product?.modifiers?.map((modifier, i) => {
        return {
          option_id: modifier?.id,
          option_value: `- ${modifierValues[i]}`
        }
      })
    })

    fbq('track', 'AddToCart', {
      content_ids: [product?.sku, product?.product_id],
      content_name: product?.title,
      currency: 'AUD',
      value: product?.calculated_price
    })

    setCurrentlyOpen('cart')
  }

  async function submit () {
    setLabel('Submitting...')
    setOpen(false)
    setGiftMessageDialogOpen(false)

    try {
      const response = await window.fetch('/api/submit-message', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          message
        })
      })
      const data = await response.json()

      if (response.ok) {
        setOpen(false)
        setGiftMessageDialogOpen(false)
        setLabel('Thank you!')
      } else {
        setOpen(false)
        setGiftMessageDialogOpen(false)
        setLabel(data.status)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (giftMessageDialogOpen) {
      setOpen(true)
      setTimeout(() => {
        nameRef.current.focus()
      }, 100)
    }
  }, [giftMessageDialogOpen])

  useOnOutsideClick(outsideClickRef, useCallback(() => {
    setOpen(false)
    setGiftMessageDialogOpen(false)
  }, []), true)

  useEffect(() => {
    document.addEventListener('keydown', (e) => handleEsc(e, setOpen), false)
    return () => [document.removeEventListener('keydown', (e) => handleEsc(e, setOpen), false)]
  }, [])

  return (
    <div className={classes.root} id='message-dialog' ref={ref}>
      <div className={classes.wrapper} ref={outsideClickRef}>
        <form className={classes.container} onSubmit={(e) => e.preventDefault()}>
          <input
            type='submit'
            className={cn('reset-button', classes.btn, label === 'Thank you!' && classes.cursorPointer)}
            onClick={handleOpen}
            value={label}
            title={label}
          />

          <div className={classes.form}>
            <div className={classes.formLabel}>{nameFields?.nameTitle}</div>
            <input
              ref={nameRef}
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classes.input}
              type='text'
              placeholder={nameFields?.namePlaceholder}
            />
            <div className={classes.formLabel}>
              <span>{messageFields?.messageTitle}</span>
              <span>{`${characterLimit} characters max`}</span>
            </div>
            <textarea
              ref={messageRef}
              required
              minLength='5'
              maxLength={characterLimit}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={cn(classes.input, classes.textArea)}
              type='textarea'
              placeholder={messageFields?.messagePlaceholder}
            />
          </div>
        </form>
      </div>
      <p className={classes.copy}>{subtitle}</p>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    gridColumn: '1 / -1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: 366 - theme.section.md.marginBottom
    }
  },
  copy: {
    fontSize: 16,
    lineHeight: '130%',
    letterSpacing: '0.01em',
    maxWidth: '18em',
    textAlign: 'center'
  },
  wrapper: {
    width: 328,
    height: 60,
    position: 'relative',
    marginBottom: 32,
    zIndex: 99
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 328,
    height: 60,
    padding: 24,
    paddingTop: 24 - 1,
    paddingBottom: 24 + 1,
    borderRadius: 32,
    background: 'black',
    color: 'white',
    overflow: 'hidden'
  },
  btn: {
    height: 60,
    textTransform: 'uppercase',
    fontWeight: '600',
    letterSpacing: '0.06em',
    fontSize: 12,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: 101,
    whiteSpace: 'pre-wrap',
    lineHeight: '1em',
    padding: '0 8px',
    transition: 'color 0.2s ease-out',
    '&:hover': {
      color: 'rgba(255,255,255,0.7)'
    }
  },
  form: {
    width: '100%',
    height: 390 - 24,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 4
  },
  formLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: '0.06em',
    color: 'currentColor',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12
  },
  input: {
    background: 'transparent',
    border: 'none',
    width: '100%',
    marginBottom: 32,
    fontSize: 14,
    borderRadius: 0,
    padding: 0,
    '&::selection': {
      background: 'white',
      color: 'black'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  textArea: {
    flex: '1',
    minWidth: '100%',
    maxWidth: '100%',
    marginBottom: 0,
    font: 'inherit',
    color: 'inherit'
  },
  cursorPointer: {
    cursor: 'initial !important',
    color: 'inherit !important'
  }
})
