import { useEffect, useState, useMemo, useCallback } from 'react'
import createUseStyles from '../lib/createUseStyles'
import cn from 'classnames'
import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { theme } from '../styles/theme'

export default function IngredientsList ({ title, ingredients, numberOfItemsToShow }) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => {
    setIsOpen(value => !value)
  }, [])

  useEffect(() => {
    const scroller = ScrollSmoother.get()
    if (scroller) {
      scroller.refresh()
      ScrollTrigger.refresh()
    }
  }, [isOpen])

  const ingredientsToShow = useMemo(() => {
    if (!numberOfItemsToShow || isOpen) return ingredients
    return ingredients?.slice(0, numberOfItemsToShow)
  }, [numberOfItemsToShow, isOpen])

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {title}
      </div>
      <ul className={classes.list}>
        {ingredientsToShow?.map(ingredient => (
          <li key={ingredient}>{ingredient}</li>
        ))}
        {numberOfItemsToShow && (
          <li>
            <button
              className={cn(classes.titleButton, 'reset-button')}
              onClick={toggle}
            >
              {isOpen ? 'Show Less' : 'View All'}
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 20,
    marginBottom: 32
  },
  title: {
    fontSize: 12,
    gridColumn: '1 / span 1',
    fontFamily: theme.fonts.mono,
    fontWeight: theme.fonts.monoFontWeight,
    opacity: 0.5
  },
  list: {
    margin: 0,
    gridColumn: '2 / span 2',
    fontSize: 16,
    padding: 0,
    listStyle: 'none'
  },
  titleButton: {
    fontSize: 8,
    textTransform: 'uppercase',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: 17,
    padding: '4px 8px 1px',
    display: 'block',
    marginTop: 16,
    '&:hover': {
      backgroundColor: 'black',
      color: 'white'
    }
  }
})
