import * as React from 'react'

export const CloseIcon = props => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M.928 0 18 17.072l-.928.928L0 .928.928 0Z" />
    <path d="M17.072 0 18 .928.928 18 0 17.073 17.072 0Z" />
  </svg>
)
