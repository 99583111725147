export const unquotedBodyFace = 'Helvetica Now Text'
export const bodyFace = `"${unquotedBodyFace}"`
export const body = `${bodyFace}, "Lucida Sans", Tahoma, sans-serif`
export const bodyFontWeight = 400
export const bodyObj = {
  fontFamily: body,
  fontWeight: bodyFontWeight
}

export const unquotedHeadingFace = 'Futura Now Headline'
export const headingFace = `"${unquotedHeadingFace}"`
export const heading = `${headingFace}, "Lucida Sans", Tahoma, sans-serif`
export const headingFontWeight = 500
export const headerObj = {
  fontFamily: heading,
  fontWeight: headingFontWeight
}

export const unquotedMonoFace = 'ABC Social Mono'
export const monoFace = `"${unquotedMonoFace}"`
export const mono = `${monoFace}, "Lucida Sans", Tahoma, nonospaced`
export const monoFontWeight = 500
export const monoObj = {
  fontFamily: mono,
  fontWeight: monoFontWeight
}

export const rem = fontSize => `${fontSize / 16}rem`

export const preload = [
  { src: '/fonts/HelveticaNowText-Regular.woff2' },
  { src: '/fonts/Futura-Bold.woff2' },
  { src: '/fonts/ABCSocialMono-Regular.woff2' }
]

export const faces = [
  {
    fontFamily: bodyFace,
    src: "url('/fonts/HelveticaNowText-Regular.woff2') format('woff2')",
    fontWeight: bodyFontWeight,
    fontStyle: 'normal'
  },
  {
    fontFamily: headingFace,
    src: "url('/fonts/Futura-Bold.woff2') format('woff2')",
    fontWeight: headingFontWeight,
    fontStyle: 'normal'
  },
  {
    fontFamily: monoFace,
    src: "url('/fonts/ABCSocialMono-Regular.woff2') format('woff2')",
    fontWeight: monoFontWeight,
    fontStyle: 'normal'
  }
]
