import { useEffect, useCallback, useRef, useState } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import cn from 'classnames'
import gsap from 'gsap'
import 'keen-slider/keen-slider.min.css'
import RichContent from '../RichContent'
import ResponsiveImage from '../ResponsiveImage'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

export default function IngredientsSlice ({ data, pageData }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const noteRef = useRef()

  const { title, ingredients, ingredientsTitle, notesTitle } = data

  const [activeIngredient, setIngredient] = useState(ingredients[0])
  const [visibleIngrdient, setVisibleIngredient] = useState(activeIngredient)

  useScrollReadyListener(useCallback(() => {
    ScrollTrigger.batch(sectionRef.current.querySelectorAll(`.${classes.activeImage}`), {
      start: '30% bottom',
      onEnter: item => gsap.to(item, {
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'power2.inOut',
        stagger: 0.1
      })
    })
  }, []))

  useEffect(() => {
    if (activeIngredient === visibleIngrdient) return
    gsap.to(noteRef.current, {
      y: 10,
      opacity: 0,
      ease: 'power2.inOut',
      duration: 0.3,
      onComplete: () => {
        setVisibleIngredient(activeIngredient)
        gsap.fromTo(noteRef.current, {
          y: -10,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          ease: 'power2.inOut',
          duration: 0.3
        })
      }
    })
  }, [activeIngredient])

  const onChange = (e) => {
    setIngredient(ingredients.filter(x => x.name === e.target.value)[0])
  }

  return (
    <Section tag='section' className={classes.section} grid ref={sectionRef}>
      {title && (
        <h3 className={classes.title}>
          {title}
        </h3>
      )}
      <div className={classes.mobileSelector}>
        {ingredientsTitle && <div className={classes.columnTitle}>{ingredientsTitle}</div>}
        <select className={classes.mobileSelect} value={activeIngredient.name} onChange={onChange}>
          {ingredients.map((ingredient) => (
            <option key={ingredient.name} className={classes.selectOption} value={ingredient.name}>{ingredient.name}</option>
          ))}
        </select>
        <svg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1.4375 1.90234L10.4375 10.9023L19.4375 1.90234' stroke='black' strokeWidth='3' />
        </svg>
      </div>
      <div className={classes.imagesContainer}>
        {ingredients && ingredients.map(ingredient => {
          return (
            ingredient.image && (
              <ResponsiveImage
                key={ingredient.name}
                image={ingredient.image}
                className={cn(
                  classes.image,
                  activeIngredient.name === ingredient.name &&
                    classes.activeImage
                )}
                aspect={566 / 686}
                speed='0.9'
              >
                {ingredient.name}
              </ResponsiveImage>
            )
          )
        })}
      </div>
      <div className={classes.details}>
        <div className={classes.ingredeintsList}>
          <div className={classes.ingredeintsLine} />
          {ingredientsTitle && <div className={classes.columnTitle}>{ingredientsTitle}</div>}
          {ingredients && ingredients.map((ingredient, index) =>
            <button
              key={ingredient.name}
              className={cn('reset-button', classes.ingredientTitle, activeIngredient.name === ingredient.name && classes.ingredientActive, index === ingredients.length - 1 && classes.lastIngredient)}
              onClick={() => setIngredient(ingredient)}
            >
              {ingredient.name}
            </button>
          )}
        </div>
        <div className={classes.notes}>
          {notesTitle && <div className={classes.columnTitle}>{notesTitle}</div>}
          <div ref={noteRef}>
            <RichContent content={visibleIngrdient.notes} />
          </div>
        </div>
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    gridColumn: '1 / span 4',
    textTransform: 'uppercase',
    fontSize: 40,
    lineHeight: '1em',
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      gridColumn: '1 / -1'
    }
  },
  details: {
    gridRow: '2',
    gridColumn: '1 / span 8',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridGap: theme.grid.md.gutter,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      gridRow: '4',
      gridTemplateColumns: '1fr',
      gridGap: 24
    }
  },
  imagesContainer: {
    gridColumn: '9 / -1',
    gridRow: '2 / 6',
    position: 'relative',
    paddingTop: `${100 / (566 / 686)}%`,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      gridRow: '3',
      marginBottom: 8
    }
  },
  image: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity 0.6s ease-out',
    transform: 'scale(1.05)'
  },
  activeImage: {
    opacity: 1
  },
  ingredeintsList: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
    position: 'relative',
    paddingLeft: 24,
    gridColumn: '1 / span 3',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  notes: {
    gridColumn: '5 / span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
    }
  },
  ingredeintsLine: {
    position: 'absolute',
    height: '100%',
    width: 1,
    background: 'currentColor',
    opacity: 0.1,
    left: 0
  },
  ingredientTitle: {
    lineHeight: 1.1,
    marginBottom: 24,
    opacity: 0.3,
    transition: 'opacity 0.15s ease-out',
    display: 'block',
    width: 'unset',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    fontSize: 12
  },
  lastIngredient: {
    marginBottom: -8
  },
  ingredientActive: {
    opacity: '1 !important'
  },
  mobileSelector: {
    gridColumn: '1 / -1',
    gridRow: '2',
    '& select': {
      fontSize: 24,
      appearance: 'none',
      border: 'none',
      paddingRight: 34,
      background: 'transparent'
    },
    '& svg': {
      marginLeft: -20,
      pointerEvents: 'none',
      height: 9,
      width: 18,
      transform: 'translateY(-3px)'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  mobileSelect: {
    width: '95%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'black !important'
  },
  columnTitle: {
    marginBottom: theme.spacing(2),
    fontSize: 12,
    textTransform: 'uppercase',
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      marginBottom: 40
    }
  }
})
