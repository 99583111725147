import ScrollSmoother from 'gsap/dist/ScrollSmoother'
import { useCallback, useEffect, useRef, useState } from 'react'
import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import RichContent from './RichContent'
import { Plus } from './Plus'

const Item = ({ title, content, onToggle }) => {
  const classes = useStyles()
  const contentRef = useRef()
  const [open, setOpen] = useState()
  const handleClick = useCallback(() => {
    if (onToggle) onToggle(!open)
    setOpen(!open)
  }, [open])

  useEffect(() => {
    gsap.to(contentRef.current, {
      height: open ? 'auto' : 0,
      duration: 0.6,
      ease: 'expo.inOut',
      onComplete: () => {
        const scroller = ScrollSmoother.get()
        if (scroller) {
          scroller.refresh()
          ScrollTrigger.refresh()
        }
      }
    })
  }, [open])

  return (
    <div className={classes.item}>
      <button onClick={handleClick} className={classes.itemButton}>
        {title}
        <Plus open={open} className={classes.icon} />
      </button>
      <div ref={contentRef} className={classes.itemContent}>
        <RichContent content={content} className={classes.text} />
      </div>
    </div>
  )
}

const AccordionComponent = ({ items = [] }) => {
  return (
    <div>
      {items.map(({ title, text }, index) => (
        <Item
          key={index}
          title={title}
          content={text}
        />
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    padding: `0 ${theme.grid.sm.margin}px`
  },
  icon: {
    flexShrink: 0,
    flexGrow: 0,
    marginLeft: theme.spacing(2)
  },
  item: {
    backgroundColor: 'var(--background)',
    marginTop: -1,
    position: 'relative',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      opacity: 0.1,
      position: 'absolute',
      backgroundColor: theme.colors.border,
      left: 0,
      right: 0,
      height: 1
    },
    '&:before': {
      top: 0
    },
    '&:after': {
      bottom: 0
    }
  },
  itemButton: {
    fontSize: 16,
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    padding: `${theme.spacing(3)}px 0`,
    borderWidth: '0',
    backgroundColor: 'transparent'
  },
  itemContent: {
    overflow: 'hidden',
    height: 0,
    fontSize: 16
  },
  text: {
    padding: `0 0 ${theme.spacing(3)}px`,
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%'
    }
  }
})

export default AccordionComponent
