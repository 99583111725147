import { useCallback, useRef } from 'react'
import createUseStyles from '../lib/createUseStyles'
import Link from './Link'
import cn from 'classnames'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'
import { Spinner } from './Header/Spinner'

const RoundButton = ({ as = Link, className, link, dark = false, bigPadding = false, showArrow = true, showLoading = false, showBackArrow = false, showText = true, yellow = false, children }) => {
  const classes = useStyles()

  const ref = useRef()
  const localsRef = useRef({})

  const onMouseEnter = useCallback(() => {
    const el = ref.current.children

    if (localsRef.current.timeline) localsRef.current.timeline.kill()

    const tl = gsap.timeline()
    tl.fromTo(el, { xPercent: !showBackArrow ? -110 : 0 }, { xPercent: !showBackArrow ? 0 : -210, duration: 0.3, ease: 'power2.out', force3D: true })
    localsRef.current.timeline = tl
  }, [])

  const onMouseLeave = useCallback(() => {
    const el = ref.current.children

    if (localsRef.current.timeline) localsRef.current.timeline.kill()

    const tl = gsap.timeline()
    tl.to(el, { xPercent: !showBackArrow ? 0 : -210, duration: 0.35, ease: 'power2.out', force3D: true })
    localsRef.current.timeline = tl
  }, [])

  const eventListeners = {
    onMouseEnter: (primaryInput === 'touch' || (!showArrow && !showBackArrow)) ? null : onMouseEnter,
    onMouseLeave: (primaryInput === 'touch' || (!showArrow && !showBackArrow)) ? null : onMouseLeave
  }

  const Component = as
  const linkProps = (Component === Link) ? {
    showExternalIcon: false,
    showText
  } : {}

  return (
    <Component className={cn(className, classes.button, dark && classes.dark, yellow && classes.yellow, bigPadding && classes.xlPadding, showBackArrow && classes.backArrow)} link={link} {...eventListeners} {...linkProps}>
      {showBackArrow && (
        <svg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} className={classes.icon}>
          <path d='M4.7915 0.892273L1.23633 4.44765L4.7915 8.00282' stroke='currentColor' />
          <path d='M11.5552 0.892273L8 4.44765L11.5552 8.00282' stroke='currentColor' />
        </svg>
      )}
      {children}
      {showArrow && (
        <svg width='12' height='12' viewBox='0 0 11.5 13' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} className={classes.icon}>
          <path d='M6.78762 2.10256L6.22194 2.66825L9.78293 6.22924H0.398204V7.02685H9.78293L6.22194 10.5878L6.78762 11.1535L11.3131 6.62805L6.78762 2.10256Z' fill='currentColor' />
          <path d='M18.7882 2.10256L18.2225 2.66825L21.7835 6.22924H12.3988V7.02685H21.7835L18.2225 10.5878L18.7882 11.1535L23.3137 6.62805L18.7882 2.10256Z' fill='currentColor' />
        </svg>
      )}
      {showLoading && (
        <Spinner className={classes.icon} />
      )}
    </Component>
  )
}

export default RoundButton

const useStyles = createUseStyles({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px 16px 4px',
    borderRadius: 41,
    border: '1px solid var(--foreground)',
    textDecoration: 'none',
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    transition: 'background 0.1s cubic-bezier(0.1, 0, 0, 1), border-color 0.1s cubic-bezier(0.1, 0, 0, 1), color 0.1s cubic-bezier(0.1, 0, 0, 1)',
    '&:hover': {
      background: 'var(--foreground)',
      color: 'var(--background)'
    }
  },
  icon: {
    marginLeft: 16,
    flexShrink: 0,
    marginTop: -4
  },
  backArrow: {
    '& svg': {
      marginLeft: '0 !important',
      marginRight: '16px !important'
    }
  },
  dark: {
    background: 'var(--foreground)',
    color: 'var(--background)',
    '&:hover': {
      border: '1px solid var(--foreground)',
      background: 'var(--background)',
      color: 'var(--foreground)'
    }
  },
  yellow: {
    border: '1px solid #FFDD00',
    background: '#FFDD00',
    color: 'black',
    '&:hover': {
      border: '1px solid black',
      background: 'black',
      color: 'white'
    }
  },
  xlPadding: {
    padding: '16px 32px 14px',
    '& svg': {
      marginLeft: 24
    }
  }
})
