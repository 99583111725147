import { atom, useSetAtom } from 'jotai'
import { useRef } from 'react'
import useThrottleCallback from '../hooks/useThrottleCallback'
import useWindowResize from '../hooks/useWindowResize'
import { theme } from '../styles/theme'

export const isMobileAtom = atom(false)

export const useLayoutState = () => {
  const setIsMobile = useSetAtom(isMobileAtom)
  const previousValue = useRef(null)
  useWindowResize(useThrottleCallback(() => {
    const nextValue = window.innerWidth < theme.breakpoints.values.md
    if (previousValue.current !== nextValue) {
      previousValue.current = nextValue
      setIsMobile(previousValue.current)
    }
  }, 200), true, true)
}
