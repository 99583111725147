import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import Section from '../Section'
import cn from 'classnames'
import QuantitySelector from '../QuantitySelector'

import useUpdateItem from '@bigcommerce/storefront-data-hooks/cart/use-update-item'
import { formatCurrency } from '../../helpers/format'
import { useAtom } from 'jotai'
import { isMobileAtom } from '../../state/layout'

export default function LineItem ({ lineItem, className }) {
  const [isMobile] = useAtom(isMobileAtom)
  const classes = useStyles()
  const updateItem = useUpdateItem(lineItem)

  const updateQty = (quantity) => {
    updateItem({ quantity })
  }

  const removeItem = () => {
    updateItem({ quantity: 0 })
  }

  return (
    <Section key={lineItem.id} tag='div' grid noBottomMargin className={cn(className, classes.lineItem)} fullWidth>
      <span>{lineItem.name}</span>
      {/* <span className={classes.lineItemVariants}></span> */}
      <span className={classes.lineItemVariants}>{lineItem.options?.length ? lineItem.options?.map(option => option.value).join(', ') : ''}</span>
      <div className={classes.qtySelector}>
        <QuantitySelector quantity={lineItem.quantity} setQuantity={updateQty} min={1} />
        {isMobile && (
          <button className={cn('reset-button', classes.x)} onClick={removeItem} title='Remove Item'>
            <svg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M7.5 3L2.5 8' stroke='var(--foreground)' strokeWidth='0.833333' strokeLinecap='square' strokeLinejoin='round' />
              <path d='M2.5 3L7.5 8' stroke='var(--foreground)' strokeWidth='0.833333' strokeLinecap='square' strokeLinejoin='round' />
            </svg>
          </button>
        )}
      </div>
      <span className={classes.price}>
        <span>
          {formatCurrency(lineItem.list_price)}
        </span>
        {!isMobile && (
          <button className={cn('reset-button', classes.x)} onClick={removeItem} title='Remove Item'>
            <svg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M7.5 3L2.5 8' stroke='var(--foreground)' strokeWidth='0.833333' strokeLinecap='square' strokeLinejoin='round' />
              <path d='M2.5 3L7.5 8' stroke='var(--foreground)' strokeWidth='0.833333' strokeLinecap='square' strokeLinejoin='round' />
            </svg>
          </button>
        )}
      </span>
      <div className={cn(classes.line, 'line')} />
    </Section>
  )
}

const useStyles = createUseStyles({
  lineItem: {
    gridColumn: '1 / -1',
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    },
    '& > span': {
      gridColumn: 'span 3',
      [theme.breakpoints.down('md')]: {
        gridColumn: 'span 2'
      }
    }
  },
  line: {
    gridColumn: '1 / -1',
    height: 1,
    background: 'var(--foreground)',
    opacity: 0.2
  },
  qtySelector: {
    gridColumn: 'span 2',
    flexShrink: 0,
    marginLeft: -18,
    [theme.breakpoints.down('md')]: {
      gridColumn: '-3 / -1',
      gridRow: '1',
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: 0,
      '& > div': {
        marginTop: -8
      }
    }
  },
  lineItemVariants: {
    gridColumn: 'span 4 !important',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 2 !important'
    }
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    gridColumn: 'span 3 !important',
    // fontFamily: theme.fonts.monoFace,
    // fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: '0.05em',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 2 !important'
    }
  },
  x: {
    marginLeft: 32,
    height: 11 + 5 + 5,
    padding: 5,
    marginRight: -5,
    marginTop: -1,
    [theme.breakpoints.down('md')]: {
      marginTop: -3,
      paddingTop: 8
    },
    [theme.breakpoints.up('md')]: {
      transform: 'rotate(0deg)',
      '&:hover': {
        transition: 'transform 0.3s ease-out',
        transform: 'rotate(90deg)'
      }
    }
  },
  na: {
    opacity: 0.3,
    fontSize: 12
  }
})
