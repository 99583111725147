import createUseStyles from '../../../lib/createUseStyles'
import { theme } from '../../../styles/theme'
import cn from 'classnames'
import ErrorIcon from './error.svg'

export default function Errors ({ className, message }) {
  const classes = useStyles()
  if (!message) return null

  return (
    <div className={cn(className, classes.errors)}>
      <ErrorIcon className={classes.icon} />
      <p className={classes.message}>
        {message}
      </p>
    </div>
  )
}

const useStyles = createUseStyles({
  errors: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: 41,
    color: theme.colors.error,
    textAlign: 'center',
    fontSize: 10,
    backgroundColor: 'rgba(255,255,255,0.1)',
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    fontFamily: theme.fonts.mono,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    flexShrink: 0,
    flexGrow: 0
  },
  message: {
    margin: 0,
    marginLeft: theme.spacing(2),
    textAlign: 'left'
  }
})
