import createUseStyles from '../lib/createUseStyles'
import { theme } from '../styles/theme'
import RichContent from './RichContent'

export default function HelpLineMessage({ message }) {
  const styles = useStyles()
  return (
    <div className={styles.wrapper}>
      <RichContent content={message} className={styles.text} />
      <svg
        className={styles.svg}
        xmlns="http://www.w3.org/2000/svg"
        width="1050.45pt"
        height="222.74pt"
        viewBox="0 0 1050.45 222.74"
        version="1.1"
      >
        <defs>
          <clipPath id="clip1">
            <path d="M 95 191 L 128 191 L 128 222.738281 L 95 222.738281 Z M 95 191 " />
          </clipPath>
          <clipPath id="clip2">
            <path d="M 405 64 L 432 64 L 432 222.738281 L 405 222.738281 Z M 405 64 " />
          </clipPath>
          <clipPath id="clip3">
            <path d="M 704 64 L 731 64 L 731 222.738281 L 704 222.738281 Z M 704 64 " />
          </clipPath>
          <clipPath id="clip4">
            <path d="M 747 63 L 900 63 L 900 222.738281 L 747 222.738281 Z M 747 63 " />
          </clipPath>
          <clipPath id="clip5">
            <path d="M 656 6 L 683 6 L 683 222.738281 L 656 222.738281 Z M 656 6 " />
          </clipPath>
          <clipPath id="clip6">
            <path d="M 268 8 L 393 8 L 393 222.738281 L 268 222.738281 Z M 268 8 " />
          </clipPath>
          <clipPath id="clip7">
            <path d="M 499 69 L 644 69 L 644 222.738281 L 499 222.738281 Z M 499 69 " />
          </clipPath>
          <clipPath id="clip8">
            <path d="M 906 69 L 1050.449219 69 L 1050.449219 222.738281 L 906 222.738281 Z M 906 69 " />
          </clipPath>
          <clipPath id="clip9">
            <path d="M 450 8 L 545 8 L 545 222.738281 L 450 222.738281 Z M 450 8 " />
          </clipPath>
        </defs>
        <g id="surface1">
          <path d="M 105.792969 97.0625 C 97.808594 100.375 94.011719 109.535156 97.320313 117.527344 C 100.628906 125.519531 109.789063 129.316406 117.777344 126.007813 C 125.773438 122.691406 129.570313 113.535156 126.257813 105.546875 C 122.945313 97.550781 113.785156 93.753906 105.792969 97.0625 " />
          <path d="M 143.621094 147.453125 C 140.308594 139.457031 131.148438 135.671875 123.152344 138.976563 C 115.164063 142.285156 111.371094 151.449219 114.679688 159.445313 C 117.992188 167.433594 127.152344 171.230469 135.140625 167.917969 C 143.136719 164.609375 146.929688 155.445313 143.621094 147.453125 " />
          <path d="M 79.960938 75.617188 C 83.265625 83.605469 92.429688 87.398438 100.421875 84.09375 C 108.414063 80.785156 112.210938 71.617188 108.898438 63.625 C 105.585938 55.636719 96.421875 51.839844 88.433594 55.152344 C 80.441406 58.460938 76.644531 67.628906 79.960938 75.617188 " />
          <path d="M 75.863281 79.695313 C 67.863281 76.390625 58.707031 80.183594 55.402344 88.175781 C 52.089844 96.171875 55.882813 105.332031 63.867188 108.644531 C 71.863281 111.953125 81.027344 108.15625 84.339844 100.160156 C 87.644531 92.179688 83.855469 83.011719 75.863281 79.695313 " />
          <path d="M 159.6875 114.421875 C 151.695313 111.109375 142.53125 114.90625 139.222656 122.902344 C 135.917969 130.890625 139.707031 140.0625 147.695313 143.359375 C 155.691406 146.671875 164.855469 142.878906 168.160156 134.886719 C 171.472656 126.894531 167.679688 117.738281 159.6875 114.421875 " />
          <path d="M 108.894531 159.445313 C 112.207031 151.457031 108.40625 142.289063 100.414063 138.972656 C 92.425781 135.671875 83.261719 139.464844 79.953125 147.453125 C 76.644531 155.445313 80.441406 164.613281 88.429688 167.917969 C 96.417969 171.230469 105.578125 167.433594 108.894531 159.445313 " />
          <path d="M 114.675781 63.632813 C 111.367188 71.621094 115.160156 80.785156 123.152344 84.09375 C 131.144531 87.398438 140.304688 83.609375 143.617188 75.617188 C 146.929688 67.628906 143.132813 58.460938 135.140625 55.152344 C 127.148438 51.84375 117.988281 55.640625 114.675781 63.632813 " />
          <path d="M 105.378906 30.140625 C 113.371094 33.445313 122.527344 29.65625 125.84375 21.664063 C 129.15625 13.671875 125.359375 4.507813 117.367188 1.199219 C 109.375 -2.109375 100.214844 1.683594 96.902344 9.675781 C 93.59375 17.667969 97.386719 26.832031 105.378906 30.140625 " />
          <g clipPath="url(#clip1)" clipRule="nonzero">
            <path d="M 117.367188 192.605469 C 109.375 189.296875 100.214844 193.089844 96.902344 201.082031 C 93.59375 209.074219 97.386719 218.238281 105.378906 221.546875 C 113.371094 224.851563 122.527344 221.0625 125.84375 213.070313 C 129.15625 205.078125 125.359375 195.914063 117.367188 192.605469 " />
          </g>
          <path d="M 63.671875 188.710938 C 57.554688 194.824219 57.554688 204.746094 63.671875 210.855469 C 69.789063 216.976563 79.703125 216.972656 85.824219 210.855469 C 91.9375 204.742188 91.941406 194.824219 85.824219 188.710938 C 79.703125 182.597656 69.792969 182.59375 63.671875 188.710938 " />
          <path d="M 159.074219 34.023438 C 165.1875 27.90625 165.191406 17.984375 159.070313 11.875 C 152.953125 5.761719 143.039063 5.753906 136.921875 11.871094 C 130.804688 17.984375 130.804688 27.90625 136.921875 34.023438 C 143.035156 40.140625 152.953125 40.136719 159.074219 34.023438 " />
          <path d="M 38.617188 148 C 38.613281 139.347656 31.609375 132.339844 22.957031 132.335938 C 14.304688 132.332031 7.292969 139.347656 7.292969 148 C 7.292969 156.644531 14.304688 163.660156 22.957031 163.664063 C 31.605469 163.660156 38.621094 156.648438 38.617188 148 " />
          <path d="M 184.128906 74.753906 C 184.128906 83.398438 191.144531 90.410156 199.792969 90.414063 C 208.441406 90.414063 215.453125 83.402344 215.453125 74.753906 C 215.449219 66.101563 208.445313 59.089844 199.792969 59.085938 C 191.144531 59.085938 184.125 66.101563 184.128906 74.753906 " />
          <path d="M 34.03125 85.824219 C 40.140625 79.703125 40.148438 69.792969 34.03125 63.675781 C 27.914063 57.558594 17.992188 57.558594 11.882813 63.675781 C 5.761719 69.789063 5.765625 79.703125 11.882813 85.828125 C 17.996094 91.941406 27.914063 91.945313 34.03125 85.824219 " />
          <path d="M 188.714844 136.921875 C 182.601563 143.039063 182.601563 152.953125 188.71875 159.074219 C 194.832031 165.1875 204.753906 165.191406 210.863281 159.074219 C 216.976563 152.953125 216.984375 143.042969 210.867188 136.921875 C 204.753906 130.804688 194.832031 130.804688 188.714844 136.921875 " />
          <path d="M 74.75 38.617188 C 83.394531 38.609375 90.410156 31.605469 90.414063 22.953125 C 90.414063 14.304688 83.398438 7.289063 74.75 7.292969 C 66.097656 7.289063 59.089844 14.304688 59.085938 22.953125 C 59.085938 31.605469 66.097656 38.621094 74.75 38.617188 " />
          <path d="M 147.996094 184.128906 C 139.347656 184.128906 132.339844 191.140625 132.335938 199.792969 C 132.335938 208.441406 139.347656 215.453125 147.996094 215.453125 C 156.644531 215.445313 163.65625 208.445313 163.660156 199.792969 C 163.660156 191.140625 156.644531 184.125 147.996094 184.128906 " />
          <path d="M 173.042969 164.578125 C 165.054688 167.894531 161.257813 177.042969 164.5625 185.039063 C 167.875 193.03125 177.039063 196.828125 185.03125 193.515625 C 193.023438 190.207031 196.816406 181.046875 193.507813 173.050781 C 190.195313 165.0625 181.035156 161.265625 173.042969 164.578125 " />
          <path d="M 49.6875 58.171875 C 57.675781 54.863281 61.46875 45.699219 58.160156 37.703125 C 54.851563 29.71875 45.6875 25.917969 37.699219 29.234375 C 29.710938 32.546875 25.914063 41.699219 29.222656 49.695313 C 32.53125 57.6875 41.695313 61.484375 49.6875 58.171875 " />
          <path d="M 213.0625 96.902344 C 205.074219 93.59375 195.90625 97.390625 192.601563 105.378906 C 189.296875 113.375 193.082031 122.53125 201.074219 125.84375 C 209.066406 129.160156 218.230469 125.363281 221.539063 117.367188 C 224.851563 109.378906 221.054688 100.21875 213.0625 96.902344 " />
          <path d="M 30.132813 117.367188 C 33.445313 109.378906 29.648438 100.21875 21.65625 96.902344 C 13.667969 93.59375 4.5 97.390625 1.195313 105.378906 C -2.109375 113.375 1.675781 122.53125 9.667969 125.84375 C 17.660156 129.160156 26.828125 125.363281 30.132813 117.367188 " />
          <path d="M 164.574219 49.699219 C 167.886719 57.683594 177.042969 61.484375 185.035156 58.175781 C 193.03125 54.863281 196.824219 45.699219 193.511719 37.710938 C 190.203125 29.71875 181.042969 25.925781 173.046875 29.234375 C 165.058594 32.542969 161.261719 41.703125 164.574219 49.699219 " />
          <path d="M 58.167969 173.050781 C 54.859375 165.0625 45.699219 161.269531 37.703125 164.578125 C 29.714844 167.886719 25.917969 177.050781 29.226563 185.039063 C 32.542969 193.03125 41.695313 196.828125 49.691406 193.519531 C 57.683594 190.210938 61.480469 181.046875 58.167969 173.050781 " />
          <path d="M 63.875 114.429688 C 55.882813 117.738281 52.089844 126.894531 55.402344 134.890625 C 58.707031 142.878906 67.867188 146.671875 75.863281 143.367188 C 83.855469 140.0625 87.644531 130.894531 84.339844 122.902344 C 81.03125 114.910156 71.867188 111.109375 63.875 114.429688 " />
          <path d="M 168.160156 88.175781 C 164.855469 80.1875 155.691406 76.390625 147.699219 79.703125 C 139.707031 83.015625 135.917969 92.179688 139.222656 100.164063 C 142.53125 108.160156 151.695313 111.957031 159.6875 108.644531 C 167.679688 105.332031 171.476563 96.171875 168.160156 88.175781 " />
          <path d="M 418.183594 51.570313 C 425.515625 51.570313 431.464844 45.621094 431.464844 38.28125 L 431.464844 21.679688 C 431.460938 14.34375 425.515625 8.394531 418.183594 8.394531 C 410.839844 8.394531 404.882813 14.34375 404.882813 21.679688 L 404.882813 38.28125 C 404.882813 45.621094 410.839844 51.5625 418.183594 51.570313 " />
          <g clipPath="url(#clip2)" clipRule="nonzero">
            <path d="M 418.542969 64.523438 C 411.207031 64.523438 405.253906 70.472656 405.257813 77.8125 L 405.257813 209.453125 C 405.257813 216.785156 411.207031 222.738281 418.542969 222.738281 C 425.878906 222.738281 431.832031 216.789063 431.832031 209.453125 L 431.832031 77.8125 C 431.824219 70.472656 425.878906 64.523438 418.542969 64.523438 " />
          </g>
          <path d="M 717.183594 51.570313 C 724.515625 51.570313 730.464844 45.621094 730.464844 38.28125 L 730.464844 21.679688 C 730.460938 14.34375 724.515625 8.394531 717.183594 8.394531 C 709.839844 8.394531 703.882813 14.34375 703.882813 21.679688 L 703.882813 38.28125 C 703.882813 45.621094 709.839844 51.5625 717.183594 51.570313 " />
          <g clipPath="url(#clip3)" clipRule="nonzero">
            <path d="M 717.542969 64.523438 C 710.207031 64.523438 704.253906 70.472656 704.257813 77.8125 L 704.257813 209.453125 C 704.257813 216.785156 710.207031 222.738281 717.542969 222.738281 C 724.878906 222.738281 730.832031 216.789063 730.832031 209.453125 L 730.832031 77.8125 C 730.824219 70.472656 724.878906 64.523438 717.542969 64.523438 " />
          </g>
          <g clipPath="url(#clip4)" clipRule="nonzero">
            <path d="M 899.070313 209.621094 L 898.839844 138.59375 C 898.800781 96.875 865.035156 63.054688 823.316406 63.058594 C 781.609375 63.058594 747.796875 96.886719 747.796875 138.59375 L 747.847656 209.429688 L 747.828125 209.453125 C 747.828125 216.785156 753.777344 222.738281 761.117188 222.738281 C 768.453125 222.738281 774.402344 216.789063 774.402344 209.453125 L 774.402344 187.050781 L 774.417969 187.035156 L 774.417969 138.484375 C 774.417969 111.457031 796.332031 89.535156 823.359375 89.535156 C 850.394531 89.53125 872.273438 111.449219 872.300781 138.484375 L 872.425781 179.035156 L 872.449219 179.035156 L 872.449219 181.828125 L 872.453125 181.835938 L 872.492188 209.453125 C 872.492188 216.785156 878.445313 222.738281 885.78125 222.738281 C 893.0625 222.738281 898.976563 216.871094 899.0625 209.609375 L 899.070313 209.621094 " />
          </g>
          <g clipPath="url(#clip5)" clipRule="nonzero">
            <path d="M 682.875 209.453125 C 682.875 216.789063 676.921875 222.738281 669.585938 222.738281 C 662.25 222.738281 656.300781 216.785156 656.300781 209.453125 L 656.300781 20.121094 C 656.296875 12.777344 662.25 6.832031 669.585938 6.832031 C 676.921875 6.832031 682.867188 12.777344 682.875 20.121094 L 682.875 209.453125 " />
          </g>
          <g clipPath="url(#clip6)" clipRule="nonzero">
            <path d="M 379.035156 196.164063 L 331.757813 196.164063 C 311.28125 196.164063 295.414063 181.378906 295.402344 162.957031 L 295.402344 21.179688 C 295.398438 14.136719 290.183594 8.429688 282.847656 8.429688 C 275.511719 8.429688 268.828125 14.136719 268.828125 21.179688 L 268.828125 162.9375 C 268.828125 162.941406 268.828125 162.949219 268.828125 162.953125 L 268.828125 162.992188 C 268.863281 194.4375 296.6875 222.652344 331.601563 222.734375 L 331.601563 222.738281 L 331.71875 222.738281 C 331.730469 222.738281 331.742188 222.738281 331.757813 222.738281 L 379.035156 222.738281 C 386.371094 222.738281 392.320313 216.886719 392.320313 209.550781 C 392.316406 202.210938 386.371094 196.164063 379.035156 196.164063 " />
          </g>
          <g clipPath="url(#clip7)" clipRule="nonzero">
            <path d="M 576.34375 96.332031 C 585.289063 96.332031 593.675781 98.699219 600.933594 102.824219 L 532.964844 170.792969 C 528.839844 163.53125 526.472656 155.148438 526.472656 146.207031 C 526.464844 118.65625 548.808594 96.332031 576.34375 96.332031 Z M 637.09375 168.058594 C 630.824219 164.199219 622.628906 166.140625 618.777344 172.386719 L 618.773438 172.382813 C 609.988281 186.597656 594.277344 196.078125 576.34375 196.078125 C 567.402344 196.074219 559.015625 193.707031 551.757813 189.578125 L 623.558594 117.75 L 623.558594 117.746094 C 632.304688 108.984375 632.230469 94.730469 623.390625 85.890625 L 620.230469 83.527344 C 607.804688 74.808594 592.679688 69.671875 576.347656 69.671875 C 534.085938 69.671875 499.796875 103.925781 499.808594 146.207031 C 499.808594 188.441406 534.085938 222.726563 576.347656 222.738281 C 603.867188 222.738281 627.992188 208.191406 641.476563 186.371094 L 641.480469 186.375 C 645.324219 180.121094 643.363281 171.921875 637.09375 168.058594 " />
          </g>
          <g clipPath="url(#clip8)" clipRule="nonzero">
            <path d="M 983.34375 96.332031 C 992.289063 96.332031 1000.671875 98.699219 1007.929688 102.824219 L 939.964844 170.792969 C 935.839844 163.53125 933.472656 155.148438 933.472656 146.207031 C 933.464844 118.65625 955.808594 96.332031 983.34375 96.332031 Z M 1044.089844 168.058594 C 1037.828125 164.199219 1029.628906 166.140625 1025.78125 172.386719 L 1025.769531 172.382813 C 1016.988281 186.597656 1001.28125 196.078125 983.34375 196.078125 C 974.402344 196.074219 966.015625 193.707031 958.757813 189.578125 L 1030.558594 117.75 L 1030.558594 117.746094 C 1039.300781 108.984375 1039.230469 94.730469 1030.390625 85.890625 L 1027.230469 83.527344 C 1014.800781 74.808594 999.679688 69.671875 983.347656 69.671875 C 941.085938 69.671875 906.796875 103.925781 906.808594 146.207031 C 906.808594 188.441406 941.085938 222.726563 983.347656 222.738281 C 1010.871094 222.738281 1034.988281 208.191406 1048.480469 186.371094 L 1048.480469 186.375 C 1052.320313 180.121094 1050.359375 171.921875 1044.089844 168.058594 " />
          </g>
          <g clipPath="url(#clip9)" clipRule="nonzero">
            <path d="M 530.785156 8.273438 L 510.761719 8.273438 C 479.265625 8.28125 450.988281 36.1875 450.976563 71.179688 L 450.960938 71.179688 L 450.960938 208.84375 C 450.960938 216.511719 456.910156 222.738281 464.246094 222.738281 C 471.582031 222.738281 477.535156 216.511719 477.535156 208.84375 L 477.535156 98.523438 L 502.269531 98.523438 C 509.609375 98.523438 515.558594 92.570313 515.558594 85.234375 C 515.550781 77.894531 509.609375 71.945313 502.269531 71.945313 L 477.535156 71.945313 L 477.535156 71.203125 L 477.550781 71.203125 C 477.550781 50.722656 492.34375 34.851563 510.777344 34.851563 L 530.785156 34.851563 C 538.125 34.851563 544.074219 28.898438 544.074219 21.5625 C 544.066406 14.222656 538.125 8.273438 530.785156 8.273438 " />
          </g>
        </g>
      </svg>
    </div>
  )
}

const useStyles = createUseStyles({
  wrapper: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    margin: '20px auto 0',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
      textAlign: 'left',
      margin: '0px 0 0 auto'
    }
  },
  text: {
    textTransform: 'uppercase',
    lineHeight: 1.2,
    '> *': {
      fontSize: 12
    },
    '& a svg': {
      display: 'none'
    }
  },
  svg: {
    marginLeft: 20,
    width: 96,
    minWidth: 96,
    height: 24,
    margin: '0 auto',
    marginTop: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
      marginTop: 0
    },
    '& path': {
      fill: '#3E426F'
    }
  }
})
