import cn from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef, useMemo, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import { theme } from '../../styles/theme'
import { AnchorPill } from '../AnchorPill'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import useScrollTrigger from '../../hooks/useScrollTrigger'
import { useAtom } from 'jotai'
import { headerColoursAtom, headerFadeInAtom } from '../Header/headerState'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import { getSectionIdOrHref } from '../../helpers/getSectionIdOrHref'

function NewlineText({ text }) {
  return text.split('\n').map((str, i) => <p key={i}>{str}</p>)
}

export const AboutHero = ({
  data: { image, title: topTitle, bottomTitle, intro, anchorTitle },
  page
}) => {
  const styles = useStyles()
  const [, setHeaderColours] = useAtom(headerColoursAtom)
  const [headerFadeIn] = useAtom(headerFadeInAtom)

  const titleRef = useRef()
  const bottomTitleRef = useRef()

  const aboutSections =
    page.slices?.find(slice => slice._type === 'aboutSections')?.sections || []

  const anchors = useMemo(
    () =>
      aboutSections.map(({ title, _key }) => ({
        title,
        id: _key,
        href: getSectionIdOrHref(title)
      })),
    [aboutSections]
  )

  const sectionRef = useScrollTrigger(
    () => {
      return {
        trigger: sectionRef.current,
        start: () => 'top 0',
        end: () =>
          `bottom ${
            window.innerWidth < theme.breakpoints.values.md ? 500 : 350
          }px`,
        scrub: false,
        onLeave: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onEnterBack: () => {
          setHeaderColours({
            foreground: '#000000',
            background: 'rgba(252,191,156,1)',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onLeaveBack: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        }
      }
    },
    tl => {},
    () => {
      setHeaderColours({
        background: theme.colors.pageTheme.default.background,
        foreground: '#000000',
        duration: 0.3,
        ease: 'expo.out'
      })
    }
  )

  useEffect(() => {
    if (!headerFadeIn) return

    const titleSplit = new SplitText(titleRef.current, {
      type: 'words, lines',
      linesClass: 'split-parent'
    })
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      }
    })

    tl.add(() => {
      setHeaderColours({
        foreground: '#000000',
        background: 'rgba(252,191,156,1)',
        duration: 0.3,
        ease: 'power2.inOut'
      })
    })
    tl.to(sectionRef.current, { opacity: 1, duration: 0.3 })

    tl.set(titleRef.current, {
      opacity: 1
    })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => titleSplit.revert()
    })
    tl.from(
      sectionRef.current.querySelectorAll(
        `.${styles.anchors}, .${styles.intro}`
      ),
      {
        y: 20,
        opacity: 0,
        stagger: 0.1
      },
      '-=0.5'
    )
    tl.from(
      sectionRef.current.querySelectorAll(`.${styles.image} > div`),
      {
        opacity: 0,
        scale: 1.05
      },
      '-=0.5'
    )
  }, [headerFadeIn])

  useScrollReadyListener(
    useCallback(() => {
      const bottomTitleSplit = new SplitText(bottomTitleRef.current, {
        type: 'words, lines',
        linesClass: 'split-parent'
      })

      const tl = gsap.timeline({
        defaults: {
          duration: 0.6,
          ease: 'power2.inOut'
        },
        scrollTrigger: {
          trigger: bottomTitleRef.current,
          start: '30% bottom',
          once: true
        }
      })
      tl.set(bottomTitleRef.current, {
        opacity: 1
      })
      tl.from(bottomTitleSplit.words, {
        y: '120%',
        opacity: 0,
        stagger: 0.1,
        ease: 'expo.out',
        delay: 0.1,
        onComplete: () => bottomTitleSplit.revert()
      })
    }, [])
  )

  return (
    <div className={styles.root} ref={sectionRef}>
      <Section grid className={styles.grid}>
        <h1 className={cn(styles.title, styles.titleTop)} ref={titleRef}>
          <NewlineText text={topTitle} />
        </h1>
        <div className={styles.anchors}>
          <AnchorPill anchors={anchors} title={anchorTitle} />
        </div>
        <p className={styles.intro}>{intro}</p>
        <div className={styles.image}>
          <ResponsiveImage image={image} speed="0.9" />
        </div>
        <span
          className={cn('h', 'as-h1', styles.title, styles.titleBottom)}
          ref={bottomTitleRef}
        >
          <NewlineText text={bottomTitle} />
        </span>
      </Section>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    marginTop: -132,
    paddingTop: 131 + 82,
    opacity: 0,
    background:
      'linear-gradient(0deg, rgba(252,191,156,0) 0%, rgba(252,191,156,1) 47%, rgba(252,191,156,1) 100%)'
  },
  grid: {
    [theme.breakpoints.up('md')]: {
      rowGap: '0!important'
    }
  },
  title: {
    textTransform: 'uppercase',
    gridColumn: '1 / -1',
    margin: 0,
    lineHeight: '0.9em',
    letterSpacing: '-0.04em',
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'wrap',
      fontSize: 'calc((100vw - 48px) * 0.158)'
    },
    opacity: 0,
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1em',
      paddingTop: '0.1em'
    },
    '& p': {
      margin: 0
    }
  },
  titleTop: {
    [theme.breakpoints.up('md')]: {
      marginBottom: '-14vw',
      fontSize: 'calc((100vw - 48px) * 0.17)'
    }
  },
  titleBottom: {
    [theme.breakpoints.up('md')]: {
      marginTop: `calc(-12vw + ${theme.spacing(1)}px)`
    }
  },
  intro: {
    gridColumn: '1 / -1',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      gridColumn: '4 / span 3',
      marginTop: '14vw'
    }
  },
  anchors: {
    display: 'flex',
    alignItems: 'flex-start',
    gridColumn: '1 / -1',
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 3',
      marginTop: '14vw'
    },
    [theme.breakpoints.up('lg')]: {
      gridColumn: '1 / span 2'
    }
  },
  image: {
    gridColumn: '1 / -1',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      gridColumn: '8 / -1',
      marginTop: '-1em'
    }
  }
})

AboutHero.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    bottomTitle: PropTypes.string.isRequired,
    intro: PropTypes.string,
    image: PropTypes.object.isRequired,
    anchorTitle: PropTypes.string
  }).isRequired
}
