import { useEffect, useRef } from 'react'
import cn from 'classnames'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import { useAtom } from 'jotai'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import ResponsiveImage from '../ResponsiveImage'
import { headerFadeInAtom } from '../Header/headerState'
import RichContent from '../RichContent'
import RoundButton from '../RoundButton'

export default function ContactSlice({ data, page }) {
  const classes = useStyles()
  const sectionRef = useRef()
  const titleRef = useRef()

  const {
    title,
    image,
    workWithUsTitle,
    workWithUsCopy,
    workWithUsLink,
    getInTouchTitle,
    getInTouchLinks
  } = data

  const [headerFadeIn] = useAtom(headerFadeInAtom)

  useEffect(() => {
    if (!headerFadeIn) return

    const titleSplit = new SplitText(titleRef.current, {
      type: 'words, lines',
      linesClass: 'split-parent'
    })

    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.inOut',
        duration: 0.6
      }
    })

    tl.set(titleRef.current, {
      opacity: 1
    })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => titleSplit.revert()
    })

    tl.to(
      sectionRef.current.querySelectorAll(`.${classes.column} > *`),
      {
        y: 0,
        opacity: 1,
        stagger: 0.1
      },
      '-=0.8'
    )
    tl.to(
      sectionRef.current.querySelectorAll(`.${classes.image}`),
      {
        opacity: 1,
        scale: 1
      },
      '-=0.5'
    )
  }, [headerFadeIn])

  return (
    <Section tag="section" className={classes.section} grid ref={sectionRef}>
      <h1 className={cn(classes.title)} ref={titleRef}>
        {title}
      </h1>

      <div className={classes.column}>
        <h3 className={classes.meta}>{workWithUsTitle}</h3>
        <RichContent
          className={classes.workWithUsCopy}
          content={workWithUsCopy}
        />
        <RoundButton link={workWithUsLink} dark bigPadding />
      </div>

      <div className={classes.column}>
        <h3 className={classes.meta}>{getInTouchTitle}</h3>
        <div className={classes.links}>
          {getInTouchLinks &&
            getInTouchLinks.map(link => (
              <RoundButton key={link._key} link={link} />
            ))}
        </div>
      </div>

      <div className={classes.imageWrap}>
        <ResponsiveImage
          className={classes.image}
          image={image}
          aspect={568 / 694}
          speed="0.9"
        />
      </div>
    </Section>
  )
}

const useStyles = createUseStyles({
  title: {
    marginTop: 90,
    marginBottom: 90,
    gridColumn: '1 / -2',
    lineHeight: '0.9em',
    textTransform: 'uppercase',
    opacity: 0,
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1em',
      paddingTop: '0.1em'
    },
    fontSize: 200,
    [theme.breakpoints.down('lg')]: {
      fontSize: 150
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 62,
      gridColumn: '1 / -1',
      marginTop: 64,
      marginBottom: 64
    }
  },

  imageWrap: {
    gridColumn: '8 / -1',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1'
    }
  },
  image: {
    opacity: 0,
    transform: 'scale(1.1)'
  },
  meta: {
    fontFamily: theme.fonts.monoFace,
    fontWeight: theme.fonts.monoFontWeight,
    textTransform: 'uppercase',
    fontSize: 10,
    letterSpacing: '0.05em',
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 32
    }
  },
  workWithUsCopy: {
    marginBottom: 32,
    maxWidth: '26em',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset'
    }
  },
  column: {
    gridColumn: 'span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      marginBottom: 64
    },
    '& > *': {
      opacity: 0,
      transform: 'translateY(20px)'
    }
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gridGap: 10
    // '& > *': {
    //   marginBottom: 8
    // }
  }
})
