import { useMediaQuery } from '@react-hook/media-query'
import { useEffect, useState } from 'react'

export const useIsBreakpoint = (breakpoint, initial = false) => {
  const [isBreakpoint, setIsBreakpoint] = useState(initial)
  const newIsBreakpoint = useMediaQuery(breakpoint.replace('@media', ''))
  useEffect(() => {
    setIsBreakpoint(newIsBreakpoint)
  }, [newIsBreakpoint])
  return isBreakpoint
}
