import createUseStyles from '../lib/createUseStyles'
import cn from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'
import toArray from 'lodash/toArray'
import gsap from 'gsap'
import reverse from 'lodash/reverse'

const range = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

const Number = ({ initialValue = 0, target, animate = true, duration = 2.5 }) => {
  const classes = useStyles()
  const ref = useRef()
  const localsRef = useRef({ animated: false })
  useEffect(() => {
    if (animate && !localsRef.current.animated) {
      localsRef.current.animated = true
      const value = { y: initialValue }
      gsap.to(value, {
        y: target + initialValue,
        ease: 'power2.out',
        duration,
        onUpdate: () => {
          ref.current.style.transform = `translate(0, -${(value.y * 100) % 1000}%)`
        }
      })
    }
  }, [animate])
  return (
    <span className={classes.number} ref={ref} style={{ transform: `translate(0, -${initialValue * 100}%` }}>
      {range.map((v, i) => <span className={classes.value} key={i}>{v}</span>)}
    </span>
  )
}

export default function NumberTicker ({ className, fromCount, toCount, animate = true, duration }) {
  const classes = useStyles()
  const ref = useRef()

  const numbers = useMemo(() => {
    let n = toArray(toCount + '')
    const from = toArray(fromCount + '')
    reverse(n)
    reverse(from)
    n = n.map((n, i) => ({
      placeValue: '1'.padEnd(i + 1, '0'),
      targetValue: parseInt(n),
      initialValue: !from[i] ? 0 : parseInt(from[i]),
      index: i
    }))
    reverse(n)
    return n
  }, [toCount])

  return (
    <div className={cn(className, classes.container)} ref={ref}>
      {numbers.map(({ targetValue, placeValue, initialValue, isNumber, index }, i) => {
        const target = Math.floor(toCount / parseInt(placeValue)) - Math.floor(fromCount / parseInt(placeValue))
        return (
          <React.Fragment key={i + '-' + targetValue}>
            <Number target={target} initialValue={initialValue} animate={animate} duration={duration} />
            {(index > 0 && (index % 3 === 0)) && <span className={classes.comma}>,</span>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    height: '1em',
    overflow: 'hidden',
  },
  number: {
    width: '0.68em',
    height: '1.1em',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-0.1em',
    '& > span': {
      display: 'block',
      paddingBottom: '0.1em',
      height: '1.1em',
      paddingTop: '0.1em',
      
    }
  },
  comma: {
    display: 'block',
    marginRight: '0.1em'
  }
})
