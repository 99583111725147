import createUseStyles from '../../lib/createUseStyles'
import RoundButton from '../RoundButton'
import { theme } from '../../styles/theme'

export default function ErrorMessage ({ data }) {
  const classes = useStyles()

  return (
    <>
      <div className={classes.container}>
        <div className={classes.centered}>
          <p className={classes.error}>{data.message}</p>
          <RoundButton link={data.buttonLink} bigPadding showBackArrow={true} showArrow={false} showText={true}>{data.buttonText}</RoundButton>
        </div>
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  container: {
    marginBottom: 200,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: 82,
    }
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    marginBottom: 16,
    fontSize: 16,
  },
})
