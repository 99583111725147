import { useRef, useCallback } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import { theme } from '../../styles/theme'
import gsap from 'gsap'
import { useScrollReadyListener } from '../../hooks/useScrollReady'
import SplitText from 'gsap/dist/SplitText'
import SubmitMessage from './SubmitMessage'
import { atom } from 'jotai'

export const giftMessageDialogOpenAtom = atom(false)

export default function GiftMessage ({ data, page }) {
  const classes = useStyles()
  const heroTextRef = useRef()
  const { heroText } = data

  useScrollReadyListener(useCallback(() => {
    const titleSplit = new SplitText(heroTextRef.current, { type: 'words, lines', linesClass: 'split-parent' })
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      },
      scrollTrigger: {
        trigger: heroTextRef.current,
        start: 'top bottom',
        once: true
      }
    })
    tl.set(heroTextRef.current, {
      opacity: 1
    })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => titleSplit.revert()
    })
  }, []))

  return (
    <Section tag='section' grid fullWidth>
      <h2 className={classes.heroText} ref={heroTextRef}>{heroText}</h2>
      <SubmitMessage data={data} pageData={page} giftMessage />
    </Section>
  )
}

const useStyles = createUseStyles({
  heroText: {
    padding: '0 32px',
    gridColumn: '2 / -2',
    textAlign: 'center',
    fontSize: 72,
    lineHeight: '1em',
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    textTransform: 'uppercase',
    letterSpacing: '-0.03em',
    position: 'relative',
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      fontSize: 24
    },
    '& .split-parent': {
      overflowY: 'hidden',
      marginTop: '-0.1em',
      height: '1.1em',
      paddingTop: '0.1em'
    }
  },
})
