import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import createUseStyles from '../../lib/createUseStyles'
import Section from '../Section'
import gsap from 'gsap'
import Player from '../VimeoPlayer'
import RichContent from '../RichContent'
import { theme } from '../../styles/theme'
import SplitText from 'gsap/dist/SplitText'
import { useAtom, useAtomValue } from 'jotai'
import { headerColoursAtom, headerFadeInAtom } from '../Header/headerState'
import useScrollTrigger from '../../hooks/useScrollTrigger'
import Link from '../Link'
import { settingsAtom } from '../../state/content'
import HelpLineMessage from '../HelplineMessage'
import { useRouter } from 'next/router'

export const StoriesHero = ({ data, page }) => {
  const { pathwayStories, title, preTitle, slug } = page ?? {}
  const styles = useStyles()
  const titleRef = useRef()
  const navRef = useRef()
  const backLinkRef = useRef()
  const preTitleRef = useRef()
  const [activeIndex, setActiveIndex] = useState('')
  const settings = useAtomValue(settingsAtom)

  const [, setHeaderColours] = useAtom(headerColoursAtom)
  const [headerFadeIn] = useAtom(headerFadeInAtom)
  const router = useRouter()
  const [showPoster, setShowPoster] = useState(true)

  useEffect(() => {
    const urlParts = router.asPath.split('/')
    const story = urlParts[urlParts.length - 1]
    const storyKey = story.includes('?') ? story.split('?')[0] : story
    if (storyKey !== page.slug) {
      setActiveIndex(storyKey)
    } else {
      setActiveIndex(page.pathwayStories[0]?.slug)
    }
  }, [setActiveIndex, page.slug, page.pathwayStories, router])

  const sectionRef = useScrollTrigger(
    () => {
      return {
        trigger: sectionRef.current,
        start: () => 'top 0',
        end: () =>
          `bottom ${
            window.innerWidth < theme.breakpoints.values.md ? 500 : 350
          }px`,
        scrub: false,
        onLeave: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onEnterBack: () => {
          setHeaderColours({
            foreground: '#000000',
            background: 'rgba(252,191,156,1)',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        },
        onLeaveBack: () => {
          setHeaderColours({
            background: theme.colors.pageTheme.default.background,
            foreground: '#000000',
            duration: 0.4,
            ease: 'power2.inOut'
          })
        }
      }
    },
    tl => {},
    () => {
      setHeaderColours({
        background: theme.colors.pageTheme.default.background,
        foreground: '#000000',
        duration: 0.3,
        ease: 'expo.out'
      })
    }
  )

  useEffect(() => {
    if (!headerFadeIn) return
    const preTitleSplit = new SplitText(preTitleRef.current, {
      type: 'words, lines',
      linesClass: 'split-parent',
      wordsClass: 'word-split-parent'
    })
    const titleSplit = new SplitText(titleRef.current, {
      type: 'words, lines',
      linesClass: 'split-parent',
      wordsClass: 'word-split-parent'
    })
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'power2.inOut'
      }
    })
    tl.add(() => {
      setHeaderColours({
        foreground: '#000000',
        background: 'rgba(252,191,156,1)',
        duration: 0.3,
        ease: 'power2.inOut'
      })
    })
    tl.to(sectionRef.current, { opacity: 1, duration: 0.3 })

    tl.set(titleRef.current, {
      opacity: 1
    })
    tl.set(preTitleRef.current, {
      opacity: 1
    })
    tl.from(preTitleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => preTitleSplit.revert()
    })
    tl.from(titleSplit.words, {
      y: '120%',
      opacity: 0,
      stagger: 0.1,
      ease: 'expo.out',
      delay: 0.1,
      onComplete: () => titleSplit.revert()
    })
    tl.set(navRef.current, {
      opacity: 1,
      duration: 0.2
    })
    tl.set(backLinkRef.current, {
      opacity: 1,
      duration: 0.2
    })
    tl.from(
      sectionRef.current.querySelectorAll(`.${styles.videoEl} > div`),
      {
        opacity: 0,
        scale: 1.05
      },
      '-=0.5'
    )
    return () => {
      titleSplit.revert()
      preTitleSplit.revert()
    }
  }, [headerFadeIn])

  return (
    <div className={styles.root} ref={sectionRef}>
      <div className={styles.gradient} />
      <Section grid className={styles.grid}>
        <div className={styles.titleWrapper}>
          <Link
            to="/pathways"
            showText={false}
            className={styles.backLink}
            ref={backLinkRef}
          >
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.55353 0.686707L9.50528 1.63846L3.514 7.62974H19.3036L19.3036 8.97171L3.514 8.97171L9.50528 14.963L8.55353 15.9147L0.939511 8.30072L8.55353 0.686707Z"
                fill="black"
              />
            </svg>
            <span>Back to Pathways</span>
          </Link>
          <div className={cn(styles.titles)}>
            <div className={cn(styles.preTitle, 'as-h1 h')} ref={preTitleRef}>
              {preTitle}
            </div>
            <h1 className={cn(styles.title)} ref={titleRef}>
              {title}
            </h1>
          </div>
        </div>

        <div className={styles.storiesWrapper}>
          {pathwayStories &&
            pathwayStories
              .filter(story => story.slug === activeIndex)
              .map((story, k) => {
                const { copy } = story ?? {}
                return (
                  <div
                    key={k}
                    id={story.slug}
                    className={cn(
                      styles.story,
                      activeIndex === story.slug && 'active'
                    )}
                  >
                    {story.videoData && (
                      <>
                        <div
                          className={styles.video}
                          onClick={() => setShowPoster(false)}
                        >
                          <div
                            className={cn(
                              styles.videoEl,
                              showPoster && 'hidden'
                            )}
                          >
                            <Player
                              id={story.vimeoId}
                              vimeoData={story.videoData}
                              previewData={story.previewData}
                            />
                          </div>
                        </div>
                        <div className={styles.navContainer}>
                          <div className={styles.navWrapper}>
                            <div className={styles.nav} ref={navRef}>
                              {pathwayStories &&
                                pathwayStories.map((story, k) => {
                                  return (
                                    <Link
                                      id={slug}
                                      to={`/${slug}/${story.slug}`}
                                      key={'nav-' + k}
                                      className={cn(
                                        styles.navButton,
                                        activeIndex === story.slug && 'active'
                                      )}
                                      onClick={() => {
                                        setActiveIndex(story.slug)
                                        setShowPoster(true)
                                      }}
                                    >
                                      {story.title}
                                    </Link>
                                  )
                                })}
                            </div>
                          </div>
                          {settings.helplineMessage && (
                            <HelpLineMessage
                              message={settings.helplineMessage}
                            />
                          )}
                        </div>
                      </>
                    )}
                    <div className={styles.storyGrid}>
                      <div className={styles.copy}>
                        {copy && (
                          <RichContent className={styles.copy} content={copy} />
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
        </div>
      </Section>
    </div>
  )
}

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    marginTop: -132,
    paddingBottom: 30,
    paddingTop: 150,
    [theme.breakpoints.up('md')]: {
      paddingTop: 131 + 82
    }
  },
  posterImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    pointerEvents: 'none',
    opacity: 0,
    '&.active': {
      opacity: 1
    }
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: 1100,
    zIndex: 1,
    background:
      'linear-gradient(0deg, rgba(252,191,156,0) 0%, rgba(252,191,156,1) 47%, rgba(252,191,156,1) 100%)',
    [theme.breakpoints.up('md')]: {
      height: 1800
    }
  },
  grid: {
    position: 'relative',
    zIndex: 2
  },
  titleWrapper: {
    gridColumn: '1 / -1'
  },
  backLink: {
    opacity: 0,
    transition: 'opacity 0.2s ease-out',
    fontSize: 12,
    marginBottom: 20,
    display: 'block',
    width: 'fit-content',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      opacity: 0.5
    },
    '& svg': {
      width: 12,
      height: 10,
      marginRight: 6
    }
  },
  preTitle: {
    opacity: 0,
    marginBottom: 0,
    textTransform: 'uppercase',
    fontSize: 40,
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'wrap',
      fontSize: 'calc((100vw - 48px) * 0.055)'
    }
  },
  title: {
    opacity: 0,
    marginBottom: 32,
    textTransform: 'uppercase',
    fontSize: 40,
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'wrap',
      fontSize: 'calc((100vw - 48px) * 0.055)',
      marginBottom: 64
    }
  },
  navContainer: {
    marginTop: 30,
    alignItems: 'start',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  navWrapper: {
    width: 'calc(100vw - 16px)',
    overflowX: 'scroll'
  },
  nav: {
    marginBottom: 24,
    width: 'max-content',
    [theme.breakpoints.down('md')]: {
      paddingRight: 12
    }
  },
  video: {
    position: 'relative',
    gridColumn: '1 / -1',
    overflow: 'hidden',
    '.vimeo-player-aspect': {
      [theme.breakpoints.down('md')]: {
        paddingTop: '100% !important'
      }
    }
  },
  helplineMessage: {
    gridColumn: '1 / -1'
  },
  navButton: {
    background: 'transparent',
    display: 'inline-flex',
    alignItems: 'center',
    padding: '14px 24px 12px',
    borderRadius: 41,
    border: '1px solid var(--foreground)',
    textDecoration: 'none',
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    transition:
      'background 0.1s cubic-bezier(0.1, 0, 0, 1), border-color 0.1s cubic-bezier(0.1, 0, 0, 1), color 0.1s cubic-bezier(0.1, 0, 0, 1)',
    '&:hover': {
      background: 'var(--foreground)',
      color: 'var(--background)'
    },
    '&:not(last-of-type)': {
      marginRight: 4
    },
    '&.active': {
      background: 'var(--foreground)',
      color: 'var(--background)'
    }
  },
  story: {
    transition: 'opacity 0s ease-out',
    width: '100%',
    '&.active': {
      opacity: 1,
      position: 'relative'
    }
  },
  storyGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    paddingTop: 80,
    [theme.breakpoints.up('md')]: {
      paddingTop: 134
    }
  },
  storiesWrapper: {
    position: 'relative',
    gridColumn: '1 / -1'
  },
  copy: {
    h2: {
      textTransform: 'uppercase'
    },
    h3: {
      textTransform: 'uppercase'
    },
    gridColumn: '7/13',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/13'
    },
    '& p': {
      width: '100%',
      maxWidth: 564
    }
  }
})
