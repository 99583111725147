import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import useCustomer from '../../../hooks/useCustomer'
import { customerGroupsAtom } from '../../../state/content'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'

export const useCustomerGroup = () => {
  const customerGroups = useAtomValue(customerGroupsAtom)
  const { data: customer } = useCustomer()
  return useMemo(() => {
    return customerGroups.find(x => (!customer && x.is_group_for_guests) || x.id === customer?.customerGroupId)
  }, [customerGroups, customer])
}

const discountMethodSelector = {
  price: (rule, price) => {
    return Math.max(0, price - rule.amount)
  },
  percent: (rule, price) => {
    return price - (price * (rule.amount / 100))
  },
  fixed: (rule, price) => {
    return rule.amount
  }
}

const matchSelector = {
  product: (rule, product) => {
    return product.product.id === parseInt(rule.product_id)
  },
  category: (rule, product) => {
    return product.product.categories?.includes(parseInt(rule.category_id))
  },
  all: (rule, product) => {
    return true
  }
}

const applyRule = (rule, product) => {
  const matches = matchSelector[rule.type]
  const applyDiscount = discountMethodSelector[rule.method]

  if (!matches(rule, product)) {
    return product
  }

  return {
    ...product,
    product: {
      ...product.product,
      calculated_price: applyDiscount(rule, product.product.calculated_price),
      guest_price: product.product.guest_price || product.product.calculated_price,
      variants: product.product?.variants?.map(v => ({
        ...v,
        calculated_price: applyDiscount(rule, v.calculated_price),
        guest_price: v.guest_price || v.calculated_price
      }))
    }
  }
}

export const applyCustomerGroupProductPrice = (customerGroup, product) => {
  const rules = customerGroup.discount_rules
  const sortedRules = sortBy(rules, r => r.type === 'all' ? 3 : r.type === 'category' ? 2 : 1)
  // NOTE: Big commerce will find the first matching rule and apply that rule
  const rule = find(sortedRules, rule => matchSelector[rule.type](rule, product))
  if (!rule) {
    return product
  }
  return applyRule(rule, product)
}

export const useCustomerGroupProductPrice = (product) => {
  const customerGroup = useCustomerGroup()
  return useMemo(() => {
    return applyCustomerGroupProductPrice(customerGroup, product)
  }, [product, customerGroup])
}

export const isProductVisible = (product, customerGroup) => {
  if (customerGroup.category_access.type !== 'all') {
    const visibleCategoryIds = customerGroup.category_access.categories
    return !isEmpty(product.product.categories) && isEmpty(difference(product.product.categories, visibleCategoryIds))
  }
  return true
}

export const isProductInStock = ({ product }) => {
  if (product.inventory_level === 0) return false
  return true
}

export const useProductVisibleToCustomer = (product) => {
  const customerGroup = useCustomerGroup()
  return useMemo(() => {
    return isProductVisible(product, customerGroup)
  }, [customerGroup, product])
}

export function useProductCustomerGroupFiltering (products) {
  const customerGroup = useCustomerGroup()
  const filteredProducts = useMemo(() => {
    const visibleProducts = products.filter(p => isProductVisible(p, customerGroup))
    const inStockProducts = visibleProducts.filter(p => isProductInStock(p))
    return inStockProducts.map(p => applyCustomerGroupProductPrice(customerGroup, p))
  }, [customerGroup, products])

  return filteredProducts
}

export const useIsWholesaleOnlyProduct = (product) => {
  const customerGroups = useAtomValue(customerGroupsAtom)
  return useMemo(() => {
    if (!product || product._type !== 'product') return false
    const guestCustomerGroup = customerGroups.find(x => x.is_group_for_guests)
    return !isProductVisible(product, guestCustomerGroup)
  }, [customerGroups, product])
}
