import React, { forwardRef, useEffect, useMemo } from 'react'
import NextLink from 'next/link'
import { resolveLink } from '../lib/resolveLink'
import cn from 'classnames'

import createUseStyles from '../lib/createUseStyles'
import { useIsWholesaleOnlyProduct } from './Slices/ProductListing/productHooks'
import { useRouter } from 'next/router'

const Link = forwardRef(
  (
    {
      openInNewTab = true,
      nonLinkTag = 'span',
      link,
      to,
      children,
      showExternalIcon = true,
      showText = true,
      scroll = false,
      queryParams,
      ...rest
    },
    ref
  ) => {
    const isWholesaleOnlyPage = useIsWholesaleOnlyProduct(link)
    const classes = useStyles()
    const { url, text, hash, query } = useMemo(() => {
      if (to) return { url: to }
      if (link) {
        return resolveLink({
          ...link,
          wholesale: isWholesaleOnlyPage,
          query: queryParams || link.query
        })
      }
      return {}
    }, [link, queryParams, to, isWholesaleOnlyPage])

    // External Link
    if (
      url &&
      (url.indexOf('http') >= 0 ||
        url.indexOf('tel:') >= 0 ||
        url.indexOf('mailto:') >= 0)
    ) {
      return (
        <a
          href={url}
          target={openInNewTab ? '_blank' : ''}
          rel={openInNewTab ? 'noreferrer noopener' : ''}
          {...rest}
          ref={ref}
        >
          {showText && text}
          {children}
          {showExternalIcon && (
            <svg
              className={cn(classes.externalLinkIcon, 'external-link-icon')}
              width="8px"
              height="8px"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M1.6 0V0.8H6.636L0 7.436L0.564 8L7.2 1.364V6.4H8V0H1.6Z"
                fill="currentColor"
              />
            </svg>
          )}
        </a>
      )
    }

    // No Link
    if (!url) {
      const Tag = nonLinkTag
      return (
        <Tag {...rest} ref={ref}>
          {showText && text}
          {children}
        </Tag>
      )
    }

    // Internal Link
    return (
      <NextLink
        href={{
          pathname: hash ? url + '#' + hash : url,
          query
        }}
        scroll={scroll}
      >
        <a {...rest} ref={ref}>
          {showText && text}
          {children}
        </a>
      </NextLink>
    )
  }
)

Link.displayName = 'Link'

const useStyles = createUseStyles({
  externalLinkIcon: {
    paddingRight: 1,
    marginLeft: 6,
    display: 'inline-block',
    height: '0.7em',
    width: '0.7em',
    pointerEvents: 'none'
  }
})

export default Link
