import { useRef, useCallback, useEffect } from 'react'
import createUseStyles from "../../lib/createUseStyles";
import Cursor, { useCursor } from '../Cursor'
import { atom, useAtom, useAtomValue } from 'jotai'
import cn from 'classnames'
import { theme } from '../../styles/theme';

export const showCursorAtom = atom(false)
export const cursorSizeAtom = atom('normal')
export const cursorColourAtom = atom('transparent')
export const showVideoCursorAtom = atom(false)

export default function ProudctCursor() {
  const classes = useStyles()

  const isVisible = useAtomValue(showCursorAtom)
  const showVideoCursor = useAtomValue(showVideoCursorAtom)
  const colour = useAtomValue(cursorColourAtom)
  const cursorSize = useAtomValue(cursorSizeAtom)

  const ref = useRef()
  const videoCursorRef = useRef()
  const localsRef = useRef({ x: '-100', y: '-100' })

  useCursor(useCallback((data) => {
    localsRef.current.x = data.last.x
    localsRef.current.y = data.last.y
  }, []))

  useEffect(() => {
    let animationId
    const loop = () => {
      ref.current.style.transform = `translate(${localsRef.current.x - 128}px, ${localsRef.current.y - 128}px)`
      videoCursorRef.current.style.transform = `translate(${localsRef.current.x - 65}px, ${localsRef.current.y - 65}px)`
      animationId = window.requestAnimationFrame(loop)
    }
    loop()
    return () => {
      window.cancelAnimationFrame(animationId)
    }
  }, [])

  useEffect(() => {
    const isBig = cursorSize === 'big'
    const el = ref.current.querySelector(`.${classes.cursor}`)

    const bigAmount = window.innerWidth / 256 * 2.3

    el.style.transform = isBig ? `scale(${bigAmount})` : isVisible ? 'scale(1)' : 'scale(0)'
    el.style.transition = isBig ? 'background 0.6s ease-in-out, transform 1s cubic-bezier(.36,.06,0,.88)' : ''
  }, [cursorSize, isVisible])

  return (
    <>
      <Cursor />
      <div className={cn(classes.root)} ref={ref}>
        <div className={cn(classes.cursor, classes.wrap)} style={{ backgroundColor: colour, transform: 'scale(0)' }} />
      </div>

      <div className={cn(classes.videoCursorWrap)} ref={videoCursorRef}>
        <div className={cn(classes.videoCursor, showVideoCursor && classes.videoCursorVisible)}>
          PLAY
        </div>
      </div>
    </>
  )
}

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    pointerEvents: 'none'
  },
  cursor: {
    pointerEvents: 'none',
    width: 256,
    height: 256,
    borderRadius: '50%',
    background: 'transparent',
    transform: 'scale(0)',
    transition: 'background 0.6s ease-in-out, transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)'
  },
  visible: {
    transform: 'scale(1) !important'
  },
  big: {
    transform: 'scale(15) !important',
    transition: 'background 0.6s ease-in-out, transform 1.5s cubic-bezier(0.16, 1, 0.3, 1)',
  },
  videoCursor: {
    zIndex: 999,
    width: 130,
    height: 130,
    textAlign: 'center',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.headingFace,
    fontWeight: theme.fonts.headingFontWeight,
    fontSize: 18,
    background: 'black',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    letterSpacing: '0.04em',
    pointerEvents: 'none',
    transform: 'scale(0)',
    transition: 'transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)'
  },
  videoCursorWrap: {
    pointerEvents: 'none',
    zIndex: 999,
    position: 'fixed',
    top: 0,
    left: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  videoCursorVisible: {
    transform: 'scale(1) !important'
  }
})